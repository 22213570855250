/* eslint-disable max-lines-per-function */
import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector, useStore} from 'react-redux';
import cx from 'classnames';
import {pluralize} from '~/common/utils';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Link} from '~/common/_pb_components/atoms/Link';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {PB_ClickOutLine as PBClickOutLine} from '~/common/svg/PB_ClickOutLine';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PageSection, SectionRow} from '~/SignUpSheets/components/PageSection/PageSection';
import {
  selectExpired,
  selectGroupIsPastOrFull,
  selectIsOrganizer,
  selectIsPreviewMode,
  selectSelectedSignups,
  selectSheetHasProducts,
  selectSheetId,
  selectSheetIsDraft,
  selectSheetStatus,
  selectSheetType,
  selectSignupOptionGroupIds,
  selectSignupOptionIsFull,
  selectSignupOptionIsPast,
  selectSortedSignupOptions,
  selectSignups,
  selectTitle,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {SignUpOverlay} from '~/SignUpSheets/components/SignUpOverlay/SignUpOverlay';
import {NavBottom} from '~/SignUpSheets/components/NavBottom/NavBottom';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';
import {
  SET_SELECTED_SIGNUPS,
  SET_SIGNUPS_PENDING_DELETION,
  UPDATE_SHEET_DETAILS,
} from '~/SignUpSheets/reducers/sheet/constants';
import {selectIsVolunteer} from '~/SignUpSheets/reducers/user/selectors';
import {selectBackground} from '~/SignUpSheets/reducers/themes/selectors';
import {flashGreen} from '~/SignUpSheets/animations/flashGreen';
import {
  emailSheetVolunteers,
  signupOptionIsBeforeToday,
  signupOptionIsToday,
  slotElapsed,
} from '~/SignUpSheets/utils/misc';
import {
  BLUEPRINT_TYPE_DATE,
  BLUEPRINT_TYPE_DATETIME,
  BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY,
  BLUEPRINT_TYPE_ITEMS,
} from '~/SignUpSheets/reducers/blueprint/constants';
import {useFormattedSheetDate} from '~/SignUpSheets/components/Details/hooks/useFormattedSheetDate';
import {Details} from '~/SignUpSheets/components/Details/Details';
import {VolunteerViewSection} from './components/VolunteerViewSection/VolunteerViewSection';
import {VolunteerWishlist} from './components/VolunteerWishlist/VolunteerWishlist';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {selectIsDateTimeBlueprint} from '~/SignUpSheets/reducers/blueprint/selectors';

import {tanzanite} from '~sass/pb_styleguide/base/_exports.sass';

export const VolunteerView = () => {
  const mounted = useRef(false);
  const store = useStore();
  const dispatch = useDispatch();
  const [signupOverlayOpen, setSignupOverlayOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const selectedSignups = useSelector(selectSelectedSignups, shallowEqual);
  const allExpired = useSelector(selectExpired);
  const status = useSelector(selectSheetStatus);
  const sheetIsDraft = useSelector(selectSheetIsDraft);
  const volunteer = useSelector(selectIsVolunteer);
  const signups = useSelector(selectSignups, shallowEqual);
  const sheetTitle = useSelector(selectTitle);
  const isOrganizer = useSelector(selectIsOrganizer);
  const canceled = status === 'canceled';
  const blueprintType = useSelector(selectSheetType);
  const sheetId = useSelector(selectSheetId);
  const signedInId = useSelector((state) => state.user?.userId);
  const sheetHasProducts = useSelector(selectSheetHasProducts);
  const {search} = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const [newUserId, setNewUserId] = useState(null);
  const storeSignupOptions = useSelector(
    selectSortedSignupOptions({userId: signedInId || newUserId}),
    shallowEqual
  );
  const signupOptionGroupIds = useSelector(
    selectSignupOptionGroupIds({sortDownPastOrFullGroups: false, userId: signedInId || newUserId}),
    shallowEqual
  );
  const grouped = useMemo(() => signupOptionGroupIds.length > 0, [signupOptionGroupIds]);
  const [openGroups, setOpenGroups] = useState(new Set());
  const isLargishAndUp = useIsLargishAndUp();
  const {processProductLinks} = useAffiliateActions();
  const {date: formattedDate} = useFormattedSheetDate(true);
  const dateToShow =
    isPreviewMode && !formattedDate && sheetIsDraft ? '[Sample Date]' : formattedDate;
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const legacyDateTime = useMemo(() => blueprintType === BLUEPRINT_TYPE_DATETIME, [blueprintType]);

  useEffect(() => {
    const key = `sheet-${sheetId}-user`;
    const value = localStorage.getItem(key);
    const queryParamValue = atob(queryParams.get('uid'));
    if (queryParamValue) {
      setNewUserId(queryParamValue);
    } else if (value) {
      setNewUserId(value);
    }
    if (!allExpired && sheetHasProducts) processProductLinks(storeSignupOptions);
    return () => {
      if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
      }
    };
  }, [allExpired, sheetHasProducts, processProductLinks, storeSignupOptions]);

  // Initialize open groups
  useEffect(() => {
    if (mounted.current || storeSignupOptions.length === 0) return;
    mounted.current = true;
    const state = store.getState();
    const expandedGroups = new Set();
    if (signupOptionGroupIds.length > 0) {
      for (const groupId of signupOptionGroupIds) {
        if (!isLargishAndUp && expandedGroups.size > 0) break;
        if (isLargishAndUp && signupOptionGroupIds.length > 10 && expandedGroups.size > 0) {
          break;
        }
        const groupIsPastOrFull = selectGroupIsPastOrFull(groupId)(state);
        if (!groupIsPastOrFull) expandedGroups.add(groupId);
      }
    } else if (storeSignupOptions.length > 0) {
      for (const so of storeSignupOptions) {
        if (!isLargishAndUp && expandedGroups.size > 0) break;
        if (isLargishAndUp && storeSignupOptions.length > 10 && expandedGroups.size > 0) {
          break;
        }
        const groupIsPast = selectSignupOptionIsPast(so)(state);
        const groupIsFull = selectSignupOptionIsFull(so)(state);
        if (!groupIsPast && !groupIsFull) expandedGroups.add(so.uuid);
      }
    }
    setOpenGroups(expandedGroups);
  }, [store, signupOptionGroupIds, isLargishAndUp, storeSignupOptions]);

  const signupOptions = useMemo(() => {
    if (isPreviewMode) {
      return storeSignupOptions.map((signupOption) => {
        if (!sheetIsDraft) {
          return {
            ...signupOption,
            slots: signupOption.slots
              ? signupOption.slots.map((slot) => ({
                  ...slot,
                  start_time: signupOption.all_day ? '12:00 AM' : slot.start_time,
                  end_time: signupOption.all_day ? '11:59 PM' : slot.end_time,
                }))
              : undefined,
          };
        }
        const baseOption = {
          ...signupOption,
          quantity: signupOption.quantity || 1,
        };

        if (blueprintType === BLUEPRINT_TYPE_ITEMS) {
          return {
            ...baseOption,
            item: signupOption.item || '[Sample Item]',
            description: signupOption.description || '[Sample Description]',
          };
        }

        if (blueprintType === BLUEPRINT_TYPE_DATE) {
          return {
            ...baseOption,
            date: signupOption.date || '[Sample Date]',
            description: signupOption.description || '[Sample Day Description]',
          };
        }

        if (blueprintType === BLUEPRINT_TYPE_DATETIME) {
          return {
            ...baseOption,
            date: signupOption.date || '[Sample Date]',
            slots: signupOption.slots.map((slot) => ({
              ...slot,
              start_time: signupOption.all_day ? '12:00 AM' : slot.start_time || '[Start Time]',
              end_time: signupOption.all_day ? '11:59 PM' : slot.end_time || '[End Time]',
              description: slot.description || '[Sample Slot Description]',
            })),
          };
        }

        if (blueprintType === BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY) {
          return {
            ...baseOption,
            date: signupOption.date || '[Sample Date]',
            title: signupOption.title || '[Sample Date Title]',
            description: signupOption.description || '[Sample Day Description]',
            slots: signupOption.slots.map((slot) => ({
              ...slot,
              start_time: signupOption.all_day ? '12:00 AM' : slot.start_time || '[Start Time]',
              end_time: signupOption.all_day ? '11:59 PM' : slot.end_time || '[End Time]',
              title: slot.title || '[Sample Slot Title]',
              description: slot.description || '[Sample Slot Description]',
            })),
          };
        }

        return signupOption; // return the original object if no blueprintType matches
      });
    }

    return storeSignupOptions.toSorted((a, b) => {
      if (!a.date || !b.date) return 0;
      if (signupOptionIsBeforeToday(a)) return 1;
      if (signupOptionIsBeforeToday(b)) return -1;

      // The signup options is for today or the future
      if (isDatetimeType) {
        if (signupOptionIsToday(a) && a.slots.every(slotElapsed)) return 1;
        if (signupOptionIsToday(b) && b.slots.every(slotElapsed)) return -1;
      }
      return 0;
    });
  }, [storeSignupOptions, isDatetimeType, isPreviewMode, blueprintType, sheetIsDraft]);

  const closeOverlay = useCallback(() => {
    setSignupOverlayOpen(false);

    if (isEditing) {
      setIsEditing(false);
      dispatch({
        type: SET_SELECTED_SIGNUPS,
        payload: [],
      });
      dispatch({
        type: SET_SIGNUPS_PENDING_DELETION,
        payload: [],
      });
    }
  }, [isEditing, dispatch, setSignupOverlayOpen, setIsEditing]);

  const onClickEditHandler = useCallback(
    (option) => () => {
      dispatch({
        type: UPDATE_SHEET_DETAILS,
        payload: {previewMode: false},
      });

      setTimeout(() => {
        const slotClass = `.builder__item-row-${option.uuid}`;
        const slotElement = document.querySelector(slotClass);
        if (slotElement) {
          const susNavbarHeight = document.querySelector(
            '.signup-sheet__header__wrapper'
          ).offsetHeight;
          const slotPosition = slotElement.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = slotPosition - susNavbarHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'instant',
          });

          flashGreen(slotClass);
        }
      }, 1);
    },
    []
  );

  const controlOpenGroups = useCallback(
    (groupId) => {
      const expandedGroups = new Set(openGroups);
      const groupWasOpen = expandedGroups.has(groupId);
      if (isLargishAndUp) {
        if (groupWasOpen) expandedGroups.delete(groupId);
        else expandedGroups.add(groupId);
      } else {
        expandedGroups.clear();
        if (!groupWasOpen) expandedGroups.add(groupId);
      }
      setOpenGroups(expandedGroups);
    },
    [isLargishAndUp, openGroups]
  );

  const emailVolunteersButton = useMemo(
    () => (
      <Button
        variant="transparent"
        className="page-section__email-volunteers-button"
        icon={<PBClickOutLine ratio={0.5} color={tanzanite} />}
        data-qa-id="email-volunteers-button"
        onClick={() => emailSheetVolunteers({signups, title: sheetTitle})}
      >
        <Typography variant="list2" color="tanzanite" size="large">
          Email volunteers
        </Typography>
      </Button>
    ),
    [signups, sheetTitle]
  );

  if (volunteer && canceled) {
    return <Cancelled />;
  }

  return (
    <div className={cx('signup-sheet__landing', {preview: isPreviewMode})}>
      <Details viewOnlyMode />

      <PageSection
        isLoggedIn
        expired={allExpired}
        canceled={canceled}
        id="sheet-items"
        title={isDatetimeType || grouped || !isLargishAndUp ? '' : dateToShow}
        bodyQaId="signup-options"
        bodyQaValue={signupOptions.length}
        subtitle={isOrganizer ? emailVolunteersButton : null}
        split={isDatetimeType}
        showOrganizerHeader={isEditing}
        pageSectionClassName={cx('page-section__standalone-items', {
          'signup-options__wrapper--no-padding-background': !isLargishAndUp && !grouped,
        })}
      >
        <div
          className={cx('signup-options__wrapper', {
            'signup-options__wrapper__preview-mode': isPreviewMode,
            grouped,
          })}
        >
          {grouped
            ? signupOptionGroupIds.map((groupId, index) => (
                <Fragment key={groupId}>
                  <VolunteerViewSection
                    groupId={groupId}
                    openSignupOverlay={() => setSignupOverlayOpen(true)}
                    setIsEditing={setIsEditing}
                    allExpired={allExpired}
                    canceled={canceled}
                    legacyDateTime={legacyDateTime}
                    isDatetimeType={isDatetimeType}
                    isPreviewMode={isPreviewMode}
                    isDesktop={isLargishAndUp}
                    signedInId={signedInId}
                    newUserId={newUserId}
                    onEdit={onClickEditHandler}
                    expanded={openGroups.has(groupId)}
                    trackOpenGroups={controlOpenGroups}
                  />
                  {isLargishAndUp && index < signupOptionGroupIds.length - 1 && <Divider />}
                </Fragment>
              ))
            : signupOptions.map((option, index) => (
                <Fragment key={option.uuid}>
                  <VolunteerViewSection
                    option={option}
                    openSignupOverlay={() => setSignupOverlayOpen(true)}
                    setIsEditing={setIsEditing}
                    allExpired={allExpired}
                    canceled={canceled}
                    legacyDateTime={legacyDateTime}
                    isDatetimeType={isDatetimeType}
                    isPreviewMode={isPreviewMode}
                    isDesktop={isLargishAndUp}
                    signedInId={signedInId}
                    newUserId={newUserId}
                    onEdit={onClickEditHandler}
                    expanded={openGroups.has(option.uuid)}
                    trackOpenGroups={controlOpenGroups}
                  />
                  {isLargishAndUp && isDatetimeType && index < signupOptions.length - 1 ? (
                    <Divider />
                  ) : null}
                </Fragment>
              ))}
        </div>
      </PageSection>
      <PageSection isLoggedIn>
        <SectionRow>
          <VolunteerWishlist />
        </SectionRow>
      </PageSection>
      {signupOverlayOpen && (
        <SignUpOverlay
          selectedSignups={selectedSignups}
          closeOverlay={closeOverlay}
          isOpen={signupOverlayOpen}
          isEditing={isEditing}
          legacyDateTime={legacyDateTime}
          currentUserId={signedInId || newUserId}
        />
      )}
      <NavBottom
        show={selectedSignups.length > 0 && !isEditing}
        onClickPrimary={() => setSignupOverlayOpen(true)}
        buttonTextPrimary={`Confirm ${pluralize('signup', selectedSignups.length)}`}
        dataQaIdPrimary="signup-sheet-confirm-signups"
      />
    </div>
  );
};

const Cancelled = () => {
  const isLargishAndUp = useIsLargishAndUp();
  const background = useSelector((s) => selectBackground(s, {isDesktop: isLargishAndUp}));

  return (
    <div
      className="signup-sheet__landing--cancelled"
      style={{...(!isLargishAndUp && background ? {backgroundImage: background} : {})}}
    >
      <div className="signup-sheet__landing--cancelled-card">
        <Typography variant={isLargishAndUp ? 'header1' : 'header3'} as="h1">
          Sorry, this SignUp Sheet has been canceled.
        </Typography>
        <Link
          as="primary"
          size="large"
          href="/signup-sheets/?cat=signup-sheets"
          data-qa-id="create-signup-sheet-link"
        >
          Create your own sheet
        </Link>
      </div>
    </div>
  );
};
