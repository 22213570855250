import {useSelector} from 'react-redux';
import {Input} from '~/common/_pb_components/atoms/Input';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {selectOrganizerName} from '~/SignUpSheets/reducers/sheet/selectors';
import {useDetailsErrors} from '~/SignUpSheets/components/Details/hooks/useDetailsErrors';
import {DetailsOrganizerInfo} from '~/SignUpSheets/components/Details/components/DetailsOrganizerInfo';
import {useOnChangeAndUpdateSheet} from '~/SignUpSheets/hooks/useOnChangeAndUpdateSheet';
import {useViewOnlyMode} from '~/SignUpSheets/components/Details/ViewOnlyModeProvider';

import {DetailsOrganizerViewOnly} from './DetailsOrganizerViewOnly';

export const DetailsOrganizedBy = () => {
  const organizerName = useSelector(selectOrganizerName);
  const isDesktop = useIsLargishAndUp();
  const detailsErrors = useDetailsErrors();
  const [onDetailsChange] = useOnChangeAndUpdateSheet();
  const viewOnlyMode = useViewOnlyMode();

  if (viewOnlyMode) {
    return <DetailsOrganizerViewOnly />;
  }

  return (
    <div className="signup-sheet__details__bottom__spacing--smaller-gap">
      {isDesktop && (
        <Typography
          variant="label2"
          size="small"
          className="signup-sheet__details__default-label"
          color="coin"
        >
          Organized By
        </Typography>
      )}

      <Input
        value={organizerName}
        onChange={onDetailsChange}
        id="organizer_name"
        data-qa-id="sheet-organizer-name"
        placeholder="Your name*"
        live
        inputClassName="details__font__where-organizer"
        error={detailsErrors.organizerName}
      />

      <DetailsOrganizerInfo onDetailsChange={onDetailsChange} />
    </div>
  );
};
