import cx from 'classnames';
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import Select from 'react-select';

import {
  convertToTimeOptions,
  createOption,
  generateTimeOptions,
  isTimeInRange,
  removeDuplicatesAndSort,
} from './utils';
import {getStyles} from './styles';
import {Input} from './components/Input';
import {Option} from './components/Option';

import './TimePicker.sass';

export const TimePicker = ({
  onChange,
  error,
  activeOption = false,
  'data-qa-id': dataQaId,
  value = '12:00 AM',
  step = 5,
  placeholder = 'Type or select a time',
  hideSelectArrow = false,
  disabled = false,
  maxTime = null,
  minTime = null,
  menuPosition = 'absolute',
  label = null,
  id,
  name = '',
  required = false,
  onMenuOpen,
  menuPortalTarget,
  maxMenuHeight,
  borderColor,
  className,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  // Generate the static time options once when the component mounts
  useEffect(() => {
    const staticOptions = generateTimeOptions(step, minTime, maxTime);
    if (value) {
      const initialValueOption = createOption(value);
      setSelectedOption(value);
      setOptions(removeDuplicatesAndSort([...staticOptions, initialValueOption]));
    } else {
      setOptions(removeDuplicatesAndSort(staticOptions));
    }
  }, [value, step, minTime, maxTime]);

  const handleInputChange = useCallback(
    (newValue) => {
      // Allow only numbers and colon, restrict input to 5 characters (for "00:00" or "0000")
      const sanitizedValue = newValue.replace(/[^0-9:]/g, ''); // Remove invalid characters
      const colonCount = (sanitizedValue.match(/:/g) || []).length; // Count how many colons exist

      // Ensure that no more than 4 digits are allowed, whether or not the colon is present, also prevent the first char from being a colon
      if (sanitizedValue.replace(/:/g, '').length > 4 || colonCount > 1 || newValue === ':') {
        return; // Prevent further input if limit is exceeded
      }

      const validTimeOptions = convertToTimeOptions(sanitizedValue).filter((time) =>
        isTimeInRange(minTime, maxTime, time.value)
      );

      setInputValue(sanitizedValue);

      // Generate AM and PM options from input
      setOptions((prevOptions) => removeDuplicatesAndSort([...prevOptions, ...validTimeOptions]));
    },
    [minTime, maxTime]
  );

  const handleChange = useCallback(
    (selected) => {
      if (selected) {
        setSelectedOption(selected);
        onChange(selected);
      }
    },
    [onChange]
  );

  const customFilter = useCallback((option, searchText) => {
    const normalize = (str) =>
      str
        .toUpperCase()
        .replace(/^0/, '') // Remove leading zero if it exists
        .replace(/[:\s]/g, ''); // Remove ':' and spaces

    return normalize(option.data.value).includes(normalize(searchText));
  }, []);

  const selectedValue = useMemo(
    () => options.find((el) => el.value === selectedOption),
    [options, selectedOption]
  );

  return (
    <div className={cx('time-picker__wrapper', className)}>
      {label && (
        <label className="label2 small input-label" htmlFor={id}>
          {label}
          {required && <span className="label3"> (Required)</span>}
        </label>
      )}
      <Select
        components={{
          Option,
          Input,
        }}
        options={options}
        menuShouldScrollIntoView
        isClearable
        isDisabled={disabled}
        onInputChange={handleInputChange}
        onChange={handleChange}
        inputValue={inputValue}
        menuPosition={menuPosition}
        value={selectedValue}
        placeholder={placeholder}
        data-qa-id={dataQaId}
        noOptionsMessage={() => 'No valid time options'}
        filterOption={customFilter}
        openMenuOnFocus
        name={name}
        onMenuOpen={onMenuOpen}
        styles={getStyles({error, hideSelectArrow, activeOption, borderColor})}
        menuPortalTarget={menuPortalTarget}
        maxMenuHeight={maxMenuHeight}
      />
      {error && typeof error === 'string' && (
        <span className="input-error" data-qa-id={`${dataQaId || 'timepicker'}_error-message`}>
          {error}
        </span>
      )}
    </div>
  );
};
