import moment from 'moment-timezone';

export const createOption = (value) => ({
  value,
  label: value,
});

export const isTimeInRange = (minTime, maxTime, time) => {
  const minTimeMoment = minTime ? moment(minTime, 'h:mm A') : moment().startOf('day');
  const maxTimeMoment = maxTime ? moment(maxTime, 'h:mm A') : moment().endOf('day');
  const timeMoment = moment(time, 'h:mm A');

  // Check if the time is within the range
  const isAfterMin = timeMoment.isSameOrAfter(minTimeMoment);
  const isBeforeMax = timeMoment.isSameOrBefore(maxTimeMoment);

  return isAfterMin && isBeforeMax;
};

// Function to generate time options with optional minTime and maxTime filtering
export const generateTimeOptions = (step, minTime = null, maxTime = null) => {
  const options = [];
  const startTime = moment().startOf('day'); // Start at 12:00 AM
  const endTime = moment().endOf('day'); // End at 11:59 PM

  while (startTime <= endTime) {
    const formattedTime = startTime.format('h:mm A');

    // Use the isTimeInRange function to check if the time is within the range
    if (isTimeInRange(minTime, maxTime, formattedTime)) {
      options.push(createOption(formattedTime));
    }

    startTime.add(step, 'minutes'); // Increment by step minutes
  }

  return options;
};

// Function to validate and parse time input
const parseInputToTime = (input) => {
  let hours;
  let minutes;

  const normalizedInput = input.replace(/\s+/g, '').toUpperCase();

  if (normalizedInput.includes(':')) {
    [hours, minutes] = normalizedInput.split(':');
    hours = hours.padStart(2, '0');
  } else if (normalizedInput.length === 3 && /^\d+$/.test(normalizedInput)) {
    hours = `0${normalizedInput.substring(0, 1)}`;
    minutes = normalizedInput.substring(1, 3);
  } else if (normalizedInput.length === 4 && /^\d+$/.test(normalizedInput)) {
    hours = normalizedInput.substring(0, 2);
    minutes = normalizedInput.substring(2, 4);
  } else {
    return null; // Invalid input format
  }

  if (parseInt(hours, 10) > 12 || parseInt(minutes, 10) > 59) {
    return null; // Invalid time values
  }

  return {hours, minutes};
};

// Function to process input and generate AM/PM options
export const convertToTimeOptions = (input) => {
  const time = parseInputToTime(input);

  if (!time) return []; // Return empty array for invalid inputs

  const {hours, minutes} = time;

  const timeAM = moment(`${hours}:${minutes} AM`, 'h:mm A').format('h:mm A');
  const timePM = moment(`${hours}:${minutes} PM`, 'h:mm A').format('h:mm A');

  return [createOption(timeAM), createOption(timePM)];
};

// Function to remove duplicates and sort by time (AM first, then PM)
export const removeDuplicatesAndSort = (options) => {
  const uniqueOptions = new Map();

  // Use Map to remove duplicates based on the 'value' field
  options.forEach((option) => {
    if (option?.value && !uniqueOptions.has(option.value)) {
      uniqueOptions.set(option.value, option);
    }
  });

  // Helper function to parse time strings
  const parseTime = (timeStr) => moment(timeStr, 'h:mm A');

  // Sorting function to handle AM/PM
  const timeSort = (timeA, timeB) => {
    if (timeA.format('A') === 'AM' && timeB.format('A') === 'PM') {
      return -1; // AM comes first
    }
    if (timeA.format('A') === 'PM' && timeB.format('A') === 'AM') {
      return 1; // PM comes later
    }

    return timeA - timeB; // Compare times within AM or PM
  };

  // Convert Map values back to an array and sort by time
  return Array.from(uniqueOptions.values()).sort((a, b) => {
    const timeA = parseTime(a.value);
    const timeB = parseTime(b.value);
    return timeSort(timeA, timeB);
  });
};
