import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_Document = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 19;
  const height = 20;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 20H1.5C1.23478 20 0.98043 19.8946 0.792893 19.7071C0.605357 19.5196 0.5 19.2652 0.5 19V1C0.5 0.734784 0.605357 0.48043 0.792893 0.292893C0.98043 0.105357 1.23478 0 1.5 0H17.5C17.7652 0 18.0196 0.105357 18.2071 0.292893C18.3946 0.48043 18.5 0.734784 18.5 1V19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20ZM16.5 18V2H2.5V18H16.5ZM5.5 5H13.5V7H5.5V5ZM5.5 9H13.5V11H5.5V9ZM5.5 13H10.5V15H5.5V13Z"
        fill={color}
      />
    </svg>
  );
};
