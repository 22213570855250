import {useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {selectThemes} from '~/SignUpSheets/reducers/themes/selectors';
import {Themes} from './Themes';

export const Categories = () => {
  const themes = useSelector(selectThemes);

  return themes?.map((theme) => (
    <div key={theme.category_id}>
      <Typography
        variant="label2"
        className="theme-picker-overlay__content__categories"
        color="pebble"
      >
        {theme.category_name}
      </Typography>
      <Themes themes={theme.themes} />
    </div>
  ));
};
