import {useCallback, useState} from 'react';
import {PlusIcon} from '~/common/svg/PlusIcon';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Theme} from './Theme';

export const Themes = ({themes}) => {
  const [showMore, setShowMore] = useState(false);
  const themesToShow = showMore ? themes : themes.slice(0, 4);

  const onClickHandler = useCallback(() => setShowMore(true), []);

  return (
    <div className="theme-picker-overlay__content__themes">
      <div className="grid-container">
        {themesToShow?.map((theme, idx) => (
          <Theme key={theme.theme_id} theme={theme} idx={idx} />
        ))}
      </div>
      {!showMore && themes.length > 4 && (
        <Button
          variant="transparent"
          onClick={onClickHandler}
          className="theme-picker-overlay__content__themes__show-more-button"
        >
          <PlusIcon ratio={0.5} /> Show more
        </Button>
      )}
    </div>
  );
};
