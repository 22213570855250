export const SET_SHEET = 'SignUpSheet/*/SET_SHEET';
export const UPDATE_SHEET_DETAILS = 'SignUpSheet/*/UPDATE_SHEET_DETAILS';
export const UPDATE_GROUP = 'SignUpSheet/*/UPDATE_GROUP';
export const DUPLICATE_GROUP = 'SignUpSheet/*/ DUPLICATE_GROUP';
export const DELETE_GROUP = 'SignUpSheet/*/DELETE_GROUP';
export const EDIT_SIGNUP_OPTION = 'SignUpSheet/*/EDIT_SIGNUP_OPTION';
export const DELETE_SIGNUP_OPTION = 'SignUpSheet/*/DELETE_SIGNUP_OPTION';
export const MOVE_SIGNUP_OPTION_UP = 'SignUpSheet/*/MOVE_SIGNUP_OPTION_UP';
export const MOVE_SIGNUP_OPTION_DOWN = 'SignUpSheet/*/MOVE_SIGNUP_OPTION_DOWN';
export const UPDATE_ERRORS = 'SignUpSheet/*/UPDATE_ERRORS';
export const SET_SELECTED_SIGNUPS = 'SignUpSheet/*/SET_SELECTED_SIGNUPS';
export const SET_SIGNUPS_PENDING_DELETION = 'SignUpSheet/*/SET_SIGNUPS_PENDING_DELETION';
export const UPDATE_SIGNUPS = 'SignUpSheet/*/UPDATE_SIGNUPS';
export const SET_SIGNUPS = 'SignUpSheet/*/SET_SIGNUPS';
export const REMOVE_SIGNUP = 'SignUpSheet/*/REMOVE_SIGNUP';
export const TOGGLE_SHOW_VALIDATION_MODAL = 'SignUpSheet/*/TOGGLE_SHOW_VALIDATION_MODAL';
export const ENABLE_GROUPING = 'SignUpSheet/*/ENABLE_GROUPING';
export const DISABLE_GROUPING = 'SignUpSheet/*/DISABLE_GROUPING';
export const TOGGLE_SHOW_NOTIFY_VOLUNTEERS_MODAL =
  'SignUpSheet/*/TOGGLE_SHOW_NOTIFY_VOLUNTEERS_MODAL';
export const APPLY_TEMPLATE = 'SignUpSheet/*/APPLY_TEMPLATE';
export const UPDATE_MULTIPLE_DATES_MODAL = 'SignUpSheet/*/UPDATE_MULTIPLE_DATES_MODAL';
export const UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB =
  'SignUpSheet/*/UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB';
export const UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB =
  'SignUpSheet/*/UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB';
export const UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER =
  'SignUpSheet/*/UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER';
export const UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE =
  'SignUpSheet/*/UPDATE_MULTIPLE_SLOTS_MODA_FORM_CHANGE';
export const UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT =
  'SignUpSheet/*/UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT';
export const UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM =
  'SignUpSheet/*/UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM';
export const UPDATE_OPEN_GROUPS = 'SignUpSheet/*/UPDATE_OPEN_GROUPS';
export const SCOPE_HOST = 'host';
export const SCOPE_USER = 'user';
