import {Fragment, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';
import {
  selectSignupOptionGroups,
  selectSignupOptions,
  selectStartDate,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {sortGroups} from '~/SignUpSheets/utils/misc';
import {SignUpItem} from './SignUpItem/SignUpItem';

export const SignUpItems = ({sortedAndFilteredSignups}) => {
  const {linkPreviews} = useAffiliateActions();
  const startDate = useSelector(selectStartDate);
  const signupOptions = useSelector(selectSignupOptions);
  const groups = useSelector(selectSignupOptionGroups);

  const yourSignups = useMemo(
    () =>
      signupOptions
        .map((s) => ({
          item: s.item,
          title: s.slots?.find((i) => i.slot_id === s.slot_id)?.title,
          date: s.date,
          option: s.slot_id || s.uuid,
          description: s.description,
          start_time: s.start_time,
          end_time: s.end_time,
          slot_description: s.slot_description,
          signups: sortedAndFilteredSignups.filter(
            (item) => (!item.slot_id || item.slot_id === s.slot_id) && item.signup_option === s.uuid
          ),
          product: {
            ...(s.product_link ?? {}),
            ...linkPreviews[s.slot_id || s.uuid],
          },
          group: groups?.[s.group] ?? null,
        }))
        .toSorted(sortGroups)
        .filter((s) => s.signups.length > 0)
        .map((s) => ({
          ...s,
          count: s.signups.length,
          signupWithComment: s.signups.find((signup) => signup.comment !== ''),
          calendarLinks: s.signups.length > 0 ? s.signups[0].calendar_links : undefined,
        })),
    [signupOptions, sortedAndFilteredSignups, linkPreviews, groups]
  );

  // eslint-disable-next-line arrow-body-style
  return yourSignups.map((signup, index) => (
    <Fragment key={signup.option}>
      {index > 0 && <hr className="signup-sheet__volunteer-confirm__separator" />}
      <SignUpItem
        item={signup.item}
        date={signup.date || startDate}
        description={signup.description}
        count={signup.count}
        comment={signup.signupWithComment?.comment}
        product={signup.product}
        group={signup.group}
      />
    </Fragment>
  ));
};
