import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Counter} from '~/common/_pb_components/atoms/Counter';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {TimeIncrementSelector} from '~/SignUpSheets/Create/routes/Customize/components/AddMultipleSlots/TimeIncrementSelector';
import {TimeRangePicker} from '~/SignUpSheets/Create/routes/Customize/components/AddMultipleSlots/TimeRangePicker';
import {UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectMultipleSlotsModalEndTime,
  selectMultipleSlotsModalIntervalCount,
  selectMultipleSlotsModalIntervalUnit,
  selectMultipleSlotsModalSignupEachSlot,
  selectMultipleSlotsModalSlots,
  selectMultipleSlotsModalStartTime,
  selectMultipleSlotsModalTimeIncrementValue,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {useMultipleSlots} from './hooks/useMultipleSlots';

export const OverlayContent = ({onClose, onSubmit, showActions = true}) => {
  const dispatch = useDispatch();
  const startTime = useSelector(selectMultipleSlotsModalStartTime);
  const endTime = useSelector(selectMultipleSlotsModalEndTime);
  const timeIncrementValue = useSelector(selectMultipleSlotsModalTimeIncrementValue);
  const intervalUnit = useSelector(selectMultipleSlotsModalIntervalUnit);
  const signupEachSlot = useSelector(selectMultipleSlotsModalSignupEachSlot);
  const intervalCount = useSelector(selectMultipleSlotsModalIntervalCount);
  const slots = useSelector(selectMultipleSlotsModalSlots, shallowEqual);

  useMultipleSlots();

  const handleQuantityIncrease = useCallback(
    () =>
      dispatch({
        type: UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
        payload: {signupEachSlot: signupEachSlot + 1},
      }),
    [signupEachSlot]
  );
  const handleQuantityDecrease = useCallback(
    () =>
      dispatch({
        type: UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
        payload: {signupEachSlot: signupEachSlot - 1},
      }),
    [signupEachSlot]
  );
  const handleQuantityChange = useCallback(
    (value) =>
      dispatch({
        type: UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
        payload: {signupEachSlot: value},
      }),
    [dispatch]
  );

  const handleAddSlots = useCallback(() => {
    onSubmit?.(slots);

    window.dataLayer.push({event: 'success-slots-added-time-modal'});

    onClose?.();
  }, [intervalCount, startTime, signupEachSlot, slots, onSubmit]);

  const handleStartTimeChange = useCallback(
    (value) => {
      dispatch({
        type: UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
        payload: {startTime: value},
      });
      window.dataLayer.push({event: 'start-date added-time-modal', startTime: value});
    },
    [dispatch]
  );

  const handleEndTimeChange = useCallback(
    (value) => {
      dispatch({type: UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE, payload: {endTime: value}});
      window.dataLayer.push({event: 'end-date added-time-modal', endTime: value});
    },
    [dispatch]
  );

  const handleDurationChange = useCallback(
    (value) => {
      dispatch({
        type: UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
        payload: {timeIncrementValue: value},
      });
      window.dataLayer.push({event: 'time-increment-set-time-modal', duration: value});
    },
    [dispatch]
  );

  const handleIncrementsChange = useCallback(
    (unit) => {
      dispatch({
        type: UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
        payload: {intervalUnit: unit},
      });
      window.dataLayer.push({event: 'time-increment-set-time-modal', increments: unit});
    },
    [dispatch]
  );

  return (
    <div className="add-multiple-slots__form">
      <div className="add-multiple-slots__form-group">
        <Typography as="label" variant="label2">
          Time Range
        </Typography>
        <TimeRangePicker
          startTime={startTime}
          endTime={endTime}
          setStartTime={handleStartTimeChange}
          setEndTime={handleEndTimeChange}
        />
      </div>
      <div className="add-multiple-slots__form-group">
        <Typography as="label" variant="label2">
          Time Incrementals
        </Typography>
        <TimeIncrementSelector
          timeIncrementValue={timeIncrementValue}
          setTimeIncrementValue={handleDurationChange}
          intervalUnit={intervalUnit}
          setIntervalUnit={handleIncrementsChange}
        />
      </div>
      <div className="add-multiple-slots__form-group">
        <Counter
          className="add-multiple-slots__counter"
          labelClassName="add-multiple-slots__label-nowrap"
          title="Signup Each Slot"
          count={signupEachSlot}
          min={1}
          max={99}
          onPlus={handleQuantityIncrease}
          onMinus={handleQuantityDecrease}
          inputCallback={handleQuantityChange}
          data-qa-id="quantity-counter"
        />
      </div>
      {showActions && (
        <div className="add-multiple-slots__form-actions">
          <Button
            data-qa-id="cancel-button"
            variant="secondary"
            size="large"
            onClick={onClose}
            fullWidth
          >
            Cancel
          </Button>
          <Button
            data-qa-id="add-slots-button"
            onClick={handleAddSlots}
            size="large"
            fullWidth
            disabled={!intervalCount}
          >
            Add {intervalCount > 0 && `${intervalCount} time`} slots
          </Button>
        </div>
      )}
    </div>
  );
};

OverlayContent.propTypes = {
  showActions: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
