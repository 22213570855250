import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactSelect, {components as SelectComponents} from 'react-select';
import PropTypes from 'prop-types';
import {
  coin,
  ghost,
  cloud,
  charcoal,
  cottonCandy,
  aquamarine,
  menuHover,
  formBorder,
  formBorderDisabled,
  formInputBg,
} from '~sass/pb_styleguide/base/_exports.sass';
// eslint-disable-next-line camelcase
import {PB_Chevron} from '~/common/svg/PB_Chevron';
import {Typography} from '~/common/_pb_components/atoms/Typography';

export const DropdownIndicator = (dropdownProps) => (
  <SelectComponents.DropdownIndicator {...dropdownProps} className="select__dropdown-indicator">
    <PB_Chevron ratio={0.5} direction="down" />
  </SelectComponents.DropdownIndicator>
);

export const Option = (optionProps) => {
  const {label, selectOption} = optionProps;
  const splitLabel = label.split(' ');
  const joinedLabel = splitLabel.slice(0, -1).join('_');
  const labelCount = splitLabel.slice(-1)[0].replace(/[{()}]/g, '');
  return (
    <SelectComponents.Option {...optionProps} className="select__option">
      <Typography
        as="span"
        variant="paragraph2"
        id={label}
        data-qa-id={`${joinedLabel}_suggestion`}
        data-qa-value={labelCount}
        onClick={() => selectOption(optionProps)}
      >
        {label}
      </Typography>
    </SelectComponents.Option>
  );
};

const SelectContainer = ({children, ...rest}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SelectComponents.SelectContainer
    {...rest}
    innerProps={{...rest.innerProps, 'data-qa-id': rest.selectProps['data-qa-id']}}
  >
    {children}
  </SelectComponents.SelectContainer>
);

const SelectMenu = ({children, ...rest}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SelectComponents.Menu
    {...rest}
    innerProps={{...rest.innerProps, 'data-qa-id': `${rest.selectProps['data-qa-id']}-select-menu`}}
  >
    {children}
  </SelectComponents.Menu>
);

export const Select = ({
  className,
  onChange,
  value,
  menuPosition,
  customStyles,
  options,
  placeholder,
  components,
  size = 'normal',
  'data-qa-id': dataQaId,
}) => {
  const [isOpen, setOpen] = useState(false);

  // eslint-disable-next-line react/no-unstable-nested-components
  const Control = (controlProps) => {
    const {innerProps, children} = controlProps;
    return (
      <SelectComponents.Control {...controlProps}>
        {/* Keep the following rule (don't add role=button) or else automated tests can't click inside the span */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          tabIndex={-1}
          onKeyUp={null}
          key={innerProps.key}
          data-qa-id={`${dataQaId}-control`}
          className="select__control"
          onClick={() => setOpen(true)}
        >
          {children}
        </span>
      </SelectComponents.Control>
    );
  };

  const ref = useRef(null);
  const active = useRef(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.onControlTouchEnd = () => {
        ref.current.userIsDragging = true;
      };
    }
  }, [ref]);

  const onFocus = useCallback(() => {
    ref.current?.focus();
    active.current = true;
  }, []);

  const onBlur = useCallback(() => {
    ref.current?.blur();
    active.current = false;
  }, []);

  const styles = useMemo(() => {
    const isSmall = size === 'small';
    return {
      control: (provided, {isDisabled}) => ({
        ...provided,
        height: '100%',
        paddingLeft: isSmall ? '0.25rem' : '1rem',
        paddingRight: isSmall ? '0.25rem' : '1rem',
        fontFamily: 'Brut',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1rem',
        borderColor: active.current ? aquamarine : formBorder,
        borderWidth: 1,
        boxShadow: undefined,
        backgroundColor: isDisabled ? formBorderDisabled : formInputBg,
        color: isDisabled ? coin : charcoal,
        cursor: 'pointer',
        ...customStyles?.control,
      }),
      placeholder: (provided) => ({
        ...provided,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: cloud,
        ...customStyles?.placeholder,
      }),
      indicatorSeparator: () => customStyles?.indicatorSeparator,
      container: (provided) => ({
        ...provided,
        width: isSmall ? 'auto' : '100%',
        height: isSmall ? 'auto' : '3rem',
        ...customStyles?.container,
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '0.5rem',
        padding: 0,
        ...customStyles?.menu,
      }),
      menuList: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        borderRadius: '0.5rem',
        ...customStyles?.menuList,
      }),
      valueContainer: (provided) => ({
        ...provided,
        margin: 0,
        padding: '0 0.5rem',
        ...customStyles?.valueContainer,
      }),
      singleValue: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        textAlign: 'left',
        ...customStyles?.singleValue,
      }),
      option: (provided, {isSelected}) => ({
        ...provided,
        backgroundColor: isSelected ? cottonCandy : ghost,
        color: isSelected ? charcoal : charcoal,
        '&:hover': {backgroundColor: menuHover},
        ...customStyles?.option,
      }),
    };
  }, [
    customStyles?.control,
    customStyles?.placeholder,
    customStyles?.indicatorSeparator,
    customStyles?.container,
    customStyles?.menu,
    customStyles?.menuList,
    customStyles?.valueContainer,
    customStyles?.singleValue,
    customStyles?.option,
    size,
  ]);

  return (
    <ReactSelect
      ref={ref}
      isCreatable={false}
      isSearchable={false}
      onChange={(newValue) => {
        onChange(newValue.value);
      }}
      value={value}
      menuPosition={menuPosition || 'absolute'}
      styles={styles}
      options={options}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      components={{
        DropdownIndicator,
        Option,
        Control,
        SelectContainer,
        Menu: SelectMenu,
        ...(components || {}),
      }}
      data-qa-id={dataQaId}
      onMenuClose={() => setOpen(false)}
      menuIsOpen={isOpen}
      onMenuOpen={() => setOpen(true)}
      className={className}
    />
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ]),
  customStyles: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ])
    )
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  'data-qa-id': PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
};
