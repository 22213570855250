import cx from 'classnames';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectGroupIsPast,
  selectSignupOptionIsFull,
  selectGroupIsFull,
  selectSignupOptionIsPast,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import './GroupAvailabilityTag.sass';

export const GroupAvailabilityTag = ({groupId, signupOption, className}) => {
  const groupIsPast = useSelector(selectGroupIsPast(groupId));
  const groupIsFull = useSelector(selectGroupIsFull(groupId));
  const slotIsPast = useSelector(selectSignupOptionIsPast(signupOption));
  const slotIsFull = useSelector(selectSignupOptionIsFull(signupOption));

  const isPast = groupIsPast || (!groupId && slotIsPast);
  const isFull = groupIsFull || (!groupId && slotIsFull);

  let tagText = 'Signups Available';
  if (isPast) tagText = 'Signup Passed';
  else if (isFull) tagText = 'Signup Full';

  return (
    <Typography
      variant="label2"
      size="small"
      color={isPast || isFull ? 'coin' : 'emeraldWebText'}
      className={cx('group-availability-tag', className)}
    >
      {tagText}
    </Typography>
  );
};

GroupAvailabilityTag.propTypes = {
  groupId: PropTypes.string,
  signupOption: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    item: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
    position_index: PropTypes.number,
    title: PropTypes.string,
    all_day: PropTypes.bool,
    slots: PropTypes.arrayOf(
      PropTypes.shape({
        slot_id: PropTypes.string,
        title: PropTypes.string,
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        description: PropTypes.string,
        product_link: PropTypes.shape({
          url: PropTypes.string,
          display_text: PropTypes.string,
          not_safe: PropTypes.bool,
        }),
      })
    ),
  }),
  className: PropTypes.string,
};
