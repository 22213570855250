import cx from 'classnames';
import React from 'react';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Link} from '~/common/_pb_components/atoms/Link';

export const MenuItem = ({
  index,
  action,
  href,
  children,
  disabled,
  dataQaId = '',
  danger = false,
}) => (
  <div className="menu-content__item">
    {href ? (
      <Link
        as="transparent"
        className={cx('menu-content__item__button', {danger})}
        data-qa-id={`${dataQaId}-${index}`}
        href={href}
        disabled={disabled}
      >
        {children}
      </Link>
    ) : (
      <Button
        variant="unstyled-button"
        className={cx('menu-content__item__button', {danger})}
        data-qa-id={`${dataQaId}-${index}`}
        onClick={() => action(index)}
        disabled={disabled}
      >
        {children}
      </Button>
    )}
  </div>
);
