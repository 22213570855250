import {useCallback} from 'react';

export const useScrollSlotIntoView = ({ref, offset = 0, behavior = 'instant'}) => {
  const trigger = useCallback(() => {
    const slotElement = ref.current;
    if (slotElement) {
      const susNavbarHeight = document.querySelector('.signup-sheet__header__wrapper').offsetHeight;
      const slotPosition = slotElement.getBoundingClientRect().top + window.scrollY - offset;
      const offsetPosition = slotPosition - susNavbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior,
      });
    }
  }, []);

  return [trigger];
};
