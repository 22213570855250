import {useSelector} from 'react-redux';
import {DatesSelector} from './components/DatesSelector/DatesSelector';
import {FrequencySelector} from './components/FrequencySelector/FrequencySelector';
import {DaysSelector} from './components/DaysSelector/DaysSelector';
import {selectMultipleDatesModalFrequency} from '~/SignUpSheets/reducers/sheet/selectors';
import './Repeating.sass';

export const Repeating = () => {
  const frequency = useSelector(selectMultipleDatesModalFrequency);

  return (
    <div className="add-multiple-dates__repeating__form">
      <DatesSelector />
      <FrequencySelector />
      {frequency !== 'monthly' && <DaysSelector />}
    </div>
  );
};
