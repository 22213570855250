import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Modal} from '~/common/_pb_components/organisms/Modal';
import {SlideoutPanel} from '~/common/_pb_components/molecules/SlideoutPanel';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {Spinner} from '~/common/components/Loader/Spinner';
import {PanelHeaderRow} from '~/common/_pb_components/atoms/PanelHeaderRow';

// Intended for more complex modal data, this opens the right-side
// slideout-panel (used for login/signup) on large screens,
// and a full-page slide up modal in small screens.

// REQUIRES isOpen, onClose, as well as the following:
// also REQUIRES either children:
//    (will render children with X close button in upper right in an otherwise empty modal)
// OR: ALL the below:
//  title: (string),
//  body: (string),
//  primaryAction: (func called on primary CTA),

// Additional optional props:
//  primaryText: (string:Submit - text for primary button,
//  secondaryText: (string:Cancel - text for secondary button)
//  primaryAction: (func:primaryAction - action for primary button),
//  secondaryAction: (func:onClose - action for secondary button)
//  showCover: (bool:False - If true, shows semi-transparent dark overlay when panel is open),
//  modalId: (string:overlay - identifier for this modal, useful for custom qa-id),
//  closeQaId: (string:overlay-header_back_button - for qa-ids on close button/X icon)

export const ResponsivePanel = (props) => {
  const {matches: mdWindow} = useMatchQuery(`(min-width: 48rem)`);
  const body = props.body || '';
  const showHeader = props.showHeader != null ? props.showHeader : true;
  const primaryAction = props.primaryAction || props.onClose;
  const secondaryAction = props.secondaryAction || props.onClose;
  const primaryText = props.primaryText || 'Submit';
  const secondaryText = props.secondaryText || 'Cancel';
  const showSpinner = props.showSpinner || false;
  const actionText = props.actionText || 'One moment please...';
  const {isOpen, showCover = false} = props;
  const titleVariant = props.titleVariant || 'header5';
  const modalId = props.modalId || 'overlay';
  const showBottomNav = props.showBottomNav != null ? props.showBottomNav : true;

  const title = showSpinner
    ? actionText
    : props.title || `${props.primaryText}?` || 'Are you sure?';
  let children = showSpinner ? <Spinner center /> : props.children;

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  const getModalChildren = () => (
    <div className={props.className || null}>
      <div className="responsive-modal-body">
        <Typography variant="paragraph2">{body}</Typography>
      </div>
      {showBottomNav && (
        <div className="responsive-modal-button-row">
          <Button
            variant="secondary"
            size="large"
            onClick={secondaryAction}
            data-qa-id={`${modalId}-cancel`}
          >
            {secondaryText}
          </Button>
          <Button
            variant="primary"
            size="large"
            onClick={primaryAction}
            data-qa-id={`${modalId}-submit`}
          >
            {primaryText}
          </Button>
        </div>
      )}
    </div>
  );

  if (!children)
    if (mdWindow) {
      children = getModalChildren();
    } else {
      children = (
        <>
          {showHeader && (
            <PanelHeaderRow
              title={title}
              onClose={props.onClose}
              qaId={`${modalId}-header`}
              backArrow={props.backArrow}
              titleVariant={titleVariant}
            />
          )}
          {getModalChildren()}
        </>
      );
    }

  if (!mdWindow) {
    if (props.children) {
      children = (
        <>
          {showHeader && (
            <PanelHeaderRow
              title={title}
              onClose={props.onClose}
              qaId={`${modalId}-header`}
              backArrow={props.backArrow}
              titleVariant={titleVariant}
            />
          )}
          {props.children}
        </>
      );
    }
    return (
      <Modal
        children={children}
        className={props.className}
        isOpen={isOpen}
        onClose={() => props.onClose()}
        onClick={() => props.onClose()}
        modalId={`responsive-modal ${modalId || ''}`}
      />
    );
  }

  const Component = (
    <SlideoutPanel
      isOpen={isOpen}
      onClose={() => props.onClose()}
      closeQaId={`${modalId}-header`}
      children={children}
      modalId={`responsive-modal ${modalId || ''}`}
      title={showSpinner ? actionText : title || null}
      showSpinner={showSpinner}
      showHeader={showHeader}
      titleVariant={titleVariant}
      qaId="responsive-modal-panel"
      backArrow={props.backArrow}
      showCover={showCover}
    />
  );

  if (props.portalMode) {
    return createPortal(Component, document.getElementById('pbModalRoot'));
  }

  return Component;
};

ResponsivePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showHeader: PropTypes.bool,
  modalId: PropTypes.string,
  primaryAction: PropTypes.func,
  primaryText: PropTypes.string,
  portalMode: PropTypes.bool,
};
