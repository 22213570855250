import cx from 'classnames';
import React, {useState, useEffect, useMemo, useCallback, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {PB_Chevron as Chevron} from '~/common/svg/PB_Chevron';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Button} from '~/common/_pb_components/atoms/Button';
import {useBrowserWidth} from '~/common/hooks';
import {pebble} from '~sass/pb_styleguide/base/_exports.sass';

export const resizeAccordion = (id, toggle = false) => {
  const accordionBodyWrapper = document.getElementById(id);
  const accordionBody = document.getElementById(`${id}_inner`);
  if (!accordionBodyWrapper || !accordionBody) return;
  if (toggle) {
    accordionBodyWrapper.style.height = 0;
  } else {
    accordionBodyWrapper.style.height = `${accordionBody.clientHeight}px`;
  }
};

export const Accordion = forwardRef(
  (
    {
      isOpen = true,
      accordionBodyWrapperId = '',
      onOpen,
      onClose,
      children,
      className = '',
      accordionQaId = '',
      alwaysOpen = false,
      accordionButtonClassName = '',
      label = null,
      labelVariant = 'label3',
      labelColor = 'charcoal',
      labelClassName = '',
      subLabel = null,
      subLabelVariant = 'header5',
      subLabelColor = labelColor,
      labelDescription,
      labelDescriptionVariant = 'paragraph3',
      labelDescriptionColor = 'pebble',
      labelDecoration = null,
      additionalLabelContent = null,
      chevron = null,
      uniqueAccordionBodyWrapperId,
      arrowPlacement = 'right',
    },
    ref
  ) => {
    const [open, setOpen] = useState(isOpen);
    const browserWidth = useBrowserWidth({timeout: 150});

    const bodyWrapperId = useMemo(
      () =>
        uniqueAccordionBodyWrapperId ??
        `${accordionBodyWrapperId}-${Math.floor(Math.random() * 999999) + 1}`,
      [uniqueAccordionBodyWrapperId, accordionBodyWrapperId]
    );

    const handleToggleAccordion = useCallback(() => {
      resizeAccordion(bodyWrapperId, true);
      setOpen(!open);
      if (open && onClose) onClose();
      else if (onOpen) onOpen();
    }, [bodyWrapperId, open, onOpen, onClose]);

    useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
      resizeAccordion(bodyWrapperId, !open);
    }, [children, open, bodyWrapperId, browserWidth]);

    // can't use string concat - these are elements
    const labelToShow = useMemo(
      () =>
        labelDecoration ? (
          <>
            {label} {labelDecoration}
          </>
        ) : (
          label
        ),
      [label, labelDecoration]
    );

    const LabelElement = useMemo(() => {
      if (subLabel || labelDescription || additionalLabelContent) {
        return (
          <div className="accordion__label-wrapper">
            <Typography variant={labelVariant} className={labelClassName} color={labelColor}>
              {labelToShow}
            </Typography>
            {subLabel && (
              <Typography variant={subLabelVariant} color={subLabelColor}>
                {subLabel}
              </Typography>
            )}
            {/* Label description is an element */}
            {labelDescription && typeof labelDescription !== 'string' && labelDescription}
            {/* Label description is a simple string */}
            {labelDescription && typeof labelDescription === 'string' && (
              <Typography variant={labelDescriptionVariant} color={labelDescriptionColor}>
                {labelDescription}
              </Typography>
            )}
            {additionalLabelContent}
          </div>
        );
      }
      if (labelToShow) {
        return (
          <Typography variant={labelVariant} className={labelClassName} color={labelColor}>
            {labelToShow}
          </Typography>
        );
      }
      return null;
    }, [
      subLabel,
      labelDescription,
      additionalLabelContent,
      labelVariant,
      labelClassName,
      labelToShow,
      subLabelVariant,
      labelDescriptionVariant,
      labelColor,
      subLabelColor,
      labelDescriptionColor,
    ]);

    return (
      <div className={cx('accordion__container', className)} data-qa-id={accordionQaId} ref={ref}>
        {!alwaysOpen && (
          <Button
            className={cx(
              'accordion__button',
              {[`accordion__arrow-${arrowPlacement}`]: arrowPlacement},
              accordionButtonClassName
            )}
            size="small"
            variant="transparent"
            data-qa-id={`accordion-show-hide-content_${label?.replaceAll?.(' ', '_') ?? uniqueAccordionBodyWrapperId.replaceAll(' ', '_')}`}
            onClick={handleToggleAccordion}
          >
            {LabelElement}
            {chevron ?? <Chevron direction={open ? 'down' : 'up'} ratio={0.5} color={pebble} />}
          </Button>
        )}
        <div className="accordion__body-wrapper" id={bodyWrapperId}>
          <div id={`${bodyWrapperId}_inner`}>{children}</div>
        </div>
      </div>
    );
  }
);

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  accordionBodyWrapperId: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  accordionQaId: PropTypes.string,
  alwaysOpen: PropTypes.bool,
  accordionButtonClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelVariant: PropTypes.string,
  labelColor: PropTypes.string,
  labelClassName: PropTypes.string,
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subLabelVariant: PropTypes.string,
  subLabelColor: PropTypes.string,
  labelDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelDescriptionVariant: PropTypes.string,
  labelDescriptionColor: PropTypes.string,
  labelDecoration: PropTypes.node,
  additionalLabelContent: PropTypes.node,
  chevron: PropTypes.node,
  uniqueAccordionBodyWrapperId: (props, propName, componentName) => {
    if (!props.accordionBodyWrapperId && !props.uniqueAccordionBodyWrapperId)
      return new Error(
        `One of props 'accordionBodyWrapperId' or 'uniqueAccordionBodyWrapperId' was not specified in '${componentName}'.`
      );
    return undefined;
  },
  arrowPlacement: PropTypes.oneOf(['left', 'right']),
};
