import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_CalendarAdd = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 35;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 0V3.42857H3.55557C1.59467 3.42857 0 4.96628 0 6.85714V30.8571C0 32.7479 1.59467 34.2857 3.55557 34.2857H28.4443C30.4053 34.2857 32 32.7479 32 30.8571V6.85714C32 4.96628 30.4053 3.42857 28.4443 3.42857H26.6667V0H23.1111V3.42857H8.88889V0H5.33333ZM3.55557 6.85714H28.4443V10.2857H3.55557V6.85714ZM3.55557 13.7143H28.4443L28.448 30.8571H3.55557V13.7143ZM14.2222 17.1429V20.5714H10.6667V24H14.2222V27.4286H17.7778V24H21.3333V20.5714H17.7778V17.1429H14.2222Z"
        fill={color}
      />
    </svg>
  );
};
