import moment from 'moment-timezone';
import {useCallback, useContext, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useIsLargeAndUp, useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {HeaderActionButtons} from '~/SignUpSheets/components/Header/HeaderActionButtons';
import {HeaderLeaveButton} from '~/SignUpSheets/components/Header/HeaderLeaveButton';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {
  selectIsPreviewMode,
  selectIsSheetExpiredOrCanceled,
  selectShortLink,
  selectUpdatedAt,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {SignUpSheetsIcon} from '~/common/svg/SignUpSheetsIcon';
import {PreviewToggle} from './components/PreviewToggle/PreviewToggle';
import {SheetOptions} from './components/SheetOptions/SheetOptions';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {PB_LinkIcon as LinkIcon} from '~/common/svg/PB_LinkIcon';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Button} from '~/common/_pb_components/atoms/Button';
import {UPDATE_SHEET_DETAILS} from '~/SignUpSheets/reducers/sheet/constants';

import './Header.sass';

export const Header = ({handlePrint, trackChangeUpstream}) => {
  const {flow} = useContext(SignUpSheetContext);
  const dispatch = useDispatch();
  const shortLink = useSelector(selectShortLink);
  const updatedAt = useSelector(selectUpdatedAt);
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const lgScreens = useIsLargeAndUp();
  const isDesktop = useIsLargishAndUp();
  const [copied, setCopied] = useState(false);

  const expiredOrCanceled = useSelector(selectIsSheetExpiredOrCanceled);

  const onShareHandler = useCallback(() => {
    navigator.clipboard
      .writeText(shortLink)
      .then(() => {
        setCopied(true);
        if (!isDesktop) {
          toastAlert('Copied shareable link!', {
            id: 'shareable-link-copy-success',
            toastId: 'shareable-link-copy-success',
            icon: <LinkIcon />,
            type: 'information',
            position: 'top-right',
            autoClose: 5000,
          });
        }
        setTimeout(() => setCopied(false), 1500);
      })
      .catch((error) => {
        evite.error('Failed to copy', error);
      });
  }, [shortLink]);

  const lastSavedDescriptor = useMemo(() => {
    const now = moment();
    const lastUpdated = moment(updatedAt);

    const startOfToday = moment().startOf('day');
    const lastUpdatedDay = moment(updatedAt).startOf('day');

    if (lastUpdated.isSame(now, 'day')) return lastUpdated.format('h:mm A');
    if (startOfToday.diff(lastUpdatedDay, 'days') === 1) {
      return `yesterday at ${lastUpdated.format('h:mm A')}`;
    }
    return lastUpdated.format('M/D/YYYY h:mm A');
  }, [updatedAt]);

  const returnToEdit = useCallback(
    () =>
      dispatch({
        type: UPDATE_SHEET_DETAILS,
        payload: {previewMode: false},
      }),
    [dispatch]
  );

  return (
    <div className="signup-sheet__header__wrapper">
      <div className="signup-sheet__header">
        <div className="signup-sheet__header__info">
          <HeaderLeaveButton />
          <div className="signup-sheet__header__logo-and-saved-date">
            <SignUpSheetsIcon ratio={0.76} />
            {['create', 'edit'].includes(flow) && updatedAt && (
              <Typography variant="label2" size={isDesktop ? null : 'smaller'} color="pebble">
                Saved {lastSavedDescriptor}
              </Typography>
            )}
          </div>
          {!lgScreens && !expiredOrCanceled && flow === 'view' ? (
            <SheetOptions onCopyShareableLink={onShareHandler} onGetPrintableSheet={handlePrint} />
          ) : null}
        </div>

        {/* Show header action buttons if on desktop, or in medium screen in create/edit, and only if sheet is not expired */}
        {!expiredOrCanceled ? (
          <HeaderActionButtons
            trackChangeUpstream={trackChangeUpstream}
            handleShare={onShareHandler}
            handlePrint={handlePrint}
            copied={copied}
          />
        ) : null}
      </div>
      {!isDesktop && <PreviewToggle />}
      {isDesktop && isPreviewMode && (
        <div className="signup-sheet__header__preview-banner">
          <Typography variant="paragraph3" semibold>
            This is a preview of your SignUp Sheet.
          </Typography>
          <Button variant="link" data-qa-id="preview-banner-return-to-edit" onClick={returnToEdit}>
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};
