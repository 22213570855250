import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PlusIcon} from '~/common/svg/PlusIcon';
import {PB_Trash as TrashIcon} from '~/common/svg/PB_Trash';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';

import {pebble, white, mediumAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';

import './AddButtonWithTitle.sass';

export const AddButtonWithTitle = ({
  label = 'default',
  onClick,
  dataQaId,
  className = '',
  wrapperClass = '',
  showRemoveIcon = false,
  disabled = false,
  variant = 'transparent',
  fullWidth = false,
  rounded = true,
  size = variant === 'primary' ? 'large' : 'small',
  colorIcon = null,
  color = variant === 'transparent' ? 'asphalt' : undefined,
}) => {
  const {matches: isDesktop} = useMatchQuery(`(${mediumAndUpQuery})`);

  return (
    <div className={cx('add-button-with-title-wrapper', variant, wrapperClass)}>
      <Button
        size={size}
        variant={variant}
        onClick={onClick}
        data-qa-id={dataQaId}
        className={cx('add-button-with-title', className)}
        disabled={disabled}
        disableProcessing
        fullWidth={fullWidth}
        rounded={rounded}
      >
        {!showRemoveIcon && (
          <PlusIcon ratio={0.5} color={colorIcon || (variant === 'primary' ? white : pebble)} />
        )}
        {showRemoveIcon && <TrashIcon color={variant === 'primary' ? white : pebble} />}
        <Typography
          variant={isDesktop ? 'list2' : 'paragraph2'}
          size={isDesktop ? 'large' : 'small'}
          semibold={variant === 'transparent'}
          color={color}
        >
          {label}
        </Typography>
      </Button>
    </div>
  );
};

AddButtonWithTitle.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  dataQaId: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  showRemoveIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['transparent', 'primary']),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
  colorIcon: PropTypes.string,
};
