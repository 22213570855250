import moment from 'moment-timezone';
import {
  slotElapsed,
  signupOptionIsBeforeToday,
  signupOptionIsToday,
  entryHasProductLink,
  isSpecificSignup,
  groupIsPast,
} from '~/SignUpSheets/utils/misc';
import {selectIsDateTimeBlueprint} from '~/SignUpSheets/reducers/blueprint/selectors';
import {sortTimesFn} from '~/SignUpSheets/hooks/useSortTimes';

const isDateTimeExpired = ({sheet}, {uuid: a} = {}) => {
  const sop = sheet.signup_options.find(({uuid: b} = {}) => a === b) || {};
  if (signupOptionIsBeforeToday(sop)) return true;
  if (signupOptionIsToday(sop) && !sop.all_day && sop.slots?.every(slotElapsed)) return true;
  return false;
};

export const selectSheetId = ({sheet}) => sheet.id;
export const selectBackground = ({sheet}) => sheet.background_image;
export const selectTitle = ({sheet}) => sheet.title;
export const selectUpdatedAt = ({sheet}) => sheet.updated_at;
export const selectStartDate = ({sheet}) => sheet.start;
export const selectEndDate = ({sheet}) => sheet.end;
export const selectLocation = ({sheet}) => sheet.location;
export const selectAddress = ({sheet}) => sheet.address;
export const selectIsOrganizer = ({sheet, user}) =>
  !!user.userId && sheet.organizer_id === user.userId;
export const selectOrganizerName = ({sheet}) => sheet.organizer_name;
export const selectVolunteerMessage = ({sheet}) => sheet.message;
export const selectOrganizerEmail = ({sheet}) => sheet.organizer_email;
export const selectOrganizerPhone = ({sheet}) => sheet.organizer_phone;
export const selectWishlistOption = ({sheet}) => sheet.wishlist_option;
export const selectWishlistName = ({sheet}) => sheet.wishlist_name;
export const selectWishlists = ({sheet}) => sheet.wishlists;
export const selectLocalWishlists = ({sheet}) => sheet.localWishlists;
export const selectIsPreviewMode = ({sheet}) => sheet.previewMode;
export const selectSheetStatus = ({sheet}) => sheet.status;
export const selectIsInEditMode = ({sheet}) => sheet.status === 'published';
export const selectSheetIsDraft = ({sheet}) => sheet.status === 'draft';
export const selectIsPristine = ({sheet}) => sheet.pristine;
export const selectSheetHasPreexistingEndDate = ({sheet}) => sheet.preexisting_end;
export const selectSignups = ({sheet}) => sheet.signups;
export const selectNumSignups = ({sheet}) => sheet.signups.length;
export const selectYourSignups =
  (userId) =>
  ({sheet}) =>
    !userId ? [] : sheet.signups.filter((s) => !!s.user_id && userId === s.user_id);
export const selectSignupsById =
  (id) =>
  ({sheet}) =>
    sheet.signups.filter((s) => isSpecificSignup(s, id));
export const selectSignupsByUuid =
  (uuid) =>
  ({sheet}) => {
    const signupOption = sheet.signup_options.find((o) => o.uuid === uuid);
    if (!signupOption) return [];
    if (!signupOption.slots) return sheet.signups.filter((s) => s.signup_option === uuid);
    const slotIds = signupOption.slots?.map((s) => s.slot_id);
    return sheet.signups.filter((i) => slotIds.includes(i.slot_id));
  };
export const selectSignupOptionGroupById =
  (groupId) =>
  ({sheet}) =>
    sheet.groups?.[groupId];
export const selectSignupOptionGroups = ({sheet}) => sheet.groups;
export const selectNumSignupOptionGroups = ({sheet}) => Object.keys(sheet.groups).length;
export const selectGroupingType = ({sheet}) => {
  const groups = Object.values(sheet.groups ?? {});
  if (groups.length > 0) return groups[0].group_type;
  return null;
};
export const selectExpired = ({sheet}, {uuid: a} = {}) => {
  if (sheet.status === 'draft') return false;
  switch (sheet.sheet_type) {
    case 'items': {
      if (moment(sheet.end ?? sheet.start).isBefore(Date.now(), 'day')) return true;
      const groups = selectSignupOptionGroups({sheet});

      // If no groups, this will loop zero time and we'll hit the base case after the loop
      for (const group of Object.values(groups)) {
        switch (group.group_type) {
          case 'time': {
            // If at least one group is not expired, return false
            if (!moment(group.end_time).isBefore(Date.now())) return false;
            break;
          }
          default: {
            break;
          }
        }
      }
      return moment(sheet.end ?? sheet.start).isBefore(Date.now());
    }
    case 'date-slots': {
      const now = moment();
      if (!a) {
        // Not checking for a specific slot, but for all in general
        return sheet.signup_options.every((sop) => moment(sop.date).isBefore(now, 'day'));
      }

      const sop = sheet.signup_options.find(({uuid: b} = {}) => a === b) || {};
      return moment(sop.date).isBefore(now, 'day');
    }
    case 'datetime-slots':
    case 'datetime-slots-with-quantity': {
      if (!a) {
        return sheet.signup_options.every((so) => isDateTimeExpired({sheet}, {uuid: so.uuid}));
      }
      return isDateTimeExpired({sheet}, {uuid: a});
    }
    default:
      return false;
  }
};
export const selectIsSheetExpiredOrCanceled = ({sheet}, {uuid: a} = {}) =>
  selectExpired({sheet}, {uuid: a}) || selectSheetStatus({sheet}) === 'canceled';
export const selectSignupOptionIsPast =
  (sop) =>
  ({sheet}) =>
    selectIsSheetExpiredOrCanceled({sheet}) ||
    signupOptionIsBeforeToday(sop) ||
    (signupOptionIsToday(sop) && !sop.all_day && sop.slots?.every?.(slotElapsed));

export const selectSignupOptionIsFull =
  (sop) =>
  ({sheet}) => {
    if (!sop) return false;
    const totalFilledSlots = selectSignupsByUuid(sop.uuid)({sheet}).reduce(
      (acc, curr) => acc + (curr.quantity ?? 1),
      0
    );
    if (sop.slots) {
      const totalPossibleSlots = sop.slots.reduce((acc, curr) => acc + (curr.quantity ?? 1), 0);
      return totalFilledSlots === totalPossibleSlots;
    }
    return totalFilledSlots === (sop.quantity ?? 1);
  };
export const selectSignupOptions = ({sheet}) => sheet.signup_options;
export const selectSortedSignupOptions =
  ({userId}) =>
  ({sheet, blueprint}) => {
    const isDatetimeType = selectIsDateTimeBlueprint({blueprint});
    return sheet.signup_options.toSorted((a, b) => {
      if (userId) {
        const yourSignups = selectYourSignups(userId)({sheet});
        const aHasYourSignups = yourSignups.some((s) => s.signup_option === a.uuid);
        const bHasYourSignups = yourSignups.some((s) => s.signup_option === b.uuid);
        if (aHasYourSignups && !bHasYourSignups) return -1;
        if (bHasYourSignups && !aHasYourSignups) return 1;
      }
      if (!a.date || !b.date) {
        const aFull = selectSignupOptionIsFull(a)({sheet});
        const bFull = selectSignupOptionIsFull(b)({sheet});
        if (aFull) return 1;
        if (bFull) return -1;
        return 0;
      }
      if (signupOptionIsBeforeToday(a)) return 1;
      if (signupOptionIsBeforeToday(b)) return -1;

      // The signup options is for today or the future
      if (isDatetimeType) {
        const isSameDay = moment(a.date).isSame(moment(b.date), 'day');
        if (isSameDay) return 0;
        if (signupOptionIsToday(a) && a.slots.every(slotElapsed)) return 1;
        if (signupOptionIsToday(b) && b.slots.every(slotElapsed)) return -1;
      }
      if (a.position_index && b.position_index) return a.position_index - b.position_index;
      return 0;
    });
  };
export const selectSignupOptionsByGroup =
  ({groupId, userId, sort} = {sort: false}) =>
  ({sheet}) => {
    const optionsInGroup = sheet.signup_options.filter((so) =>
      groupId ? so.group === groupId : true
    );
    if (groupId && sort) {
      optionsInGroup.sort((a, b) => {
        const yourSignups = selectYourSignups(userId)({sheet});
        const aHasYourSignups = yourSignups.some((s) => s.signup_option === a.uuid);
        const bHasYourSignups = yourSignups.some((s) => s.signup_option === b.uuid);
        if (aHasYourSignups && !bHasYourSignups) return -1;
        if (bHasYourSignups && !aHasYourSignups) return 1;
        if (selectSignupOptionIsFull(a)({sheet})) return 1;
        if (selectSignupOptionIsFull(b)({sheet})) return -1;
        return 0;
      });
    }
    return optionsInGroup;
  };
export const selectSignupOptionUuids =
  (groupId) =>
  ({sheet}) =>
    selectSignupOptionsByGroup({groupId})({sheet}).map((so) => so.uuid);
export const selectNumSignupOptions = ({sheet}) => sheet.signup_options.length;
export const selectNumSignupSlots = ({sheet}) =>
  sheet.signup_options.flatMap((so) => so.slots ?? []).length;
export const selectSignupOptionById =
  (uuid) =>
  ({sheet}) =>
    sheet.signup_options.find((so) => so.uuid === uuid);
export const selectIsFirstSignupOptionInGroup =
  ({uuid, groupId}) =>
  ({sheet}) => {
    const signupOptionsInGroup = selectSignupOptionsByGroup({groupId})({sheet});
    const index = signupOptionsInGroup.findIndex((so) => so.uuid === uuid);
    return index === 0;
  };
export const selectOpenGroups = ({sheet}) => sheet.open_groups;
export const selectIsGroupOpen =
  (id) =>
  ({sheet}) =>
    sheet.open_groups.has(id);
export const selectIsLastSignupOptionInGroup =
  ({uuid, groupId}) =>
  ({sheet}) => {
    const signupOptionsInGroup = selectSignupOptionsByGroup({groupId})({sheet});
    const index = signupOptionsInGroup.findIndex((so) => so.uuid === uuid);
    return index === signupOptionsInGroup.length - 1;
  };
export const selectSignupOptionByIndex =
  (index) =>
  ({sheet}) =>
    sheet.signup_options[index];
export const selectHasUnsavedWishlists = ({sheet}) => sheet.has_unsaved_wishlists;
export const selectErrors = ({sheet}) => sheet.errors;
export const selectShowValidationModal = ({sheet}) => sheet.show_validation_modal;
export const selectSheetTimezone = ({sheet}) => sheet.timezone;

export const selectSignupsBySlotId =
  (slotId) =>
  ({sheet}) =>
    sheet.signups.filter((s) => s.slot_id === slotId);
export const selectIsLastSignupOption =
  (index) =>
  ({sheet}) =>
    index === sheet.signup_options.length - 1;
export const selectIsOnlySignupOption = ({sheet}) => sheet.signup_options.length <= 1;
export const selectSelectedSignups = ({sheet}) => sheet.selected_signups;

export const selectGateUnlockedForSheet = ({sheet}) => sheet.show_notify_volunteers_modal;

export const selectSignupsPendingDeletion = ({sheet}) => sheet.signups_pending_deletion;

export const selectGroupIsFull =
  (groupId = null) =>
  ({sheet}) => {
    const signupOptions = selectSignupOptionsByGroup({groupId})({sheet});
    const signups = sheet.signups.filter((s) =>
      signupOptions.some((so) => so.uuid === s.signup_option)
    );
    const totalSlots = signupOptions.reduce((acc, so) => {
      if (so.quantity) return acc + so.quantity;
      return acc + so.slots.reduce((a, slot) => a + (slot.quantity ?? 1), 0);
    }, 0);
    return signups.length === totalSlots;
  };

export const selectGroupIsPast =
  (groupId = null) =>
  ({sheet}) => {
    if (selectIsSheetExpiredOrCanceled({sheet})) return true;
    if (!groupId) return false;
    const group = selectSignupOptionGroupById(groupId)({sheet});
    return moment(sheet.start).isSame(moment(), 'day') && groupIsPast(group);
  };

export const selectGroupIsPastOrFull =
  (groupId = null) =>
  ({sheet}) => {
    if (!groupId) return false;
    const past = selectGroupIsPast(groupId)({sheet});
    const full = selectGroupIsFull(groupId)({sheet});
    return past || full;
  };

export const selectSignupOptionsAreGrouped = ({sheet}) =>
  Object.keys(sheet.groups ?? {}).length > 0;
export const selectSignupOptionGroupIds =
  ({sortDownPastOrFullGroups, userId} = {sortDownPastOrFullGroups: false}) =>
  ({sheet}) => {
    const groups = Object.entries(sheet.groups ?? {});
    if (groups.length === 0) return groups.map((g) => g[0]);

    const groupType = groups[0][1].group_type;
    groups.sort((a, b) => {
      const yourSignups = selectYourSignups(userId)({sheet});
      const groupAOptions = selectSignupOptionsByGroup({groupId: a[0]})({sheet});
      const groupBOptions = selectSignupOptionsByGroup({groupId: b[0]})({sheet});
      const aHasYourSignups = groupAOptions.some((so) =>
        yourSignups.filter((s) => s.signup_option === so.uuid)
      );
      const bHasYourSignups = groupBOptions.some((so) =>
        yourSignups.filter((s) => s.signup_option === so.uuid)
      );
      if (aHasYourSignups && !bHasYourSignups) return -1;
      if (bHasYourSignups && !aHasYourSignups) return 1;
      switch (groupType) {
        case 'time': {
          if (sortDownPastOrFullGroups) {
            const aPastOrFull = selectGroupIsPastOrFull(a[0])({sheet});
            const bPastOrFull = selectGroupIsPastOrFull(b[0])({sheet});

            if (aPastOrFull && !bPastOrFull) return 1;
            if (bPastOrFull && !aPastOrFull) return -1;
          }
          return sortTimesFn(a[1], b[1]);
        }
        default: {
          return 0;
        }
      }
    });

    return groups.map((g) => g[0]);
  };

export const selectSheetHasProducts = ({sheet}) =>
  sheet.signup_options.some(
    (so) => entryHasProductLink(so) || so.slots?.some((slot) => entryHasProductLink(slot))
  );

export const selectProductLinks = ({sheet, blueprint}) => {
  const links = {};
  const isDatetimeType = selectIsDateTimeBlueprint({blueprint});
  for (const so of sheet.signup_options) {
    if (entryHasProductLink(so)) links[so.uuid] = so.product_link.url;
    else if (isDatetimeType) {
      for (const slot of so.slots ?? []) {
        if (entryHasProductLink(slot)) links[slot.slot_id] = slot.product_link.url;
      }
    }
  }
  return links;
};

export const selectThemeId = ({sheet}) => sheet.theme_id;
export const selectActiveTheme = ({sheet: s, blueprint: bp}, {theme_id: themeId}) =>
  (s.theme_id || bp.theme_id) === themeId;

export const selectSheetIsPublished = (s) => selectIsInEditMode(s);
export const selectShowEllipses = (s) => !selectExpired(s);
export const selectCalendarLinks = ({sheet}) => sheet.calendar_links ?? {};
export const selectShortLink = ({sheet}) => {
  if (!sheet.short_link) return null;
  const url = new URL(sheet.short_link, sheet.shortlink_host);
  return url.toString();
};
export const selectSheetType = ({sheet}) => sheet?.sheet_type;

// Multiple Dates Modal
const selectMultipleDatesModalData = ({sheet}) => sheet?.multipleDatesModal;

export const selectIsMultipleDatesModalOpen = (state) => selectMultipleDatesModalData(state)?.open;

export const selectMultipleDatesModalTab = (state) => selectMultipleDatesModalData(state)?.tab;

export const selectMultipleDatesModalDates = (state) => selectMultipleDatesModalData(state)?.dates;

export const selectMultipleDatesModalStartDate = (state) =>
  selectMultipleDatesModalData(state)?.startDate;

export const selectMultipleDatesModalEndDate = (state) =>
  selectMultipleDatesModalData(state)?.endDate;

export const selectMultipleDatesModalFrequency = (state) =>
  selectMultipleDatesModalData(state)?.frequency;

export const selectMultipleDatesModalDays = (state) => selectMultipleDatesModalData(state)?.days;

export const selectMultipleDatesModalWeeklyScheduleSpecifier = (state) =>
  selectMultipleDatesModalData(state)?.weeklyScheduleSpecifier;

export const selectMultipleDatesModalMonthlyScheduleSpecifier = (state) =>
  selectMultipleDatesModalData(state)?.monthlyScheduleSpecifier;

export const selectMultipleDatesModalMonthlyScheduleSpecifierOrdinal = (state) =>
  selectMultipleDatesModalMonthlyScheduleSpecifier(state)?.ordinal;

export const selectMultipleDatesModalMonthlyScheduleSpecifierDay = (state) =>
  selectMultipleDatesModalMonthlyScheduleSpecifier(state)?.day;

// Add Multiple slots modal
export const selectMultipleSlotsModalStartTime = ({sheet}) => sheet.multipleSlotsModal.startTime;
export const selectMultipleSlotsModalEndTime = ({sheet}) => sheet.multipleSlotsModal.endTime;
export const selectMultipleSlotsModalTimeIncrementValue = ({sheet}) =>
  sheet.multipleSlotsModal.timeIncrementValue;
export const selectMultipleSlotsModalIntervalUnit = ({sheet}) =>
  sheet.multipleSlotsModal.intervalUnit;
export const selectMultipleSlotsModalSignupEachSlot = ({sheet}) =>
  sheet.multipleSlotsModal.signupEachSlot;
export const selectMultipleSlotsModalIntervalCount = ({sheet}) =>
  sheet.multipleSlotsModal.intervalCount;
export const selectMultipleSlotsModalSlots = ({sheet}) => sheet.multipleSlotsModal.slots;
