import React, {useCallback} from 'react';
import moment from 'moment-timezone';
import {useDispatch, useSelector} from 'react-redux';
import {DatePicker} from '~/common/_pb_components/atoms/DatePicker';
import {
  selectMultipleDatesModalEndDate,
  selectMultipleDatesModalStartDate,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB} from '~/SignUpSheets/reducers/sheet/constants';
import {PB_CalendarLine as CalendarIcon} from '~/common/svg/PB_CalendarLine';

import './DatesSelector.sass';

export const DatesSelector = () => {
  const dispatch = useDispatch();
  const startDate = useSelector(selectMultipleDatesModalStartDate);
  const endDate = useSelector(selectMultipleDatesModalEndDate);

  const onChangeStartDateHandler = useCallback(([date]) => {
    const d = moment(date);
    dispatch({
      type: UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB,
      payload: {startDate: d.toISOString()},
    });
  }, []);

  const onChangeEndDateHandler = useCallback(([date]) => {
    const d = moment(date);
    dispatch({
      type: UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB,
      payload: {endDate: d.toISOString()},
    });
  }, []);

  return (
    <div className="add-multiple-dates__dates_selector__form-group">
      <DatePicker
        label="START DATE"
        id="start-date"
        name="date"
        data-qa-id="start-date"
        type="text"
        value={moment(startDate).format('YYYY-MM-DD')}
        minDate={moment().format('YYYY-MM-DD')}
        placeholder="empty"
        onChange={onChangeStartDateHandler}
        endAdornment={<CalendarIcon ratio={0.75} />}
        containerClassName="add-multiple-dates__dates_selector"
      />

      <DatePicker
        label="END DATE"
        id="end-date"
        name="date"
        data-qa-id="end-date"
        type="text"
        value={endDate ? moment(endDate).format('YYYY-MM-DD') : null}
        minDate={moment(startDate).format('YYYY-MM-DD')}
        placeholder="empty"
        onChange={onChangeEndDateHandler}
        endAdornment={<CalendarIcon ratio={0.75} />}
        containerClassName="add-multiple-dates__dates_selector"
      />
    </div>
  );
};
