import PropTypes from 'prop-types';
import cx from 'classnames';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Spinner} from '~/common/_pb_components/atoms/Spinner';

import './NavBottom.sass';

export const NavBottom = ({
  show,
  // primary props
  onClickPrimary,
  buttonTextPrimary,
  buttonVariantPrimary = 'primary',
  dataQaIdPrimary = 'nav-bottom-cta-primary',
  disabledPrimary = false,
  showPrimary = true,
  fullWidthPrimary = true,
  classNamePrimary = '',
  disableProcessingPrimary = false,
  isLoadingPrimary = false,
  isSuccessPrimary = false,
  isErrorPrimary = false,
  iconPrimary,
  // secondary props
  onClickSecondary,
  buttonTextSecondary,
  dataQaIdSecondary = 'nav-bottom-cta-secondary',
  disabledSecondary = false,
  showSecondary = false,
  fullWidthSecondary = false,
  classNameSecondary = '',
  disableProcessingSecondary = false,
  isLoadingSecondary = false,
  isSuccessSecondary = false,
  isErrorSecondary = false,
}) => (
  <div
    className={cx('signup-sheet__bottom-cta', {
      'signup-sheet__bottom-cta--open': show,
    })}
  >
    {showSecondary && (
      <Button
        variant="secondary"
        onClick={onClickSecondary}
        data-qa-id={dataQaIdSecondary}
        size="large"
        fullWidth={fullWidthSecondary}
        disabled={disabledSecondary}
        className={classNameSecondary}
        disableProcessing={disableProcessingSecondary}
      >
        {buttonTextSecondary}
        <Spinner
          isLoading={isLoadingSecondary}
          isSuccess={isSuccessSecondary}
          isError={isErrorSecondary}
        />
      </Button>
    )}

    {showPrimary && (
      <Button
        variant={buttonVariantPrimary}
        onClick={onClickPrimary}
        data-qa-id={dataQaIdPrimary}
        size="large"
        icon={iconPrimary}
        fullWidth={fullWidthPrimary}
        disabled={disabledPrimary}
        className={classNamePrimary}
        disableProcessing={disableProcessingPrimary}
      >
        {buttonTextPrimary}
        <Spinner
          isLoading={isLoadingPrimary}
          isSuccess={isSuccessPrimary}
          isError={isErrorPrimary}
        />
      </Button>
    )}
  </div>
);

NavBottom.propTypes = {
  show: PropTypes.bool.isRequired,

  onClickPrimary: PropTypes.func.isRequired,
  buttonTextPrimary: PropTypes.string.isRequired,
  buttonVariantPrimary: PropTypes.oneOf(['primary', 'secondary', 'brand-primary']),
  dataQaIdPrimary: PropTypes.string,
  classNamePrimary: PropTypes.string,
  disableProcessingPrimary: PropTypes.bool,
  isLoadingPrimary: PropTypes.bool,
  isSuccessPrimary: PropTypes.bool,
  isErrorPrimary: PropTypes.bool,
  fullWidthPrimary: PropTypes.bool,
  iconPrimary: PropTypes.element,

  onClickSecondary: PropTypes.func,
  buttonTextSecondary: PropTypes.string,
  dataQaIdSecondary: PropTypes.string,
  showPrimary: PropTypes.bool,
  showSecondary: PropTypes.bool,
  classNameSecondary: PropTypes.string,
  disableProcessingSecondary: PropTypes.bool,
  isLoadingSecondary: PropTypes.bool,
  isSuccessSecondary: PropTypes.bool,
  isErrorSecondary: PropTypes.bool,
  fullWidthSecondary: PropTypes.bool,
};
