/* eslint-disable max-lines-per-function */
import cx from 'classnames';
import {useState, useCallback, useMemo, useContext} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {ResponsivePanel} from '~/common/_pb_components/organisms/ResponsivePanel';
import {useIsLargeAndUp, useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {PB_ClickOutLine as ClickOutLineIcon} from '~/common/svg/PB_ClickOutLine';
import {PB_CloseLine as CloseLineIcon} from '~/common/svg/PB_CloseLine';
import {PB_Document as DocumentIcon} from '~/common/svg/PB_Document';
import {PB_EditDesignLine as EditDesignLineIcon} from '~/common/svg/PB_EditDesignLine';
import {PB_LinkIcon as LinkIcon} from '~/common/svg/PB_LinkIcon';
import {PB_SettingsFill as SettingsFillIcon} from '~/common/svg/PB_SettingsFill';
import {PB_Chevron as ChevronIcon} from '~/common/svg/PB_Chevron';
import {PB_Restart as RestartIcon} from '~/common/svg/PB_Restart';
import {PB_CalendarAdd as CalendarAddIcon} from '~/common/svg/PB_CalendarAdd';
import {Menu} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/SlotToolBar/mobile/Menu';
import {MenuHeader} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/SlotToolBar/mobile/MenuHeader';
import {MenuItem} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/SlotToolBar/mobile/MenuItem';
import {MoreIcon} from '~/common/svg/MoreIcon';
import {Link} from '~/common/_pb_components/atoms/Link';
import {
  selectNumSignups,
  selectSheetId,
  selectSignups,
  selectTitle,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {
  selectBlueprintType,
  selectFieldSchema,
  selectIsDateTimeBlueprint,
} from '~/SignUpSheets/reducers/blueprint/selectors';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {ResponsiveModal} from '~/common/_pb_components/organisms/ResponsiveModal';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {AdvancedOptionsModal} from '~/SignUpSheets/Create/routes/Customize/components/AdvancedOptions/modal/AdvancedOptionsModal';
import {ConfirmDeleteSlotsModal} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/ConfirmDeleteSlotsModal';
import {getLoggedUserId} from '~/common/fabric/User/reducers/user';
import {schemaToJson} from '~/SignUpSheets/utils/schema';
import {SCOPE_HOST} from '~/SignUpSheets/reducers/sheet/constants';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {emailSheetVolunteers} from '~/SignUpSheets/utils/misc';
import {useLazyExportSheetToCSVQuery} from '~/SignUpSheets/api/sheet';
import {shouldPrintInline} from '~/SignUpSheets/utils/print';

import {feedbackError, charcoal} from '~sass/pb_styleguide/base/_exports.sass';
import './SheetOptions.sass';

export const SheetOptions = ({
  trackChangeUpstream,
  onCopyShareableLink,
  onGetPrintableSheet,
  buttonIconColor = charcoal,
  buttonTextColor = 'charcoal',
  forceShowButtonText = false,
  title = 'SignUp Sheet Options',
}) => {
  const {flow} = useContext(SignUpSheetContext);
  const {updateSheetDetails} = useUpdateSheet();
  const lgScreens = useIsLargeAndUp();
  const isDesktop = useIsLargishAndUp();
  const sheetId = useSelector(selectSheetId);
  const signups = useSelector(selectSignups, shallowEqual);
  const sheetTitle = useSelector(selectTitle);
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const {cancelSheet, editSheet} = useSheetActions();
  const fieldSchema = useSelector(selectFieldSchema, shallowEqual);
  const blueprintType = useSelector(selectBlueprintType);
  const numSignups = useSelector(selectNumSignups);
  const isLoggedIn = useSelector(getLoggedUserId);
  const [exportSheetToCSV] = useLazyExportSheetToCSVQuery();

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showAdvancedOptionsModal, setShowAdvancedOptionsModal] = useState(false);
  const [showDeleteSlotsModal, setShowDeleteSlotsModal] = useState(false);

  const toggleOptions = useCallback(() => {
    setOptionsOpen((o) => !o);
  }, []);

  const onConfirmCancel = useCallback(() => {
    cancelSheet(sheetId);
    setShowConfirmCancelModal(false);
    setOptionsOpen(false);
  }, [sheetId]);

  const onCancelSheet = useCallback(() => {
    setShowConfirmCancelModal(true);
  }, []);

  const handleCloseConfirmCancelModal = useCallback(() => {
    setShowConfirmCancelModal(false);
  }, []);

  const toggleAdvancedOptions = useCallback(() => {
    if (showAdvancedOptionsModal) setOptionsOpen(false);
    setShowAdvancedOptionsModal((prev) => !prev);
  }, [showAdvancedOptionsModal]);

  const toggleDeleteSlotsModal = useCallback(() => setShowDeleteSlotsModal((prev) => !prev), []);

  const onEmailVolunteers = useCallback(() => {
    emailSheetVolunteers({signups, title: sheetTitle});
  }, [signups, sheetTitle]);

  const onDeleteSlots = useCallback(() => {
    window.dataLayer.push({event: 'delete-all-clicked-start-over-modal'});
    const newSignupOptionsList = [schemaToJson(fieldSchema, blueprintType, SCOPE_HOST)];
    updateSheetDetails({payload: {signup_options: newSignupOptionsList, signups: []}});
    toggleDeleteSlotsModal();
    setOptionsOpen(false);
    window.dataLayer.push({event: 'success-slots-deleted-start-over-modal'});
  }, [fieldSchema, blueprintType, updateSheetDetails, toggleDeleteSlotsModal]);

  const onExportSheetToCSV = useCallback(() => {
    if (!sheetId) return;
    exportSheetToCSV(sheetId);
  }, [sheetId, exportSheetToCSV]);

  const options = useMemo(() => {
    if (['create', 'edit'].includes(flow)) {
      return {
        'advanced-slot-options': {
          label: 'Advanced Slot Options',
          options: [
            {
              id: 'create-multiple-days-times',
              element: lgScreens ? (
                <div className="sheet-options__advanced-options">
                  <div className="sheet-options__advanced-options__header">
                    <Typography variant="list2" semibold>
                      Create multiple days and times
                    </Typography>
                    <Typography variant="paragraph3" color="pebble">
                      Perfect for creating signups over many days.
                    </Typography>
                  </div>
                  <Button
                    variant="secondary"
                    size="large"
                    fullWidth
                    data-qa-id="open-advanced-options"
                    onClick={toggleAdvancedOptions}
                  >
                    Create slots
                    <ChevronIcon ratio={0.5} direction="right" />
                  </Button>
                </div>
              ) : null,
              label: lgScreens ? null : 'Create multiple days and times',
              icon: <CalendarAddIcon ratio={0.75} />,
              onClick: toggleAdvancedOptions,
              visible: isDatetimeType,
              mw_order: 1,
            },
          ],
        },
        'delete-slots': {
          label: 'Delete Slots',
          options: [
            {
              id: 'clear-slots',
              element: lgScreens ? (
                <div className="sheet-options__advanced-options">
                  <Typography variant="list2" semibold>
                    Start over by deleting slots
                  </Typography>
                  <Button
                    variant="secondary"
                    size="large"
                    fullWidth
                    data-qa-id="delete-slots-button"
                    onClick={toggleDeleteSlotsModal}
                  >
                    Delete slots
                  </Button>
                </div>
              ) : null,
              label: lgScreens ? null : 'Delete slots',
              icon: <RestartIcon color={charcoal} />,
              onClick: toggleDeleteSlotsModal,
              visible: numSignups === 0,
              mw_order: 2,
            },
          ],
        },
      };
    }
    if (!sheetId) return {};
    if (flow === 'view') {
      return {
        'volunteer-management': {
          label: 'Volunteer Management',
          options: [
            {
              id: 'copy-shareable-link',
              label: 'Copy shareable link',
              visible: !isDesktop,
              mw_order: 1,
              icon: <LinkIcon ratio={0.75} />,
              onClick: onCopyShareableLink,
            },
            {
              id: 'email-volunteers',
              label: 'Email volunteers',
              visible: true,
              mw_order: 5,
              icon: <ClickOutLineIcon ratio={0.5} />,
              onClick: onEmailVolunteers,
            },
          ],
        },
        'sheet-management': {
          label: 'Sheet Management',
          options: [
            {
              id: 'get-printable-sheet',
              label: 'Get printable sheet',
              visible: true,
              mw_order: 3,
              icon: <DocumentIcon ratio={0.75} />,
              href: shouldPrintInline ? null : `/signup-sheet/${sheetId}/print`,
              onClick: shouldPrintInline ? onGetPrintableSheet : null,
            },
            {
              id: 'export-csv',
              label: 'Export CSV',
              visible: true,
              mw_order: 4,
              icon: <DocumentIcon ratio={0.75} />,
              onClick: onExportSheetToCSV,
            },
            {
              id: 'edit-sheet',
              label: 'Edit sheet',
              visible: true,
              mw_order: 2,
              icon: <EditDesignLineIcon ratio={0.5} />,
              onClick: editSheet,
            },
            {
              id: 'cancel-sheet',
              label: 'Cancel sheet',
              visible: true,
              mw_order: 6,
              icon: (
                <CloseLineIcon
                  ratio={0.75}
                  color={feedbackError}
                  className="sheet-options__cancel-sheet-icon"
                />
              ),
              onClick: onCancelSheet,
              danger: true,
            },
          ],
        },
      };
    }
    return {};
  }, [
    toggleAdvancedOptions,
    onCopyShareableLink,
    onEmailVolunteers,
    editSheet,
    onCancelSheet,
    isDesktop,
    sheetId,
    flow,
    isDatetimeType,
    numSignups,
    lgScreens,
  ]);

  const header = useMemo(
    () => <MenuHeader toggleMenu={toggleOptions} title={title} />,
    [toggleOptions, title]
  );

  const onActionHandler = useCallback(
    (action) => () => {
      action();
      toggleOptions();
    },
    [toggleOptions]
  );

  const mobileItems = useMemo(
    () =>
      Object.values(options)
        .map((o) => o.options)
        .flat()
        .filter((o) => o.visible)
        .sort((a, b) => a.mw_order - b.mw_order)
        .map((o, index) => (
          <MenuItem
            key={o.mw_order}
            action={o.onClick ? onActionHandler(o.onClick) : null}
            href={o.href}
            danger={o.danger}
            dataQaId={`sheet-option-${o.id}`}
            index={index}
          >
            <div className="sheet-options__icon">{o.icon}</div>
            <Typography variant="paragraph2" color={o.danger ? 'feedbackError' : 'charcoal'}>
              {o.label}
            </Typography>
          </MenuItem>
        )),
    [options, onActionHandler]
  );

  const SheetOptionsMenu = useMemo(() => {
    if (!lgScreens) {
      return (
        <>
          {optionsOpen && (
            <button
              className="unstyled-button mobile-menu__overlay"
              onClick={toggleOptions}
              type="button"
              aria-label="Close advanced options menu"
            />
          )}
          <Menu
            header={header}
            items={mobileItems}
            menuVisible={optionsOpen}
            toggleMenu={toggleOptions}
          />
        </>
      );
    }
    return (
      <ResponsivePanel
        isOpen={optionsOpen}
        onClose={toggleOptions}
        title={title}
        modalId="signup-sheet-options"
        closeQaId="close-signup-sheet-options"
        showCover
      >
        <div className="sheet-options__desktop">
          {Object.entries(options)
            .filter(([, v]) => v.options.some((o) => o.visible))
            .map(([key, group]) => (
              <div className="sheet-options__desktop__option-group" key={key}>
                <Typography variant="paragraph2" semibold>
                  {group.label}
                </Typography>
                {group.options
                  .filter((o) => o.visible)
                  .map((option) => {
                    if (option.element) {
                      return <div key={option.id}>{option.element}</div>;
                    }
                    if (option.href) {
                      return (
                        <Link
                          key={option.id}
                          data-qa-id={option.label.toLowerCase().replaceAll(' ', '-')}
                          as="secondary"
                          className={cx('sheet-options__button', {
                            'sheet-options__danger-button': option.danger,
                          })}
                          variant="large"
                          fullWidth
                          href={option.href}
                          target="_blank"
                        >
                          {option.icon}
                          {option.label}
                        </Link>
                      );
                    }
                    return (
                      <Button
                        key={option.id}
                        data-qa-id={option.label.toLowerCase().replaceAll(' ', '-')}
                        variant="secondary"
                        className={cx('sheet-options__button', {
                          'sheet-options__danger-button': option.danger,
                        })}
                        icon={option.icon}
                        disableProcessing
                        onClick={option.onClick}
                        size="large"
                        fullWidth
                      >
                        {option.label}
                      </Button>
                    );
                  })}
              </div>
            ))}
        </div>
      </ResponsivePanel>
    );
  }, [optionsOpen, toggleOptions, header, mobileItems, options, lgScreens]);

  const ButtonText = useMemo(() => {
    if (!isDesktop && !forceShowButtonText) return null;
    if (flow === 'view') return 'More';
    if (['create', 'edit'].includes(flow)) return 'Settings';
    return null;
  }, [isDesktop, forceShowButtonText, flow]);

  return (
    <>
      <Button
        variant="transparent"
        className="signup-sheet__header__more-menu"
        icon={
          isDesktop || forceShowButtonText ? (
            <SettingsFillIcon ratio={0.5} color={buttonIconColor} />
          ) : (
            <MoreIcon color={buttonIconColor} />
          )
        }
        onClick={toggleOptions}
        aria-label="Options"
        disabled={!isLoggedIn}
        data-qa-id="sheet-options-trigger"
      >
        {ButtonText ? (
          <Typography variant="paragraph2" color={buttonTextColor}>
            {ButtonText}
          </Typography>
        ) : null}
      </Button>
      {SheetOptionsMenu}
      {showConfirmCancelModal ? (
        <ResponsiveModal
          isOpen
          title="Cancel sheet"
          body="Your SignUp Sheet will be canceled and volunteers will be notified via email."
          primaryText="Cancel sheet"
          secondaryText="Nevermind"
          primaryAction={onConfirmCancel}
          onClose={handleCloseConfirmCancelModal}
        />
      ) : null}
      {showAdvancedOptionsModal ? (
        <AdvancedOptionsModal
          onClose={toggleAdvancedOptions}
          trackChangeUpstream={trackChangeUpstream}
        />
      ) : null}
      {showDeleteSlotsModal ? (
        <ConfirmDeleteSlotsModal onClose={toggleDeleteSlotsModal} onSubmit={onDeleteSlots} />
      ) : null}
    </>
  );
};
