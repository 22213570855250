import moment from 'moment-timezone';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {getTimezoneAbbreviation} from '~/SignUpSheets/components/TimezoneSelect/TimezoneSelect';
import {formatDateForPrint, formatDateShort} from '~/SignUpSheets/utils/misc';

const userTimezone = moment.tz.guess();

const isSameDay = (date1, date2, timezone) =>
  moment.tz(date1, timezone).isSame(moment.tz(date2, timezone), 'day');

const dateTimeFormat = (
  date,
  timezone,
  showTimes,
  abbr = '',
  includeTzInDate = false,
  forPrint = false
) => {
  if (forPrint) return formatDateForPrint(moment.tz(date, timezone));
  const format = showTimes ? 'llll' : 'ddd, ll';
  return `${moment.tz(date, timezone).format(format)} ${includeTzInDate ? abbr : ''}`.trim();
};

export const formatDate = (
  start,
  end,
  timezone,
  showTimes,
  includeTzInDate = false,
  forPrint = false
) => {
  if (!start) return {date: null, abbr: null, timeRange: null};

  const dateFormatter = forPrint ? formatDateForPrint : formatDateShort;
  const abbr = timezone && timezone !== userTimezone ? getTimezoneAbbreviation(timezone) : '';
  const startFormatted = dateTimeFormat(start, timezone, showTimes, undefined, false, forPrint);

  const abbrElem = abbr ? (
    <Typography className="paragraph1" color="pebble" as="span">
      {abbr}
    </Typography>
  ) : null;

  if (!end) return {date: startFormatted, abbr: abbrElem, timeRange: null};

  const sameDay = isSameDay(start, end, timezone);

  if (!end || sameDay) {
    const dateLine = dateFormatter(moment.tz(start, timezone));
    const startTime = moment.tz(start, timezone).format('LT');
    const endTime = moment.tz(end, timezone).format('LT');

    const timeLine = showTimes ? (
      <Typography variant="paragraph1" color="pebble">
        {`${startTime}${endTime ? ` - ${endTime}` : ''} ${abbr || ''}`.trim()}
      </Typography>
    ) : null;

    return {
      date: dateLine,
      abbr: timeLine ? null : abbrElem,
      timeRange: timeLine,
    };
  }

  const endFormatted = dateTimeFormat(end, timezone, showTimes, abbr, includeTzInDate, forPrint);
  return {
    date: (
      <>
        {startFormatted} -<br />
        {endFormatted}
      </>
    ),
    abbr: abbrElem,
    timeRange: null,
  };
};
