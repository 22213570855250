import {
  coin,
  fog,
  formBorder,
  formInputBg,
  feedbackError,
  charcoal,
  placeholder as placeholderColor,
  pebble,
  cottonCandy,
} from '~sass/pb_styleguide/base/_exports.sass';

export const getStyles = ({error, hideSelectArrow, activeOption, borderColor = formBorder}) => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
    height: 48,
  }),
  control: (provided, {isDisabled}) => ({
    ...provided,
    color: isDisabled ? coin : charcoal,
    height: '100%',
    fontFamily: 'Brut',
    fontWeight: 400,
    borderColor: error ? feedbackError : borderColor,
    borderWidth: error ? 2 : 'thin',
    fontSize: '1rem',
    lineHeight: '1rem',
    boxShadow: undefined,
    backgroundColor: isDisabled ? fog : formInputBg,
    cursor: 'pointer',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: placeholderColor,
    margin: 0,
  }),
  menu: (provided) => ({...provided, minWidth: 'max-content', zIndex: 2}),
  indicatorSeparator: () => ({}),
  clearIndicator: () => ({display: 'none'}),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: hideSelectArrow ? 'none' : 'block',
  }),
  option: (provided, state) => ({
    // ...provided,
    fontFamily: 'Brut',
    fontWeight: 400,
    padding: '0.75rem',
    cursor: 'pointer',
    color: activeOption ? pebble : charcoal,
    backgroundColor: state.isFocused || state.isSelected ? cottonCandy : null,
  }),
});
