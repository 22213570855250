import {useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {TimePicker} from '~/common/_pb_components/molecules/TimePicker/TimePicker';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {standardizeTimeStr} from '~/SignUpSheets/utils/misc';
import {useScrollSlotIntoView} from '~/SignUpSheets/hooks/useScrollSlotIntoView';

import {borderColor} from '~sass/pb_styleguide/base/_exports.sass';
import './TimesField.sass';

export const TimesField = ({
  id,
  dataQaId,
  startTime = null,
  endTime = null,
  defaultStart = null,
  defaultEnd = null,
  onChangeStartTime,
  onChangeEndTime,
  startError = null,
  endError = null,
  textVariant = 'list2',
  textColor,
  disabled = false,
}) => {
  const ref = useRef(null);
  const [trigger] = useScrollSlotIntoView({ref});
  const isLargishAndUp = useIsLargishAndUp();

  const onChangeStart = useCallback(
    ({value: time}) => {
      onChangeStartTime(standardizeTimeStr(time) || startTime);
    },
    [onChangeStartTime, startTime]
  );

  const onChangeEnd = useCallback(
    ({value: time}) => {
      onChangeEndTime(standardizeTimeStr(time) || endTime);
    },
    [onChangeEndTime, endTime]
  );

  if (disabled) {
    return (
      <Typography
        variant={textVariant}
        size="large"
        semibold
        data-qa-id={`${dataQaId}-start-and-end-time`}
        color={textColor}
      >
        {startTime} - {endTime}
      </Typography>
    );
  }

  const onMenuOpenHandler = useCallback(() => {
    if (!isLargishAndUp) {
      setTimeout(() => trigger(), 300);
    }
  }, [trigger, isLargishAndUp]);

  return (
    <div className="times-field__times-wrapper" ref={ref}>
      <div className="times-field__times">
        <TimePicker
          className="times-field__time"
          id={`${id}-start-time`}
          data-qa-id={`${dataQaId}-start-time`}
          error={startError}
          value={startTime || defaultStart}
          onChange={onChangeStart}
          placeholder={defaultStart || '00:00 AM'}
          disabled={disabled}
          hideSelectArrow
          required
          maxTime={endTime || defaultEnd}
          step={5}
          onMenuOpen={onMenuOpenHandler}
          menuPortalTarget={document.body}
          maxMenuHeight={220}
          borderColor={borderColor}
        />
        <Typography variant="paragraph2" as="span" className="times-field__to">
          to
        </Typography>
        <TimePicker
          className="times-field__time"
          id={`${id}-end-time`}
          data-qa-id={`${dataQaId}-end-time`}
          error={endError}
          value={endTime || defaultEnd}
          onChange={onChangeEnd}
          placeholder={defaultEnd || '00:00 AM'}
          disabled={disabled}
          hideSelectArrow
          required
          minTime={startTime || defaultStart}
          step={15}
          onMenuOpen={onMenuOpenHandler}
          menuPortalTarget={document.body}
          maxMenuHeight={220}
          borderColor={borderColor}
        />
      </div>
    </div>
  );
};

TimesField.propTypes = {
  id: PropTypes.string.isRequired,
  dataQaId: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  defaultStart: PropTypes.string,
  defaultEnd: PropTypes.string,
  onChangeStartTime: PropTypes.func.isRequired,
  onChangeEndTime: PropTypes.func.isRequired,
  startError: PropTypes.string,
  endError: PropTypes.string,
  textVariant: PropTypes.string,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
};
