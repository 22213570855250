import {useCallback} from 'react';
import cx from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Select} from '~/common/_pb_components/atoms/Select';
import {selectMultipleDatesModalFrequency} from '~/SignUpSheets/reducers/sheet/selectors';
import {UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB} from '~/SignUpSheets/reducers/sheet/constants';
import {MonthlyScheduleSpecifier} from './MonthlyScheduleSpecifier/MonthlyScheduleSpecifier';
import {WeeklyScheduleSpecifier} from './WeeklyScheduleSpecifier/WeeklyScheduleSpecifier';
import './FrequencySelector.sass';

const options = [
  {value: 'daily', label: 'Daily'},
  {value: 'weekly', label: 'Weekly'},
  {value: 'every other week', label: 'Every other week'},
  {value: 'monthly', label: 'Monthly'},
];

export const FrequencySelector = () => {
  const dispatch = useDispatch();
  const frequency = useSelector(selectMultipleDatesModalFrequency);

  const onChangeHandler = useCallback(
    (value) =>
      dispatch({
        type: UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB,
        payload: {frequency: value},
      }),
    []
  );

  return (
    <div
      className={cx('add-multiple-dates__frequency__form-group', {
        [frequency?.replaceAll(' ', '-')]: !!frequency,
      })}
    >
      <div className="add-multiple-dates__frequency__form-group__select">
        <Typography as="label" variant="label2">
          REPEATS
        </Typography>
        <Select
          defaultValue={options.find((option) => option.value === frequency)}
          onChange={onChangeHandler}
          data-qa-id="frequency-select"
          options={options}
          menuPosition="fixed"
        />
      </div>
      {frequency === 'weekly' && <WeeklyScheduleSpecifier />}
      {frequency === 'monthly' && <MonthlyScheduleSpecifier />}
    </div>
  );
};
