import {useMemo} from 'react';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {entryHasProductLink, toTitleCase} from '~/SignUpSheets/utils/misc';
import {Link} from '~/common/_pb_components/atoms/Link';

import './PrintableSlot.sass';

const getGroupedSlotTitle = (group) => {
  if (!group) return null;
  switch (group.group_type) {
    case 'time': {
      return `${group.start_time} - ${group.end_time}`;
    }
    default: {
      return null;
    }
  }
};

const getTitle = (slot) => {
  if (slot.group) {
    return getGroupedSlotTitle(slot.group);
  }
  if (slot.start_time && slot.end_time && !slot.all_day) {
    return `${slot.start_time} - ${slot.end_time}`;
  }
  if (slot.item) return slot.item;
  return slot.title;
};

const getSubtitle = (slot) => {
  if (slot.group) return {content: slot.item ?? slot.title, rich: false};
  if (slot.all_day) return {content: slot.description, rich: true};
  return {content: slot.title ?? slot.description, rich: !slot.title && !!slot.description};
};

const getDescription = (slot) => {
  if (slot.group) return slot.description;
  if (slot.all_day) return null;
  if (slot.item || slot.title) return slot.description;
  return null;
};

export const PrintableSlot = ({
  slot = {
    group: null,
    item: null,
    title: null,
    start_time: null,
    end_time: null,
    all_day: false,
    description: '',
    product_link: null,
    signups: [],
  },
  linkPreview = {
    affiliate_url: '',
    images: {
      primary: {
        small: '',
        medium: '',
        large: '',
      },
    },
    title: null,
    price: {
      display_amount: '',
    },
    vendor_name: null,
    url: '',
  },
}) => {
  const title = getTitle(slot);
  const subtitle = getSubtitle(slot);
  const description = getDescription(slot);

  const linkText = useMemo(() => {
    if (!entryHasProductLink(slot) || !linkPreview.url) return null;
    if (linkPreview.vendor_name) return `[${toTitleCase(linkPreview.vendor_name)}] Link`;
    return `[${toTitleCase(slot.product_link.display_text)}] Link`;
  }, [linkPreview, slot.product_link]);

  return (
    <div className="printable-slot">
      <table>
        <thead className="printable-slot__details-wrapper">
          <tr>
            <th className="printable-slot__details">
              {title ? (
                <Typography variant="paragraph3" semibold>
                  {title}
                </Typography>
              ) : null}
              {subtitle.content ? (
                <Typography
                  variant="paragraph4"
                  color="asphalt"
                  semibold={!subtitle.rich}
                  className="signup-sheet__rendered-rich-text"
                >
                  {subtitle.content}
                </Typography>
              ) : null}
              {description ? (
                <Typography
                  variant="paragraph4"
                  color="asphalt"
                  className="signup-sheet__rendered-rich-text"
                >
                  {description}
                </Typography>
              ) : null}
              {linkText ? (
                <Link
                  href={slot.product_link?.url}
                  className="printable-slot__product-link"
                  data-qa-id={`${slot.slot_id ?? slot.uuid}__product-link-url`}
                >
                  {linkText}
                </Link>
              ) : null}
            </th>
          </tr>
        </thead>
        <tbody />
      </table>
      <table className="printable-slot__filled-slots">
        <colgroup>
          <col width="30%" />
          <col width="40%" />
          <col width="30%" />
        </colgroup>
        <thead>
          <tr>
            <th className="printable-slot__details-cell">
              <Typography variant="paragraph3" semibold>
                Name
              </Typography>
            </th>
            <th className="printable-slot__details-cell">
              <Typography variant="paragraph3" semibold>
                Email Address
              </Typography>
            </th>
            <th className="printable-slot__details-cell">
              <Typography variant="paragraph3" semibold>
                Comment
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {slot.signups
            .sort((a, b) => a.name - b.name)
            .map((signup) => (
              <tr key={signup.id}>
                <td className="printable-slot__details-cell">
                  <Typography variant="paragraph4" color="asphalt">
                    {signup.name || '--'}
                  </Typography>
                </td>
                <td className="printable-slot__details-cell">
                  <Typography variant="paragraph4" color="asphalt">
                    {signup.email || '--'}
                  </Typography>
                </td>
                <td className="printable-slot__details-cell">
                  <Typography variant="paragraph4" color="asphalt">
                    {signup.comment || '--'}
                  </Typography>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
