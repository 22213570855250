import {useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {UPDATE_MULTIPLE_DATES_MODAL} from '~/SignUpSheets/reducers/sheet/constants';
import {selectMultipleDatesModalTab} from '~/SignUpSheets/reducers/sheet/selectors';
import {Toggle} from '~/common/_pb_components/atoms/Toggle';

import './ToggleSwitch.sass';

export const ToggleSwitch = () => {
  const dispatch = useDispatch();
  const tab = useSelector(selectMultipleDatesModalTab);
  const inRepeatingTab = tab === 'repeating';
  const customButtonRef = useRef(null);
  const repeatingButtonRef = useRef(null);

  const changeTab = useCallback(
    (value) => () =>
      dispatch({
        type: UPDATE_MULTIPLE_DATES_MODAL,
        payload: {tab: value},
      }),
    [dispatch]
  );

  return (
    <Toggle
      options={[
        {
          label: 'Custom',
          value: 'custom',
          dataQaId: 'custom-tab',
          className: cx('toggle-switch__button', {
            active: !inRepeatingTab,
          }),
          ref: customButtonRef,
        },
        {
          label: 'Repeating',
          value: 'repeating',
          dataQaId: 'repeating-tab',
          className: cx('toggle-switch__button', {active: inRepeatingTab}),
          ref: repeatingButtonRef,
        },
      ]}
      value={tab}
      className="toggle-switch__buttons"
      bgClassName="toggle-switch__background"
      onChange={(val) => changeTab(val)()}
    />
  );
};
