import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import SignUpSheetsIcon from '~/common/svg/icons/SignUpSheetsIcon.svg';
import {selectAddress, selectLocation, selectTitle} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectPrintableHeaderImage} from '~/SignUpSheets/reducers/themes/selectors';
import {useFormattedSheetDate} from '~/SignUpSheets/components/Details/hooks/useFormattedSheetDate';
import {selectIsDateTimeBlueprint} from '~/SignUpSheets/reducers/blueprint/selectors';

import './PrintableHeader.sass';

export const PrintableHeader = () => {
  const title = useSelector(selectTitle);
  const where = useSelector(selectLocation);
  const address = useSelector(selectAddress);
  const headerImage = useSelector(selectPrintableHeaderImage);
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const {date: formattedDate} = useFormattedSheetDate(false, true);

  const renderedAddress = useMemo(() => {
    const {street, city, state, zip} = address;
    const hasAddress = Boolean(street || city || state || zip);
    if (!hasAddress) return '';
    const singleLines = street ? (
      <>
        {street} <br />
      </>
    ) : null;

    const sameLine = [city, state, zip].filter(Boolean).join(' ');

    return (
      <>
        {singleLines}
        {sameLine}
      </>
    );
  }, [address]);

  return (
    <div className="printable-sheet__header">
      {/* SUS LOGO */}
      <img
        src={SignUpSheetsIcon}
        className="printable-sheet__sus-logo-container"
        alt="Evite SignUp Sheets"
      />
      {/* THEME IMAGE */}
      <img
        src={headerImage}
        alt="Sheet header"
        className="printable-sheet__theme-image"
        height="6rem"
        width="6rem"
      />
      {/* SHEET DETAILS */}
      <div className="printable-sheet__details">
        {/* TITLE */}
        <Typography variant="paragraph1" className="printable-sheet__title" semibold as="h1">
          {title}
        </Typography>
        {/* WHEN AND WHERE */}
        <div className="printable-sheet__when-where">
          {/* TODO: Refine condition below for BP1 time slots in 1485 */}
          {isDatetimeType ? (
            <div className="printable-sheet__when">
              <Typography variant="paragraph3" semibold>
                {formattedDate}
              </Typography>
            </div>
          ) : null}
          {where || renderedAddress ? (
            <div className="printable-sheet__where">
              {where && (
                <Typography variant="paragraph3" semibold>
                  {where}
                </Typography>
              )}
              {renderedAddress && (
                <Typography variant="paragraph4" color="asphalt">
                  {renderedAddress}
                </Typography>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
