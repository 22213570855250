import {useSelector} from 'react-redux';
import cx from 'classnames';
import {useMemo} from 'react';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {formatDateShort} from '~/SignUpSheets/utils/misc';
import {selectSheetIsDraft} from '~/SignUpSheets/reducers/sheet/selectors';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_Chevron as Chevron} from '~/common/svg/PB_Chevron';

export const SplitViewLeftColumn = ({
  groupId,
  dateSlot,
  past = false,
  allExpired = false,
  isToday = false,
  expanded = false,
  onToggle,
}) => {
  const hasDateOrTitle = dateSlot.date || dateSlot.title;
  const hasDateAndTitle = dateSlot.date && dateSlot.title;
  const sheetIsDraft = useSelector(selectSheetIsDraft);

  const displayText = useMemo(() => {
    if (!sheetIsDraft) return dateSlot.date ? formatDateShort(dateSlot.date) : dateSlot.title;
    return dateSlot.date ? formatDateShort(dateSlot.date) || '[Sample Date]' : dateSlot.title;
  }, [dateSlot.date, dateSlot.title, sheetIsDraft]);

  return (
    <div className="split-page-section__slot__wrapper">
      <Button
        variant="transparent"
        onClick={() => onToggle?.(!expanded)}
        data-qa-id={`group-expand-collapse-${groupId}`}
        aria-label={`${expanded ? 'Collapse' : 'Expand'} group`}
        type="button"
      >
        <Chevron direction={expanded ? 'up' : 'down'} ratio={0.75} />
      </Button>
      <div className="split-page-section__slot__date-column">
        {hasDateOrTitle && (
          <Typography variant="header5" semibold className="split-page-section__slot__date">
            {displayText}
            {past && !allExpired && <Pill expired>past</Pill>}
            {isToday && !past && <Pill>today</Pill>}
          </Typography>
        )}
        {hasDateAndTitle && (
          <Typography
            variant="list2"
            size="large"
            semibold
            className="split-page-section__slot__title"
          >
            {dateSlot.title}
          </Typography>
        )}
        {dateSlot.description && (
          <Typography
            variant="paragraph3"
            color="shadow"
            className="split-page-section__slot__desc"
          >
            {dateSlot.description}
          </Typography>
        )}
      </div>
    </div>
  );
};

const Pill = ({expired, children}) => (
  <Typography
    as="span"
    className={cx('page-section__status-pill', {expired})}
    variant="label2"
    inline
  >
    {children}
  </Typography>
);
