import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Select} from '~/common/_pb_components/atoms/Select';
import {UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB} from '~/SignUpSheets/reducers/sheet/constants';
import {selectMultipleDatesModalWeeklyScheduleSpecifier} from '~/SignUpSheets/reducers/sheet/selectors';
import './WeeklyScheduleSpecifier.sass';

export const WeeklyScheduleSpecifier = () => {
  const dispatch = useDispatch();
  const weeklyScheduleSpecifier = useSelector(selectMultipleDatesModalWeeklyScheduleSpecifier);

  const onChangeHandler = useCallback(
    (value) =>
      dispatch({
        type: UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB,
        payload: {weeklyScheduleSpecifier: value},
      }),
    []
  );

  return (
    <div className="add-multiple-dates__weekly-schedule-specifier__form-group__selector">
      <Typography as="label" variant="paragraph2">
        every
      </Typography>
      <Select
        onChange={onChangeHandler}
        data-qa-id="weekly-schedule-specifier-select"
        options={Array.from({length: 52}, (v, k) => {
          const value = (k + 1).toString();
          return {value, label: value};
        })}
        className="add-multiple-dates__weekly-schedule-specifier__select"
        defaultValue={{value: weeklyScheduleSpecifier, label: weeklyScheduleSpecifier}}
        menuPosition="fixed"
      />
      <Typography as="label" variant="paragraph2">
        week{weeklyScheduleSpecifier >= 2 && 's'}
      </Typography>
    </div>
  );
};
