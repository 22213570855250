import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const Paint = (props) => {
  const {color = charcoal, className = '', ratio = 1, onClick} = props;

  // default dimensions
  const width = 33;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M25.8367 19.3333C25.8367 19.3333 23.17 22.2267 23.17 24C23.17 24.7072 23.451 25.3855 23.951 25.8856C24.4511 26.3857 25.1294 26.6667 25.8367 26.6667C26.5439 26.6667 27.2222 26.3857 27.7223 25.8856C28.2224 25.3855 28.5033 24.7072 28.5033 24C28.5033 22.2267 25.8367 19.3333 25.8367 19.3333ZM7.45 17.3333L13.8367 10.9467L20.2233 17.3333M22.5833 15.92L10.6633 4L8.78333 5.88L11.9567 9.05333L5.09 15.92C4.30333 16.6667 4.30333 17.96 5.09 18.7467L12.4233 26.08C12.81 26.4667 13.33 26.6667 13.8367 26.6667C14.3433 26.6667 14.8633 26.4667 15.25 26.08L22.5833 18.7467C23.37 17.96 23.37 16.6667 22.5833 15.92Z"
        fill={color}
      />
    </svg>
  );
};
