import React from 'react';
import {pebble} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_Restart = (props) => {
  const {color = pebble, className = '', ratio = 1} = props;

  const width = 16;
  const height = 16;

  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.816326 0C1.26717 0 1.63265 0.365482 1.63265 0.816326V2.7468C1.83078 2.56519 2.0475 2.37782 2.28188 2.19031C3.61516 1.12369 5.58748 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.77726 16 3.78828 15.0359 2.36135 13.6954C0.947824 12.3675 0 10.5853 0 8.86204C0 8.4112 0.365482 8.04571 0.816326 8.04571C1.26717 8.04571 1.63265 8.4112 1.63265 8.86204C1.63265 10.0122 2.29282 11.3909 3.47921 12.5054C4.6522 13.6074 6.25506 14.3673 8 14.3673C9.68872 14.3673 11.3083 13.6965 12.5024 12.5024C13.6965 11.3083 14.3673 9.68872 14.3673 8C14.3673 6.31127 13.6965 4.69171 12.5024 3.49761C11.3083 2.3035 9.68872 1.63265 8 1.63265C6.10231 1.63265 4.4828 2.52039 3.30179 3.4652C3.06644 3.65348 2.85117 3.84195 2.65749 4.02286H4.26449C4.71533 4.02286 5.08082 4.38834 5.08082 4.83918C5.08082 5.29003 4.71533 5.65551 4.26449 5.65551H0.825899C0.797421 5.65585 0.768819 5.65469 0.740206 5.65201C0.528019 5.63239 0.33937 5.53156 0.205627 5.38089C0.0776942 5.23677 0 5.04705 0 4.83918V0.816326C0 0.365482 0.365482 0 0.816326 0Z"
        fill={color}
      />
    </svg>
  );
};
