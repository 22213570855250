import {useSelector, shallowEqual} from 'react-redux';
import {selectIsDateTimeBlueprint} from '~/SignUpSheets/reducers/blueprint/selectors';
import {formatDate} from '~/SignUpSheets/components/Details/components/utils/utils';
import {
  selectSignupOptions,
  selectSignups,
  selectStartDate,
  selectEndDate,
  selectSheetTimezone,
  selectSignupOptionGroups,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {PrintableHeader} from './PrintableHeader/PrintableHeader';
import {PrintableSignups} from './PrintableSignups/PrintableSignups';
import {formatDateShort, sortGroups} from '~/SignUpSheets/utils/misc';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';

import './PrintableView.sass'; // For all page styles
import './PrintableView.css'; // For page numbers
import {sanitizeString} from '~/common/utils/sanitize_html';

const getItemsSignupLookup = (so, groups, su) =>
  so
    .map((s) => ({
      ...s,
      group: groups?.[s.group] ?? null,
      description: s.description ? sanitizeString(s.description) : null,
      signups: su
        .filter((signup) => signup.signup_option === s.uuid)
        .toSorted((a, b) => (a.name < b.name ? -1 : 1)),
    }))
    .toSorted(sortGroups)
    .filter((s) => s.signups.length > 0);
const getDateTimeSignupLookup = (so, su) =>
  so
    .map((s) => ({
      ...s,
      slots: s.slots
        .map((slot) => ({
          ...slot,
          all_day: s.all_day,
          description: slot.description ? sanitizeString(slot.description) : null,
          signups: su
            .filter((signup) => signup.slot_id === slot.slot_id)
            .toSorted((a, b) => (a.name < b.name ? -1 : 1)),
        }))
        .filter((slot) => slot.signups.length > 0),
    }))
    .filter((s) => s.slots.length > 0);

export const PrintableView = ({contentRef}) => {
  const signupOptions = useSelector(selectSignupOptions, shallowEqual);
  const signups = useSelector(selectSignups, shallowEqual);
  const groups = useSelector(selectSignupOptionGroups, shallowEqual);
  const start = useSelector(selectStartDate);
  const end = useSelector(selectEndDate);
  const timezone = useSelector(selectSheetTimezone);
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const {date: sheetDateForSlot, timeRange} = formatDate(start, end, timezone?.name, false);
  const signupMap = isDatetimeType
    ? getDateTimeSignupLookup(signupOptions, signups)
    : getItemsSignupLookup(signupOptions, groups, signups);

  const {linkPreviews} = useAffiliateActions();

  return (
    <table className="printable-sheet" ref={contentRef}>
      {/* HEADER */}
      <thead>
        <tr>
          <th aria-label="Sheet header" className="printable-sheet__header-wrapper">
            <PrintableHeader />
          </th>
        </tr>
      </thead>
      {/* SIGNUPS */}
      <tbody className="printable-sheet__signups-wrapper">
        {isDatetimeType ? (
          signupMap.map((s) => (
            <tr key={s.slot_id ?? s.uuid}>
              <td className="printable-sheet__full-width-row">
                <PrintableSignups
                  date={formatDateShort(s.date)}
                  title={s.title}
                  slots={s.slots}
                  linkPreviews={linkPreviews}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="printable-sheet__full-width-row">
              <PrintableSignups
                date={sheetDateForSlot}
                title={timeRange}
                slots={signupMap}
                linkPreviews={linkPreviews}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
