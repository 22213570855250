import React from 'react';
import cx from 'classnames';

import './Menu.sass';

export const Menu = ({header, items, menuVisible}) => (
  <div
    className={cx('mobile-menu__content', {
      'mobile-menu__content--active': menuVisible,
    })}
  >
    <div className="menu-content">
      {header}
      <div className="menu-content__body">{items}</div>
    </div>
  </div>
);
