import {shallowEqual, useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {AddToCalendar} from '~/common/_pb_components/molecules/AddToCalendar';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {
  selectGroupingType,
  selectCalendarLinks,
  selectIsPreviewMode,
  selectSheetIsDraft,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintType} from '~/SignUpSheets/reducers/blueprint/selectors';
import {useFormattedSheetDate} from '~/SignUpSheets/components/Details/hooks/useFormattedSheetDate';
import {BLUEPRINT_TYPE_ITEMS} from '~/SignUpSheets/reducers/blueprint/constants';

export const DetailsWhenViewOnly = () => {
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const calendarLinks = useSelector(selectCalendarLinks, shallowEqual);
  const blueprintType = useSelector(selectBlueprintType);
  const sheetIsDraft = useSelector(selectSheetIsDraft);
  const isLargishAndUp = useIsLargishAndUp();
  const groupingType = useSelector(selectGroupingType);
  const {date, abbr, timeRange} = useFormattedSheetDate();
  let formattedDate = date;
  const isBluePrintTypeItems = blueprintType === BLUEPRINT_TYPE_ITEMS;

  const mustShowOnlyDate =
    groupingType === 'time' &&
    formattedDate &&
    typeof formattedDate === 'string' &&
    (formattedDate.endsWith('PM') || formattedDate.endsWith('AM'));

  if (mustShowOnlyDate) formattedDate = formattedDate.split(' ').slice(0, 4).join(' ');

  return (
    <>
      {(formattedDate || isPreviewMode) && (
        <>
          <Typography variant={isLargishAndUp ? 'paragraph1' : 'paragraph2'}>
            {isPreviewMode && !formattedDate && sheetIsDraft ? '[Sample Date]' : formattedDate}
          </Typography>
          {timeRange}
          {abbr}
        </>
      )}
      {isBluePrintTypeItems && (
        <div className="signup-sheet__view-details__calendar-container">
          <AddToCalendar
            className="signup-sheet__view-details__action-btn"
            closeMenu={() => {}}
            isButton
            googleCalUrl={calendarLinks.google_cal_url}
            iCalUrl={calendarLinks.ical_url}
            outlookUrl={calendarLinks.outlook_cal_url}
            preview={isPreviewMode}
            buttonSize={isLargishAndUp ? 'large' : 'small'}
          />
        </div>
      )}
    </>
  );
};
