import PropTypes from 'prop-types';

export const ValidationError = ({error, dataQaIdStub}) =>
  error ? (
    <span className="input-error" data-qa-id={`${dataQaIdStub}_inputErrorMessage`}>
      {error}
    </span>
  ) : null;

ValidationError.propTypes = {
  error: PropTypes.string,
  dataQaIdStub: PropTypes.string.isRequired,
};
