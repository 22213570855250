import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import cx from 'classnames';
import moment from 'moment-timezone';
import {Button} from '~/common/_pb_components/atoms/Button';
import {
  selectGroupingType,
  selectSheetTimezone,
  selectStartDate,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectShowWhen} from '~/SignUpSheets/reducers/blueprint/selectors';
import {DateTimeOverlay} from '~/SignUpSheets/components/DateTimeOverlay/DateTimeOverlay';
import {ValidationError} from '~/SignUpSheets/components/ValidationError/ValidationError';
import {useDetailsErrors} from '~/SignUpSheets/components/Details/hooks/useDetailsErrors';
import {useViewOnlyMode} from '~/SignUpSheets/components/Details/ViewOnlyModeProvider';
import {DatePicker} from '~/common/_pb_components/atoms/DatePicker';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';

import {DetailsWhenViewOnly} from './DetailsWhenViewOnly';

export const DetailsWhen = () => {
  const start = useSelector(selectStartDate);
  const {name: timezone} = useSelector(selectSheetTimezone);
  const showWhen = useSelector(selectShowWhen);
  const viewOnlyMode = useViewOnlyMode();
  const groupingType = useSelector(selectGroupingType);
  const {updateSheetDetails} = useUpdateSheet();

  const [dateTimeOverlayOpen, setDateTimeOverlayOpen] = useState(false);
  const detailsErrors = useDetailsErrors();

  const openDateTimeOverlay = useCallback(
    () => setDateTimeOverlayOpen(true),
    [setDateTimeOverlayOpen]
  );

  const closeDateTimeOverlay = useCallback(() => {
    setDateTimeOverlayOpen(false);
  }, [setDateTimeOverlayOpen]);

  const formatDate = useCallback(
    (date) =>
      moment(date).isValid()
        ? [
            moment.utc(date).tz(timezone).format('ddd, ll'),
            moment.utc(date).tz(timezone).format('h:mm A'),
          ]
        : [],
    [timezone]
  );

  const onChangeStartDate = useCallback(
    ([date]) => {
      updateSheetDetails({
        payload: {start: date.toISOString()},
        errorsToClear: [['start'], ['end']],
      });
    },
    [updateSheetDetails]
  );

  const [date, time] = formatDate(start);

  if (viewOnlyMode) {
    return <DetailsWhenViewOnly />;
  }

  if (!showWhen) return null;

  return groupingType === 'time' ? (
    <DatePicker
      live
      error={detailsErrors.when}
      className={cx('input-text', 'details__when-where__input', 'details__font__when', {
        error: !!detailsErrors.when,
      })}
      data-qa-id="sheet-when"
      value={start}
      placeholder="Add event date*"
      dateValueFormat="Z"
      dateRenderFormat="l, M j, Y"
      onChange={onChangeStartDate}
    />
  ) : (
    <>
      <div className="signup-sheet__details__when__input-wrapper">
        <Button
          variant="unstyled-button"
          onClick={openDateTimeOverlay}
          className={cx('input-text', 'details__when-where__input', 'details__font__when', {
            placeholder: !date,
            error: !!detailsErrors.when,
          })}
          data-qa-id="sheet-when"
        >
          {date || 'Add event date and time*'}
        </Button>

        {time && (
          <Button
            variant="unstyled-button"
            onClick={openDateTimeOverlay}
            className={cx(
              'input-text',
              'details__when-where__input',
              'details__font__when',
              'details__font-default',
              {
                error: !!detailsErrors.when,
              }
            )}
            data-qa-id="sheet-when--time"
          >
            {time || 'Add event date and time*'}
          </Button>
        )}
        <ValidationError error={detailsErrors.when} dataQaIdStub="when" />
      </div>

      <DateTimeOverlay isOpen={dateTimeOverlayOpen} closeOverlay={closeDateTimeOverlay} />
    </>
  );
};
