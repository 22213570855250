import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import cx from 'classnames';
import {Input} from '~/common/_pb_components/atoms/Input';
import {useBrowserWidth} from '~/common/hooks';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {selectTitle} from '~/SignUpSheets/reducers/sheet/selectors';
import {resizeTextarea} from '~/SignUpSheets/utils/misc';
import {selectIsReady} from '~/SignUpSheets/reducers/selectors';
import {useDetailsErrors} from '~/SignUpSheets/components/Details/hooks/useDetailsErrors';
import {useOnChangeAndUpdateSheet} from '~/SignUpSheets/hooks/useOnChangeAndUpdateSheet';
import {useViewOnlyMode} from '~/SignUpSheets/components/Details/ViewOnlyModeProvider';

import './DetailsTitle.sass';

let titleFocused = false;

export const DetailsTitle = () => {
  const titleRef = useRef(null);
  const ready = useSelector(selectIsReady);
  const title = useSelector(selectTitle);
  const [onDetailsChange] = useOnChangeAndUpdateSheet();
  const viewOnlyMode = useViewOnlyMode();

  const detailsErrors = useDetailsErrors();

  const browserWidth = useBrowserWidth({timeout: 50});

  useEffect(() => {
    if (!ready || titleFocused || title?.length > 0) return;
    titleFocused = true;
    titleRef.current?.focus();
  }, [ready, title]);

  useEffect(() => {
    resizeTextarea(titleRef.current);
  }, [title, browserWidth]);

  if (viewOnlyMode) {
    return (
      <Typography variant="header5" className="details-title__title--view-only">
        {title || '[Sample Sheet Title]'}
      </Typography>
    );
  }

  return (
    <Input
      value={title}
      onChange={onDetailsChange}
      id="title"
      data-qa-id="sheet-title"
      placeholder="Your sheet title*"
      type="textarea"
      className={cx('signup-details-title__title', {
        error: !!detailsErrors.title,
      })}
      live
      inputClassName="details-title__input details__font__title"
      ref={titleRef}
      rows={1}
      error={detailsErrors.title}
      maxLength={48}
      cols={16}
      wrap="hard"
    />
  );
};
