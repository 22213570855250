import {useSelector} from 'react-redux';
import {ToggleSwitch} from '~/SignUpSheets/Create/routes/Customize/components/AddMultipleDatesModal/components/ToggleSwitch/ToggleSwitch';
import {Custom} from '~/SignUpSheets/Create/routes/Customize/components/AddMultipleDatesModal/Tabs/Custom/Custom';
import {Repeating} from '~/SignUpSheets/Create/routes/Customize/components/AddMultipleDatesModal/Tabs/Repeating/Repeating';
import {selectMultipleDatesModalTab} from '~/SignUpSheets/reducers/sheet/selectors';

export const AddMultipleDates = () => {
  const tab = useSelector(selectMultipleDatesModalTab);

  return (
    <>
      <ToggleSwitch />

      <div className="multiple-dates__overlay-content__main">
        {tab === 'custom' && <Custom />}
        {tab === 'repeating' && <Repeating />}
      </div>
    </>
  );
};
