import {useCallback} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button} from '~/common/_pb_components/atoms/Button';
import {HorizontalDotsIcon} from '~/common/svg/HorizontalDotsIcon';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {selectBlueprintType, selectFieldSchema} from '~/SignUpSheets/reducers/blueprint/selectors';
import {SCOPE_HOST} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectMultipleDatesModalDates,
  selectMultipleSlotsModalIntervalCount,
  selectMultipleSlotsModalSlots,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {initializeSlot, duplicateSlot, schemaToJson} from '~/SignUpSheets/utils/schema';

export const AdvancedOptionsOverlay = ({
  onClose,
  goToNextStep,
  goToPreviousStep,
  currentStep,
  currentStepIndex,
  trackChangeUpstream,
}) => {
  const slots = useSelector(selectMultipleSlotsModalSlots, shallowEqual);
  const dates = useSelector(selectMultipleDatesModalDates, shallowEqual);
  const fieldSchema = useSelector(selectFieldSchema, shallowEqual);
  const blueprintType = useSelector(selectBlueprintType);
  const intervalCount = useSelector(selectMultipleSlotsModalIntervalCount);
  const {updateSheetDetails} = useUpdateSheet();

  const handleSubmit = useCallback(() => {
    const newSignupOptionsList = dates.map((date) => {
      const dateSchemaJson = schemaToJson(fieldSchema, blueprintType, SCOPE_HOST);
      dateSchemaJson.date = date;
      dateSchemaJson.all_day = intervalCount === 0;
      dateSchemaJson.slots = slots.length
        ? slots.map((slot) => duplicateSlot(slot))
        : [initializeSlot(blueprintType)];
      return dateSchemaJson;
    });

    trackChangeUpstream?.(newSignupOptionsList.map((so) => so.uuid));
    updateSheetDetails({
      payload: {signup_options: newSignupOptionsList},
      appendIfArray: true,
      saveChanges: true,
    });

    onClose();
  }, [
    dates,
    fieldSchema,
    blueprintType,
    slots,
    updateSheetDetails,
    onClose,
    intervalCount,
    trackChangeUpstream,
  ]);

  return (
    <>
      <div className="advanced-options__step-content">{currentStep.content}</div>
      <div className="advanced-options__step-icon">
        <HorizontalDotsIcon step={currentStepIndex} />
      </div>
      <div className="advanced-options__step-actions">
        {currentStep.buttons.back && (
          <Button
            variant="secondary"
            size="large"
            onClick={goToPreviousStep}
            fullWidth
            data-qa-id="back-button"
          >
            {currentStep.buttons.back}
          </Button>
        )}
        {currentStep.buttons.cancel && (
          <Button
            variant="secondary"
            size="large"
            onClick={onClose}
            fullWidth
            data-qa-id="cancel-button"
          >
            {currentStep.buttons.cancel}
          </Button>
        )}
        {currentStep.buttons.next && (
          <Button
            variant="primary"
            size="large"
            onClick={goToNextStep}
            disabled={dates?.length === 0}
            fullWidth
            data-qa-id="next-button"
          >
            {currentStep.buttons.next}
          </Button>
        )}
        {currentStep.buttons.submit && (
          <Button
            variant="primary"
            size="large"
            onClick={handleSubmit}
            fullWidth
            data-qa-id="submit-button"
          >
            {currentStep.buttons.submit}
          </Button>
        )}
      </div>
    </>
  );
};
