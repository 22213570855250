import moment from 'moment-timezone';
import React, {useCallback} from 'react';
import {TimePicker} from '~/common/_pb_components/molecules/TimePicker/TimePicker';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_AlertLine as AlertLineIcon} from '~/common/svg/PB_AlertLine';
import {parseTimePieces} from '~/SignUpSheets/hooks/useSortTimes';

export const TimeRangePicker = ({startTime, endTime, setStartTime, setEndTime}) => {
  const dataQaId = 'time-picker';

  const handleStartTimeChange = useCallback(
    ({value}) => {
      const [startHour, startMinutes] = parseTimePieces(value);
      const minEndTS = moment().hour(startHour).minutes(startMinutes);
      const endTS = moment(endTime, 'hh:mm A');
      if (minEndTS.isAfter(endTS)) {
        toastAlert('Start time cannot be greater than end time', {
          id: 'start-time-error',
          toastId: 'start-time-error',
          icon: <AlertLineIcon ratio={1.5} />,
          type: 'error',
          position: 'top-right',
        });
        return;
      }
      setStartTime(value);
    },
    [endTime, setStartTime]
  );

  const handleEndTimeChange = useCallback(
    ({value}) => {
      const [endHour, endMinutes] = parseTimePieces(value);
      const endTS = moment().hour(endHour).minutes(endMinutes);
      const startTS = moment(startTime, 'hh:mm A');
      if (endTS.isBefore(startTS)) {
        toastAlert('End time cannot be less than start time', {
          id: 'end-time-error',
          toastId: 'end-time-error',
          icon: <AlertLineIcon ratio={1.5} />,
          type: 'error',
          position: 'top-right',
        });
        return;
      }
      setEndTime(value);
    },
    [startTime, setEndTime]
  );

  return (
    <div id="range-time-picker" className="add-multiple-slots__time-range">
      <TimePicker
        className="add-multiple-slots__time-picker"
        id={`${dataQaId}-start-time`}
        data-qa-id={`${dataQaId}-start-time`}
        value={startTime || ''}
        onChange={handleStartTimeChange}
        placeholder={startTime || '9:00 AM'}
        hideSelectArrow
        maxTime={endTime}
        menuPosition="fixed"
        required
        step={5}
      />
      <Typography variant="paragraph2" as="span" className="add-multiple-slots__to">
        to
      </Typography>
      <TimePicker
        className="add-multiple-slots__time-picker"
        id={`${dataQaId}-end-time`}
        data-qa-id={`${dataQaId}-end-time`}
        value={endTime}
        maxTime="11:59 PM"
        onChange={handleEndTimeChange}
        placeholder={endTime || '00:00 AM'}
        hideSelectArrow
        minTime={startTime}
        menuPosition="fixed"
        required
        step={5}
      />
    </div>
  );
};
