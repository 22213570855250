import {useCallback, useEffect, useMemo, useRef} from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import cx from 'classnames';
import XDate from 'xdate';
import {PB_Chevron as ChevronIcon} from '~/common/svg/PB_Chevron';
import {Button} from './Button';

import {asphalt} from '~sass/pb_styleguide/base/_exports.sass';
import 'flatpickr/dist/themes/light.css';

const cleanDateInput = (value, minValue) => {
  const date = new XDate(value);
  const minDate = minValue
    ? new XDate(minValue === 'today' ? new Date().toLocaleDateString('en-US') : minValue)
    : false;
  if ((date.valid() && date - minDate >= 0) || !minDate)
    return {value: date.toString('MM/dd/yyyy'), valid: true};
  if (date.valid()) {
    return {value: date.toString('MM/dd/yyyy'), valid: false};
  }
  return {value, valid: false};
};

export const getDateValue = (date) => {
  if (!date) return '';
  let index = date.indexOf('T');
  if (index < 0) index = 10;
  return XDate(date.substr(0, index)).toString('MM/dd/yyyy');
};

export const DatePicker = ({
  disabled = false,
  error,
  focusOnMount = false,
  onChange,
  options = {},
  value,
  onFocus,
  onBlur,
  containerClassName,
  inputContainerClassName,
  className,
  id = `datepicker-${Date.now()}`,
  placeholder,
  name,
  label,
  required = false,
  dateValueFormat = 'Y-m-d',
  dateRenderFormat = 'm/d/Y',
  'data-qa-id': dataQaId,
  minDate,
  multi = false,
  inline = false,
  endAdornment,
  endAdornmentLabel = 'calendar',
  live = false,
  variant,
}) => {
  const flatpickrRef = useRef(null);
  const mountedRef = useRef(false);

  const handleInput = useCallback(
    (input) => {
      if (multi) {
        // Multi-date onChange
        const dates = [];
        for (const date of input) {
          const {value: val, valid} = cleanDateInput(date, options?.minDate);
          if (valid) dates.push(new Date(val));
        }
        onChange(
          dates,
          dates.map((date) => date.toISOString().split('T')[0])
        );
      } else {
        // Single-date onChange
        const {value: val, valid} = cleanDateInput(input[0], options?.minDate);
        if (valid) {
          onChange([new Date(val)], new Date(val).toISOString().split('T')[0]);
        }
      }
    },
    [multi, onChange, options?.minDate]
  );

  const dateValue = useMemo(() => {
    if (multi) {
      return Array.isArray(value) ? value : [value];
    }
    return Array.isArray(value) ? (value[0] ?? null) : value;
  }, [value, multi]);

  const handleOpen = useCallback(() => {
    flatpickrRef.current?.flatpickr?.jumpToDate(
      Array.isArray(dateValue) ? dateValue[0] : dateValue
    );
  }, [dateValue]);

  useEffect(() => {
    if (mountedRef.current) return;
    mountedRef.current = true;
    if (flatpickrRef?.current) {
      if (focusOnMount || inline) {
        flatpickrRef.current.flatpickr?.open();
        handleOpen();
      }
      // Tigran wants the non-value-holding input to have this QA ID
      document.getElementById(id).nextSibling.dataset.qaId = dataQaId;
      // Set disabled on input
      // document.getElementById(id).nextSibling.disabled = disabled;
      // Hide keyboard on mobile
      document.getElementById(id).nextSibling.inputMode = 'none';
    }
  }, [dataQaId, disabled, id, focusOnMount, inline, handleOpen]);

  const datePickerOptions = useMemo(
    () => ({
      altInput: true,
      allowInput: !multi,
      altFormat: dateRenderFormat,
      dateFormat: dateValueFormat,
      minDate: minDate || 'today',
      disableMobile: true,
      mode: multi ? 'multiple' : 'single',
      prevArrow: ReactDOMServer.renderToString(
        <ChevronIcon ratio={0.625} color={asphalt} direction="left" />
      ),
      nextArrow: ReactDOMServer.renderToString(
        <ChevronIcon ratio={0.625} color={asphalt} direction="right" />
      ),
      inline,
      locale: {
        weekdays: {
          shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },
      },
      monthSelectorType: 'static',
      ...options,
    }),
    [options, multi, dateRenderFormat, dateValueFormat, minDate, inline]
  );

  const toggleDatePicker = useCallback(() => {
    flatpickrRef.current?.flatpickr?.open();
  }, []);

  return (
    <div className={cx('input-wrapper', containerClassName)} style={{flexGrow: 1}}>
      {label && (
        <label className="label2 small input-label" htmlFor={name}>
          {label} {required && <span className="label3">(Required)</span>}
        </label>
      )}
      <Flatpickr
        ref={flatpickrRef}
        value={dateValue}
        defaultValue={Array.isArray(dateValue) ? null : dateValue}
        key={id} // Ensures that when value/error/className changes, the visual changes are shown appropriately
        options={datePickerOptions}
        multiple={multi}
        onOpen={handleOpen}
        onClose={handleInput} // Handles manual entry of date(s)
        onChange={(input) => (inline ? handleInput(input) : null)}
        render={({value: val}, ref) => (
          <div
            className={cx('input-box', inputContainerClassName, {
              'input-box__inline-date-picker': inline,
              live,
            })}
          >
            <input
              id={id}
              className={cx('input-text', className, {
                error: !!error,
                filled: !!val,
                hidden: inline,
                live,
                [variant]: !!variant,
              })}
              type="text"
              value={
                Array.isArray(val) ? val.map((v) => getDateValue(v)).join(', ') : getDateValue(val)
              }
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              name={name}
              disabled={disabled}
              inputMode="none"
              ref={ref}
              data-qa-id={`${dataQaId}-input`}
              onChange={() => {}}
            />
            {endAdornment && (
              <Button
                variant="unstyled-button"
                className="input__end-adornment"
                type="button"
                aria-label={endAdornmentLabel}
                onClick={toggleDatePicker}
                data-qa-id={`${dataQaId}-open-calendar`}
              >
                {endAdornment}
              </Button>
            )}
          </div>
        )}
      />
      {error && typeof error === 'string' && (
        <span className="input-error" data-qa-id={`${dataQaId || 'datepicker'}_inputErrorMessage`}>
          {error}
        </span>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  focusOnMount: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputContainerClassName: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  dateValueFormat: PropTypes.string,
  'data-qa-id': PropTypes.string.isRequired,
  minDate: PropTypes.string,
  multi: PropTypes.bool,
  inline: PropTypes.bool,
  live: PropTypes.bool,
  variant: PropTypes.string,
};
