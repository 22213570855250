import {shallowEqual, useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {Link} from '~/common/_pb_components/atoms/Link';
import {redirectToGoogleMaps} from '~/SignUpSheets/utils/misc';
import {selectAddress, selectLocation} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintId} from '~/SignUpSheets/reducers/blueprint/selectors';
import {usePreviewValue} from '~/SignUpSheets/components/Details/hooks/usePreviewValue';
import {useFormattedAddress} from '~/SignUpSheets/components/Details/hooks/useFormattedAddress';
import {DetailsDivider} from '~/SignUpSheets/components/Details/components/DetailsDivider/DetailsDivider';

export const DetailsWhereViewOnly = () => {
  const location = usePreviewValue(selectLocation, 'Sample Location');
  const address = useSelector(selectAddress, shallowEqual);
  const blueprintId = useSelector(selectBlueprintId);
  const isLargishAndUp = useIsLargishAndUp();
  const {street, city, state, zip} = address;
  const hasAddress = Boolean(street || city || state || zip);
  const showLocation = blueprintId !== 'snack' && (location || hasAddress);
  const cityStateZip = useFormattedAddress({addStreet: false});

  if (!showLocation) return null;

  return (
    <>
      <DetailsDivider />
      <div className="signup-sheet__details__bottom__spacing--smaller-gap">
        <Typography
          variant={isLargishAndUp ? 'paragraph1' : 'paragraph2'}
          className="signup-sheet__view-details__where-address"
          color="pebble"
          semibold={isLargishAndUp}
        >
          {location}
        </Typography>

        {hasAddress && (
          <>
            {address.street && (
              <Link
                data-qa-id="view-maps-link"
                target="_blank"
                href={redirectToGoogleMaps(address)}
                className="details-where-view-only__address"
                thickness="thin"
              >
                {address.street}
              </Link>
            )}

            {cityStateZip && (
              <Link
                data-qa-id="view-maps-link"
                target="_blank"
                href={redirectToGoogleMaps(address)}
                className="details-where-view-only__address"
                thickness="thin"
              >
                {cityStateZip}
              </Link>
            )}
          </>
        )}
      </div>
    </>
  );
};
