import moment from 'moment-timezone';
import {useMemo} from 'react';
import {slotElapsed} from '~/SignUpSheets/utils/misc';

export const parseTimePieces = (timeStr) => {
  const [time, modifier] = timeStr.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  hours = parseInt(hours, 10);
  if (modifier === 'PM') hours += 12;

  minutes = parseInt(minutes, 10);

  return [hours, minutes, modifier];
};

export const sortTimesFn = (a, b) => {
  if (!a?.start_time && !b?.start_time) {
    return 0;
  }
  if (!a?.start_time) {
    return 1;
  }
  if (!b?.start_time) {
    return -1;
  }
  const [startHourA, startMinutesA, startModifierA] = parseTimePieces(a.start_time);
  const [startHourB, startMinutesB, startModifierB] = parseTimePieces(b.start_time);

  const startDtA = moment().hour(startHourA).minutes(startMinutesA);
  const startDtB = moment().hour(startHourB).minutes(startMinutesB);

  if (startDtA.isBefore(startDtB)) return -1;
  if (startDtA.isAfter(startDtB)) return 1;

  if (!a?.end_time && !b?.end_time) {
    return 0;
  }
  if (!a?.end_time) {
    return 1;
  }
  if (!b?.end_time) {
    return -1;
  }
  const [endHourA, endMinutesA, endModifierA] = parseTimePieces(a.end_time);
  const [endHourB, endMinutesB, endModifierB] = parseTimePieces(b.end_time);

  const endDtA = moment().hour(endHourA).minutes(endMinutesA);
  const endDtB = moment().hour(endHourB).minutes(endMinutesB);

  // If slot overflows to next day, set end day to next day
  if (startModifierA === 'PM' && endModifierA === 'AM') endDtA.add(1, 'day');
  if (startModifierB === 'PM' && endModifierB === 'AM') endDtB.add(1, 'day');

  if (endDtA.isBefore(endDtB)) return -1;
  if (endDtA.isAfter(endDtB)) return 1;

  return 0;
};

export const sortDownPastTimes = (a, b, isToday) => {
  if (!a.start_time || !b.start_time) return 0;
  const aElapsed = isToday && slotElapsed(a);
  const bElapsed = isToday && slotElapsed(b);
  if (aElapsed && !bElapsed) return 1;
  if (bElapsed && !aElapsed) return -1;

  // Both are either in the past or not in the past; put earlier time first
  return moment(a.start_time, 'h:mm a') - moment(b.start_time, 'h:mm a');
};

export const useSortTimes = (slots, sorter = sortTimesFn) => {
  const sortedList = useMemo(() => [...slots].sort(sorter), [slots]);
  return {
    list: sortedList,
  };
};
