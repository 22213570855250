import {useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Link} from '~/common/_pb_components/atoms/Link';
import {pretty_format_phone_number as formatPhone} from '~/common/utils';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {
  selectIsPreviewMode,
  selectOrganizerEmail,
  selectOrganizerName,
  selectOrganizerPhone,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {usePreviewValue} from '~/SignUpSheets/components/Details/hooks/usePreviewValue';

export const DetailsOrganizerViewOnly = () => {
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const isLargishAndUp = useIsLargishAndUp();

  const isPreviewAndDefaultValue = (value) =>
    isPreviewMode && (value.startsWith('[') || value.endsWith(']'));
  const organizerName = usePreviewValue(selectOrganizerName, 'Sample Organizer Name');
  const organizerEmail = usePreviewValue(selectOrganizerEmail, 'Sample Email');
  const organizerPhone = usePreviewValue(selectOrganizerPhone, 'Sample Phone');

  if (!organizerName && !organizerPhone && !organizerEmail) return null;

  return (
    <div className="signup-sheet__details__bottom__spacing--smaller-gap">
      <Typography
        variant={isLargishAndUp ? 'paragraph1' : 'paragraph2'}
        semibold={isLargishAndUp}
        color="pebble"
      >
        {organizerName}
      </Typography>

      {organizerPhone && (
        <Link
          data-qa-id="organizer-phone"
          href={`tel:${organizerPhone}`}
          variant={isLargishAndUp ? 'large' : 'small'}
          disabled={isPreviewAndDefaultValue(organizerPhone)}
          thickness="thin"
        >
          {formatPhone(organizerPhone)}
        </Link>
      )}

      {organizerEmail && (
        <Link
          data-qa-id="organizer-email"
          href={`mailto:${organizerEmail}`}
          variant={isLargishAndUp ? 'large' : 'small'}
          disabled={isPreviewAndDefaultValue(organizerEmail)}
          thickness="thin"
        >
          {organizerEmail}
        </Link>
      )}
    </div>
  );
};
