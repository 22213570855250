import React, {useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import ReactQuill, {Quill} from 'react-quill';

import 'quill/dist/quill.snow.css';

const Parchment = Quill.import('parchment');

// Configure styles
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = null;
Quill.register(SizeStyle, true);

// Configure line height options
const lineHeightConfig = {
  scope: Parchment.Scope.BLOCK,
};
const lineHeightClass = new Parchment.Attributor.Class(
  'line-height',
  'ql-line-height',
  lineHeightConfig
);
const lineHeightStyle = new Parchment.Attributor.Style(
  'line-height',
  'line-height',
  lineHeightConfig
);
Parchment.register(lineHeightClass);
Parchment.register(lineHeightStyle);
Quill.register({'attributors/class/line-style': lineHeightClass}, true);
Quill.register({'attributors/style/line-style': lineHeightStyle}, true);
Quill.register({'formats/line-height': lineHeightStyle}, true);

// Configure font weight options
const fontWeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: [
    '',
    'normal',
    'bold',
    'bolder',
    'light',
    'lighter',
    'inherit',
    'initial',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ],
};
const fontWeightStyle = new Parchment.Attributor.Style(
  'font-weight',
  'font-weight',
  fontWeightConfig
);
Parchment.register(fontWeightStyle);
Quill.register({'attributors/style/font-weight': fontWeightStyle}, true);
Quill.register({'formats/font-weight': fontWeightStyle}, true);

// Configure text transform options
const textTransformConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: ['none', 'capitalize', 'uppercase', 'lowercase'],
};
const textTransformStyle = new Parchment.Attributor.Style(
  'texttransform',
  'text-transform',
  textTransformConfig
);
Parchment.register(textTransformStyle);
Quill.register({'attributors/style/text-transform': textTransformStyle}, true);
Quill.register({'formats/text-transform': textTransformStyle}, true);

// Configure margin options
const marginConfig = {
  scope: Parchment.Scope.BLOCK,
};
const marginStyle = new Parchment.Attributor.Style('margin', 'margin', marginConfig);
Parchment.register(marginStyle);
Quill.register({'attributors/style/margin': marginStyle}, true);
Quill.register({'formats/margin': marginStyle}, true);

// Configure line alignment options
const AlignStyle = Quill.import('attributors/style/align');
const Icons = Quill.import('ui/icons');
Icons.align.left = Icons.align[''];
AlignStyle.whitelist = ['left', 'center', 'right', 'justify'];
Quill.register(AlignStyle, true);

// Configure link sanitation
const Link = Quill.import('formats/link');
const builtInSanitize = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(link) {
  let val = link;

  // if URL starts with a custom protocol, do nothing
  // otherwise check for http/https
  if (!/^\w+:/.test(val)) {
    if (!/^http:\/\/?:/.test(val) || !/^https:\/\/?:/.test(val)) {
      val = `https://${val}`;
    }
  }
  return builtInSanitize.call(this, val);
};

export const TextEditor = ({
  containerClassName,
  innerQuillClassName,
  value,
  onChange,
  onFocus,
  onBlur,
  toolbar,
  toolbarId,
  toolbarOptions = [],
  scrollingContainer = '.ql-editor',
  style,
  fonts = [],
  fontColors = [],
  defaults,
  id,
  'data-qa-id': dataQaId,
  boundingContainerID,
  placeholder,
  maxLength,
  disabled = false,
}) => {
  const reactQuillRef = useRef(null);
  const modules = {
    toolbar: {
      container: `#${toolbarId}` || '#quill-toolbar',
      clipboard: {
        matchVisual: false,
      },
      ...(toolbarOptions || []),
    },
  };

  const FontStyle = Quill.import('attributors/style/font');
  // FontStyle.whitelist = ['Brut', ...props.fonts];
  delete FontStyle.whitelist;
  Quill.register(FontStyle, true);

  const getColorList = () => {
    const colors = new Set();
    ReactQuill.Toolbar.defaultColors.forEach((c) => {
      colors.add(c.value);
    });
    if (fontColors) {
      fontColors.forEach((c) => {
        colors.add(c.trim());
      });
    }
    return Array.from(colors);
  };

  /**
   * Get a customized toolbar either passed from props or default one
   */
  const getToolbar = () => {
    if (toolbar) return toolbar;

    return (
      <div id="quill-toolbar">
        {/* Font Types */}
        <span className="ql-formats">
          <select className="ql-font">
            <option value="Brut">Default</option>
            {fonts.map((f) => (
              <option key={f} value={f}>
                {f}
              </option>
            ))}
          </select>
        </span>

        {/* Font Sizes */}
        <span className="ql-formats">
          <select title="Size" className="ql-size" defaultValue={defaults?.fontSize}>
            <option value="9px">9</option>
            <option value="10px">10</option>
            <option value="12px">12</option>
            <option value="14px">14</option>
            <option value="16px">16</option>
            <option value="18px">18</option>
            <option value="24px">24</option>
            <option value="32px">32</option>
            <option value="48px">48</option>
            <option value="64px">64</option>
          </select>
        </span>

        {/* Line Heights */}
        {/* <span className="ql-formats"> */}
        {/*  <select className="ql-line-height" defaultValue={props.defaults?.lineHeight || '1.2'}> */}
        {/*    <option value="0.5">0.5</option> */}
        {/*    <option value="0.8">0.8</option> */}
        {/*    <option value="1.0">1.0</option> */}
        {/*    <option value="1.2">1.2</option> */}
        {/*    <option value="1.25">1.25</option> */}
        {/*    <option value="1.3">1.3</option> */}
        {/*    <option value="1.4">1.4</option> */}
        {/*    <option value="2.0">2.0</option> */}
        {/*    <option value="4.0">4.0</option> */}
        {/*  </select> */}
        {/* </span> */}

        {/* Font weights */}
        <button className="ql-bold" type="button" aria-label="bold" />
        <button className="ql-italic" type="button" aria-label="italic" />
        <button className="ql-underline" type="button" aria-label="underline" />
        <button className="ql-clean" type="button" aria-label="clean" />

        {/* Colors */}
        <span className="ql-formats">
          <select className="ql-color" defaultValue={defaults?.color}>
            {getColorList().map((c) => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </select>
        </span>

        {/* Link */}
        <span className="ql-formats">
          <button className="ql-link" type="button" aria-label="link" />
        </span>

        {/* Alignment buttons */}
        <span className="ql-formats">
          <select
            className="ql-align"
            defaultValue={defaults?.alignment || 'center'}
            aria-label="align"
          >
            <option className="ql-align" value="left" aria-label="left" />
            <option className="ql-align" value="center" aria-label="center" />
            <option className="ql-align" value="right" aria-label="right" />
            <option className="ql-align" value="justify" aria-label="justify" />
          </select>
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (!reactQuillRef.current) return;
    const editor = reactQuillRef.current.getEditor().root;
    editor.dataset.qaId = dataQaId;
  }, [dataQaId]);

  const checkCharacterCount = useCallback(
    (event) => {
      const {unprivilegedEditor} = reactQuillRef.current;

      // Allow command keys (Ctrl, Cmd) to bypass the check
      if (event.ctrlKey || event.metaKey) return;

      if (maxLength && unprivilegedEditor.getLength() > maxLength && event.key !== 'Backspace') {
        event.preventDefault();
      }
    },
    [maxLength]
  );

  const onChangeHandler = useCallback(
    (newValue) => {
      if (!reactQuillRef.current) {
        onChange(newValue);
        return;
      }
      const editor = reactQuillRef.current.getEditor();
      const plainText = editor.getText(); // Get plain text without HTML tags

      if (maxLength && plainText.length > maxLength + 1) {
        // maxLength + 1 accounts for trailing newline in maxLength
        // If content exceeds maxLength, delete excess text
        editor.deleteText(maxLength, plainText.length);
      } else {
        onChange(newValue);
      }
    },
    [maxLength, onChange]
  );

  return (
    <div className={containerClassName}>
      {getToolbar()}
      <ReactQuill
        ref={reactQuillRef}
        id={id}
        theme="snow"
        bounds={boundingContainerID || `#${id}`}
        value={value}
        data-qa-id={dataQaId}
        className={innerQuillClassName}
        onChange={onChangeHandler}
        modules={modules}
        style={style}
        scrollingContainer={scrollingContainer}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        onKeyDown={checkCharacterCount}
        readOnly={disabled}
      />
    </div>
  );
};

TextEditor.propTypes = {
  containerClassName: PropTypes.string,
  innerQuillClassName: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  toolbar: PropTypes.node,
  toolbarId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  toolbarOptions: PropTypes.array,
  scrollingContainer: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  fonts: PropTypes.arrayOf(PropTypes.string),
  fontColors: PropTypes.arrayOf(PropTypes.string),
  defaults: PropTypes.shape({
    fontFamily: PropTypes.string,
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    color: PropTypes.string,
    alignment: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  }),
  id: PropTypes.string,
  'data-qa-id': PropTypes.string,
  boundingContainerID: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
