import {useCallback} from 'react';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';

export const useOnChangeAndUpdateSheet = () => {
  const {updateSheetDetails} = useUpdateSheet();

  const onDetailsChange = useCallback(
    ({target: {value, id}}) => updateSheetDetails({payload: {[id]: value}, errorsToClear: [[id]]}),
    [updateSheetDetails]
  );

  return [onDetailsChange];
};
