import {components} from 'react-select';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';

export const Input = (props) => {
  const {selectProps, getValue} = props;
  const option = getValue()?.[0];
  const value = option?.value || '';
  const label = option?.label;
  const isLargishAndUp = useIsLargishAndUp();

  return (
    <components.Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      data-qa-id={selectProps['data-qa-id']}
      data-label={label}
      data-value={value}
      name={selectProps?.name}
      {...(selectProps.menuIsOpen ? {} : {value})}
      type={isLargishAndUp ? 'text' : 'number'}
      inputMode="numeric"
    />
  );
};
