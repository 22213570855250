import {useCallback} from 'react';
import cx from 'classnames';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  selectMultipleDatesModalDays,
  selectMultipleDatesModalFrequency,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB} from '~/SignUpSheets/reducers/sheet/constants';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import './DaysSelector.sass';

const daysOptions = [
  {value: '0', label: 'S'},
  {value: '1', label: 'M'},
  {value: '2', label: 'T'},
  {value: '3', label: 'W'},
  {value: '4', label: 'T'},
  {value: '5', label: 'F'},
  {value: '6', label: 'S'},
];

export const DaysSelector = () => {
  const dispatch = useDispatch();
  const days = useSelector(selectMultipleDatesModalDays, shallowEqual);
  const frequency = useSelector(selectMultipleDatesModalFrequency);

  const onClickHandler = useCallback(
    (day) => () => {
      let updatedDays = [];

      if (days.includes(day)) {
        updatedDays = days.filter((el) => el !== day);
      } else {
        updatedDays = [...days, day];
      }

      // this will avoid the user unselecting all days
      if (frequency !== 'daily' && updatedDays.length >= 1) {
        dispatch({
          type: UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB,
          payload: {days: updatedDays},
        });
      }
    },
    [days, frequency]
  );

  return (
    <div className="repeating__days__wrapper">
      <Typography as="label" variant="label2">
        ON THESE DAYS
      </Typography>
      <div className="repeating__days__options">
        {daysOptions.map((day) => {
          const isDaySelected = days.includes(day.value);
          return (
            <Button
              key={day.value}
              data-qa-id={`day-${day.label}`}
              data-qa-value={isDaySelected.toString()}
              variant="unstyled-button"
              className={cx('repeating__day-option', {active: isDaySelected})}
              onClick={onClickHandler(day.value)}
            >
              {day.label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
