import cx from 'classnames';
import {forwardRef, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {PageSectionHeader} from '~/SignUpSheets/components/PageSection/PageSectionHeader';
import {selectIsPreviewMode} from '~/SignUpSheets/reducers/sheet/selectors';
import {mediumAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';
import {inSignupSheetMaker as inCreateOrEditFlow} from '~/SignUpSheets/history';
import {useBrowserWidth} from '~/common/hooks';

import './PageSection.sass';

export const PageSection = ({
  title = '',
  subtitle = null, // this only displays when the screen is > medium
  children,
  isLoggedIn = false,
  loginCtaText,
  onLogin,
  loginDataQaId,
  footer,
  id,
  expired,
  canceled,
  bodyQaId,
  bodyQaValue,
  pageSectionClassName,
  stackedHeader = false,
  split,
  showOrganizerHeader,
  forceShowSubtitle = false,
  responsiveLoginWall = false,
}) => {
  const {matches: isMedium} = useMatchQuery(`(${mediumAndUpQuery})`);
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const loginButtonRef = useRef();
  const contentRef = useRef();
  const browserWidth = useBrowserWidth();

  useEffect(() => {
    if (!loginButtonRef.current) return;

    if (contentRef.current && responsiveLoginWall) {
      const marginY = Math.max(
        0, // Math.max with 0 prevents negative value
        (contentRef.current.offsetHeight - loginButtonRef.current.offsetHeight) / 2
      );
      loginButtonRef.current.style.margin = `min(${marginY}px, 12.5rem) auto auto`;
    } else {
      loginButtonRef.current.style.margin = 'auto';
    }
  }, [browserWidth, isLoggedIn, children, responsiveLoginWall]);

  return (
    <div
      className={cx('page-section', pageSectionClassName)}
      id={id ?? title.toLowerCase().replaceAll(' ', '-')}
    >
      {!isPreviewMode && (
        <PageSectionHeader
          split={split}
          title={title}
          canceled={canceled}
          expired={expired}
          isMedium={isMedium}
          subtitle={subtitle}
          showOrganizerHeader={showOrganizerHeader}
          forceShowSubtitle={forceShowSubtitle}
          stacked={stackedHeader}
        />
      )}
      {isLoggedIn ? (
        <>
          <div
            className={cx('page-section__body', {expired: canceled || expired})}
            data-qa-id={bodyQaId}
            data-qa-value={bodyQaValue}
            ref={contentRef}
          >
            {children}
          </div>
          {footer && <div className="page-section__footer">{footer}</div>}
        </>
      ) : (
        <button
          className="unstyled-button page-section__body"
          onClick={onLogin}
          type="button"
          ref={contentRef}
        >
          <div className="page-section__login-wall">
            <div className="button primary large" data-qa-id={loginDataQaId} ref={loginButtonRef}>
              {loginCtaText}
            </div>
          </div>
          {children}
        </button>
      )}
    </div>
  );
};

export const SectionRow = forwardRef(
  (
    {
      children,
      wide,
      loggedIn = true,
      expired,
      split = false,
      showOrganizerHeader,
      sectionQaId,
      id,
      grouped = false,
    },
    ref
  ) => {
    const isPreviewMode = useSelector(selectIsPreviewMode);

    return (
      <div
        className={cx({
          'page-section__row': !split,
          'page-section__row--organizer': showOrganizerHeader,
          'split-page-section__slot-group': split,
          'page-section__row__wide': wide,
          'page-section__blur': !loggedIn,
          organizer: inCreateOrEditFlow,
          volunteer: !inCreateOrEditFlow,
          preview: isPreviewMode,
          expired,
          grouped,
        })}
        data-qa-id={sectionQaId}
        id={id}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
