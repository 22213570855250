import {useSelector} from 'react-redux';
import cx from 'classnames';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {selectSheetStatus} from '~/SignUpSheets/reducers/sheet/selectors';

export const PageSectionHeader = ({
  title,
  canceled,
  expired,
  isMedium,
  subtitle,
  showOrganizerHeader,
  forceShowSubtitle,
  stacked,
}) => {
  const sheetStatus = useSelector(selectSheetStatus);

  if (showOrganizerHeader || (sheetStatus === 'draft' && title)) {
    return (
      <div className={cx('page-section__header', {stacked})}>
        {title && (
          <Typography variant="header5" inline>
            {title}
          </Typography>
        )}
        {canceled || expired ? (
          <Typography
            className={cx('page-section__status-pill', {
              canceled,
              expired: expired && !canceled,
            })}
            variant="label2"
            inline
          >
            {canceled ? 'canceled' : 'past'}
          </Typography>
        ) : null}
        {(isMedium || forceShowSubtitle) && subtitle}
      </div>
    );
  }

  return title ? (
    <div className="page-section__header">
      <Typography variant="header5" inline>
        {title}
      </Typography>
    </div>
  ) : null;
};
