import {useSelector} from 'react-redux';
import {useCallback, useState} from 'react';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {selectDetailsHeader} from '~/SignUpSheets/reducers/themes/selectors';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Paint} from '~/common/svg/Paint';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {ThemePickerOverlay} from '~/SignUpSheets/components/ThemePickerOverlay/ThemePickerOverlay';
import {useViewOnlyMode} from '~/SignUpSheets/components/Details/ViewOnlyModeProvider';

import {ghost} from '~sass/pb_styleguide/base/_exports.sass';
import './DetailsThemeChanger.sass';

export const DetailsThemeChanger = () => {
  const isDesktop = useIsLargishAndUp();
  const [themePickerOverlayOpen, setThemePickerOverlayOpen] = useState(false);
  const image = useSelector((s) => selectDetailsHeader(s, {isDesktop}));
  const style = image ? {backgroundImage: `url("https:${image}")`} : {};
  const viewOnlyMode = useViewOnlyMode();

  const openThemePickerOverlay = useCallback(
    () => setThemePickerOverlayOpen(true),
    [setThemePickerOverlayOpen]
  );

  const closeThemePickerOverlay = useCallback(() => {
    setThemePickerOverlayOpen(false);
  }, [setThemePickerOverlayOpen]);

  return (
    <div className="details-theme-changer" style={style}>
      {!viewOnlyMode && (
        <Button
          className="details-theme-changer__button"
          variant="primary"
          rounded
          onClick={openThemePickerOverlay}
          data-qa-id="change-theme-button"
        >
          <Paint ratio={0.5} color={ghost} />
          <Typography variant="list2" size="small">
            Change theme
          </Typography>
        </Button>
      )}

      <ThemePickerOverlay isOpen={themePickerOverlayOpen} closeOverlay={closeThemePickerOverlay} />
    </div>
  );
};
