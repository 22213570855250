import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PrintableSlot} from '~/SignUpSheets/components/PrintableView/PrintableSlot/PrintableSlot';

import './PrintableSignups.sass';

export const PrintableSignups = ({date, title, slots = [], linkPreviews = {}}) => (
  <table className="printable-signups">
    <thead className="printable-signups__header-wrapper">
      <tr>
        <th>
          <div className="printable-signups__header">
            <Typography variant="paragraph3" semibold>
              {date}
            </Typography>
            {title && (
              <Typography variant="paragraph4" semibold color="charcoal">
                {title}
              </Typography>
            )}
          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      {slots.map((slot, index) => (
        <tr key={slot.slot_id ?? slot.uuid} className="printable-signups__full-width-row">
          <td>
            <PrintableSlot
              key={slot.slot_id ?? slot.uuid}
              slot={slot}
              linkPreview={linkPreviews[slot.slot_id ?? slot.uuid]}
            />
            {index < slots.length - 1 ? (
              <Divider
                className="printable-signups__slot-divider"
                marginLeft="0.75rem"
                marginRight="0.75rem"
                marginTop="1rem"
                marginBottom="1rem"
                width="calc(100% - 1.5rem)"
              />
            ) : null}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
