import PropTypes from 'prop-types';
import cx from 'classnames';
import {useCallback, useMemo} from 'react';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Input} from '~/common/_pb_components/atoms/Input';
import {PB_Minus as PBMinus} from '~/common/svg/PB_Minus';
import {PlusIcon} from '~/common/svg/PlusIcon';
import {Typography} from '~/common/_pb_components/atoms/Typography';

import {charcoal, coin} from '~sass/pb_styleguide/base/_exports.sass';

export const Counter = ({
  as = 'controls',
  id = '',
  className = '',
  labelClassName = '',
  labelSize = 'small',
  labelColor = 'charcoal',
  min = 0,
  max = Infinity,
  onMinus = () => {},
  onPlus = () => {},
  inputCallback,
  count,
  disabled = false,
  title,
  plusDisabled = false,
  index = 0,
  error,
  'data-qa-id': dataQaId = 'counter-control',
  selectShowZeroAsBlank = false,
}) => {
  const minimum = useMemo(() => min || 0, [min]);
  const maximum = useMemo(() => max ?? (as === 'dropdown' ? 999 : Infinity), [max, as]);
  const minusDisabled = !(count > minimum);
  const isPlusDisabled = plusDisabled || count >= maximum;
  const inputId = id || `counter-input-${Date.now()}`;

  const inputRequired = count <= minimum;
  const inputTabIndex = 2 + index * 2;
  const handleQuantityChange = useCallback(
    (e) => {
      if (/^\d+$/.test(e.target.value) || e.target.value === '') {
        const intValue = parseInt(e.target.value, 10);
        if (intValue > maximum) {
          inputCallback(maximum);
        } else if (intValue < minimum) {
          inputCallback(minimum);
        } else {
          inputCallback(intValue);
        }
      }
    },
    [maximum, minimum, inputCallback]
  );

  const selectOptions = useMemo(() => {
    if (as !== 'dropdown') return null;
    const numberArr = Array.from(
      {length: maximum || 200},
      (_, i) => minimum + i + (minimum === 0 ? 1 : 0)
    );
    const optionsNums = selectShowZeroAsBlank ? ['-', ...numberArr] : numberArr;
    return optionsNums.map((i) => ({
      label: `${i}`,
      value: typeof i === 'string' ? 0 : i,
      selected: minimum > 0 ? i === minimum : i === count,
    }));
  }, [as, minimum, maximum, selectShowZeroAsBlank]);

  const onSelectChange = useCallback(
    (e) => {
      inputCallback(e.target.value);
    },
    [inputCallback]
  );

  if (as === 'dropdown') {
    return (
      <div className={cx('input-wrapper', {[className]: !!className})}>
        <Typography
          as="label"
          variant="label2"
          htmlFor={inputId}
          color={labelColor}
          className={cx('input-label', {
            [labelClassName]: !!labelClassName,
            [labelSize]: !!labelSize,
          })}
        >
          {title}
        </Typography>
        <select
          className={cx('number-select', {
            error: !!error,
          })}
          onChange={onSelectChange}
          id={inputId}
          defaultValue={count}
          disabled={disabled}
          data-qa-id={dataQaId}
          data-qa-value={count}
        >
          {selectOptions.map((o) => (
            <option name={o.label} value={o.value} key={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        {error && typeof error === 'string' && (
          <span className="input-error" data-qa-id={`${dataQaId}_inputErrorMessage`}>
            {error}
          </span>
        )}
      </div>
    );
  }

  return (
    <div
      className={cx('input-wrapper', 'item-field', 'counter-wrapper', {
        [className]: !!className,
      })}
      data-qa-value={count}
    >
      <Typography
        as="label"
        variant="label2"
        htmlFor={inputId}
        color={labelColor}
        className={cx('input-label', {
          [labelClassName]: !!labelClassName,
          [labelSize]: !!labelSize,
        })}
      >
        {title}
      </Typography>
      <div className={cx('counter', {error})}>
        <Button
          className="counter__decrement"
          disabled={minusDisabled || disabled}
          variant="transparent"
          onClick={onMinus}
          data-qa-id={`${dataQaId}-decrement`}
        >
          <PBMinus color={minusDisabled || disabled ? coin : charcoal} ratio={0.5} />
        </Button>
        <Input
          inputClassName="input-counter counter-input-field"
          type="number"
          value={count}
          onChange={(e) => handleQuantityChange(e)}
          maxLength={4}
          min={minimum}
          max={maximum}
          required={inputRequired}
          tabIndex={inputTabIndex}
          autoFocus={false}
          data-qa-id={`${dataQaId}-value`}
          data-qa-value={count}
          error={!!error}
          disabled={disabled}
        />

        <Button
          className="counter__increment"
          disabled={isPlusDisabled || disabled}
          variant="transparent"
          onClick={onPlus}
          data-qa-id={`${dataQaId}-increment`}
        >
          <PlusIcon color={isPlusDisabled || disabled ? coin : charcoal} ratio={0.5} />
        </Button>
      </div>
      {error && typeof error === 'string' && (
        <span className="input-error" data-qa-id={`${dataQaId}_inputErrorMessage`}>
          {error}
        </span>
      )}
      <input type="hidden" id={inputId} value={count} />
    </div>
  );
};

Counter.propTypes = {
  as: PropTypes.oneOf(['controls', 'dropdown']),
  id: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelSize: PropTypes.oneOf(['smaller', 'small', 'medium', 'large']),
  labelColor: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onMinus: PropTypes.func,
  onPlus: PropTypes.func,
  inputCallback: PropTypes.func,
  count: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  plusDisabled: PropTypes.bool,
  index: PropTypes.number,
  error: PropTypes.string,
  'data-qa-id': PropTypes.string,
  selectShowZeroAsBlank: PropTypes.bool,
};
