import {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {selectErrors} from '~/SignUpSheets/reducers/sheet/selectors';

export const useDetailsErrors = () => {
  const errors = useSelector(selectErrors, shallowEqual);

  return useMemo(() => {
    const titleError = errors.find((e) => e.loc?.[0] === 'title')?.msg;
    const whenError = errors.find((e) => e.loc?.[0] === 'start' || e.loc?.[0] === 'end')?.msg;
    const organizerNameError = errors.find((e) => e.loc?.[0] === 'organizer_name')?.msg;
    const volunteerMessageError = errors.find((e) => e.loc?.[1] === 'message')?.msg;
    const organizerEmailError = errors.find((e) => e.loc?.[0] === 'organizer_email')?.msg;
    const organizerPhoneError = errors.find((e) => e.loc?.[0] === 'organizer_phone')?.msg;
    return {
      title: titleError,
      when: whenError,
      organizerName: organizerNameError,
      volunteerMessage: volunteerMessageError,
      organizerEmail: organizerEmailError,
      organizerPhone: organizerPhoneError,
    };
  }, [errors]);
};
