import {Fragment, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Input} from '~/common/_pb_components/atoms/Input';
import {PB_Trash as TrashIcon} from '~/common/svg/PB_Trash';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {selectOrganizerEmail, selectOrganizerPhone} from '~/SignUpSheets/reducers/sheet/selectors';
import {AddButtonWithTitle} from '~/SignUpSheets/components/AddButtonWithTitle/AddButtonWithTitle';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {useDetailsErrors} from '~/SignUpSheets/components/Details/hooks/useDetailsErrors';

import {tanzanite, charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const DetailsOrganizerInfo = ({onDetailsChange}) => {
  const organizerEmail = useSelector(selectOrganizerEmail);
  const organizerPhone = useSelector(selectOrganizerPhone);
  const {updateSheetDetails} = useUpdateSheet();
  const detailsErrors = useDetailsErrors();
  const isDesktop = useIsLargishAndUp();
  const [showOrganizerContact, setShowOrganizerContact] = useState(isDesktop);

  useEffect(() => {
    if ((organizerEmail || organizerPhone) && !showOrganizerContact) {
      setShowOrganizerContact(true);
    }
  }, [organizerEmail, organizerPhone, showOrganizerContact]);

  const enableOrganizerContact = useCallback(() => {
    setShowOrganizerContact(true);
  }, [setShowOrganizerContact]);

  const onClearOrganizerEmail = useCallback(() => {
    updateSheetDetails({payload: {organizer_email: ''}, errorsToClear: [['organizer_email']]});
  }, [updateSheetDetails]);

  const onClearOrganizerPhone = useCallback(() => {
    updateSheetDetails({payload: {organizer_phone: ''}, errorsToClear: [['organizer_phone']]});
  }, [updateSheetDetails]);

  return (
    <>
      {!showOrganizerContact ? (
        <AddButtonWithTitle
          dataQaId="sheet-enable-organizer-contact"
          label="Add organizer contact information"
          onClick={enableOrganizerContact}
          className="signup-sheet__add-organizer-contact-button"
          colorIcon={charcoal}
          color="charcoal"
        />
      ) : null}

      {showOrganizerContact || organizerEmail || organizerPhone ? (
        <>
          <div className="signup-sheet__organizer-contact">
            <Input
              value={organizerEmail ?? ''}
              onChange={onDetailsChange}
              id="organizer_email"
              data-qa-id="sheet-organizer-email"
              placeholder="Add email"
              inputClassName="details__font-default"
              error={detailsErrors.organizerEmail}
              live
              type="email"
            />
            <Button
              variant="unstyled-button"
              onClick={onClearOrganizerEmail}
              className="signup-sheet__clear-organizer-contact"
              data-qa-id="sheet-clear-organizer-email"
            >
              <TrashIcon color={tanzanite} ratio={1} />
            </Button>
          </div>
          <div className="signup-sheet__organizer-contact">
            <Input
              value={organizerPhone ?? ''}
              onChange={onDetailsChange}
              id="organizer_phone"
              data-qa-id="sheet-organizer-phone"
              placeholder="Add phone number"
              live
              inputClassName="details__font-default"
              error={detailsErrors.organizerPhone}
              type="tel"
            />
            <Button
              variant="unstyled-button"
              onClick={onClearOrganizerPhone}
              className="signup-sheet__clear-organizer-contact"
              data-qa-id="sheet-clear-organizer-phone"
            >
              <TrashIcon color={tanzanite} ratio={1} />
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};
