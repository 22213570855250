import React from 'react';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_CloseLine as PBCloseLine} from '~/common/svg/PB_CloseLine';

export const MenuHeader = ({toggleMenu, title}) => (
  <div className="menu-content__header">
    <div className="menu-content__title">
      <Typography as="h1" variant="paragraph4" size="medium" color="pebble">
        {title}
      </Typography>
    </div>
    <button
      className="menu-content__close-btn unstyled-button"
      onClick={toggleMenu}
      data-qa-id="slot-toolbar-menu-mobile-close"
      data-impression-tracking="true"
      aria-label="Close menu"
      type="button"
    >
      <PBCloseLine ratio={0.75} />
    </button>
  </div>
);
