import cx from 'classnames';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {selectOmittedAssets} from '~/SignUpSheets/reducers/themes/selectors';
import {UPDATE_SHEET_DETAILS} from '~/SignUpSheets/reducers/sheet/constants';
import {selectActiveTheme} from '~/SignUpSheets/reducers/sheet/selectors';

export const Theme = ({theme, idx}) => {
  const dispatch = useDispatch();

  const {
    theme_id: id,
    theme_name: name,
    theme_tile_desktop_url: dtTile,
  } = selectOmittedAssets(theme, true);

  const isActiveTheme = useSelector((s) => selectActiveTheme(s, {theme_id: id}));

  const handleClick = useCallback(
    () =>
      dispatch({
        type: UPDATE_SHEET_DETAILS,
        payload: {theme_id: id},
      }),
    [dispatch, id]
  );

  return (
    <div
      className="grid-item"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={idx}
    >
      <img className={cx('themer-item__thumbnail', {isActiveTheme})} src={dtTile} alt={name} />
      <Typography variant="paragraph4" color="pebble">
        {name}
      </Typography>
    </div>
  );
};
