import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {TimesField} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/Fields/TimesField';

export const TimeGroupConfig = ({
  group,
  disabled = false,
  isDesktop = false,
  errors = [],
  onChange,
  expired = false,
}) => {
  const groupErrors = useMemo(
    () => ({
      start: errors.find(
        (e) => e.loc?.[0] === 'groups' && e.loc?.[1] === group.id && e.loc?.[2] === 'start_time'
      )?.msg,
      end: errors.find(
        (e) => e.loc?.[0] === 'groups' && e.loc?.[1] === group.id && e.loc?.[2] === 'end_time'
      )?.msg,
    }),
    [errors, group.id]
  );

  return (
    <TimesField
      id={`slot-group-time-${group.id}`}
      dataQaId={`group-time-${group.id}`}
      startTime={group.start_time}
      endTime={group.end_time}
      onChangeStartTime={(time) => onChange({start_time: time})}
      onChangeEndTime={(time) => onChange({end_time: time})}
      startError={groupErrors.start}
      endError={groupErrors.end}
      disabled={disabled}
      textVariant={isDesktop ? 'header5' : 'paragraph1'}
      textColor={expired ? 'pebble' : 'charcoal'}
    />
  );
};

TimeGroupConfig.propTypes = {
  group: PropTypes.shape({
    group_type: PropTypes.oneOf(['time']),
    start_time: PropTypes.string,
    end_time: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  errors: PropTypes.arrayOf(
    // Inferred from Pydantic
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      loc: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
      msg: PropTypes.string.isRequired,
      input: PropTypes.string,
      ctx: PropTypes.shape({
        error: PropTypes.string.isRequired,
      }),
      url: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  expired: PropTypes.bool,
};
