import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {selectIsPreviewMode, selectVolunteerMessage} from '~/SignUpSheets/reducers/sheet/selectors';
import {useDetailsErrors} from '~/SignUpSheets/components/Details/hooks/useDetailsErrors';
import {RichTextField} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/Fields/RichTextField';
import {useOnChangeAndUpdateSheet} from '~/SignUpSheets/hooks/useOnChangeAndUpdateSheet';
import {useViewOnlyMode} from '~/SignUpSheets/components/Details/ViewOnlyModeProvider';
import {DetailsOrganizerNoteViewOnly} from './DetailsOrganizerNoteViewOnly';

import './DetailsOrganizerNote.sass';

export const DetailsOrganizerNote = () => {
  const volunteerMessage = useSelector(selectVolunteerMessage);
  const detailsErrors = useDetailsErrors();
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const [onDetailsChange] = useOnChangeAndUpdateSheet();
  const viewOnlyMode = useViewOnlyMode();
  const message =
    isPreviewMode && volunteerMessage.trim() === '' ? '[Sample Note]' : volunteerMessage;

  const onChangeVolunteerMessage = useCallback(
    (value) => {
      onDetailsChange({target: {value, id: 'message'}});
    },
    [onDetailsChange]
  );

  if (viewOnlyMode) {
    return <DetailsOrganizerNoteViewOnly organizerNote={message} />;
  }

  return (
    <div className="signup-sheet__details__organizer-note">
      <RichTextField
        value={message}
        onChange={onChangeVolunteerMessage}
        label="Description"
        id="message"
        dataQaId="sheet-volunteer-note"
        placeholder="Leave a note for your volunteers"
        error={detailsErrors.volunteerMessage}
        innerQuillClassName="details__font__description"
      />
    </div>
  );
};
