import React, {useState, useCallback, useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {OverlayContent} from '~/SignUpSheets/Create/routes/Customize/components/AddMultipleSlots/OverlayContent';
import {AddMultipleDates} from '~/SignUpSheets/Create/routes/Customize/components/AdvancedOptions/modal/steps/AddMultipleDates';
import {Summary} from '~/SignUpSheets/Create/routes/Customize/components/AdvancedOptions/modal/steps/Summary';
import {selectMultipleSlotsModalIntervalCount} from '~/SignUpSheets/reducers/sheet/selectors';

export const useSteps = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const intervalCount = useSelector(selectMultipleSlotsModalIntervalCount, shallowEqual);

  const steps = useMemo(
    () => [
      {
        title: 'Step 1: Add days',
        content: <AddMultipleDates />,
        buttons: {
          next: 'Next: Time slots',
          cancel: 'Cancel',
        },
      },
      {
        title: 'Step 2: Add time slots (optional)',
        content: <OverlayContent showActions={false} />,
        buttons: {
          next: intervalCount > 0 ? 'Next: Summary' : 'Skip',
          back: 'Back',
        },
      },
      {
        title: 'Step 3: Summary',
        content: <Summary />,
        buttons: {
          submit: 'Add now',
          back: 'Back',
        },
      },
    ],
    [intervalCount]
  );

  const goToNextStep = useCallback(() => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }, [currentStepIndex, steps.length]);

  const goToPreviousStep = useCallback(() => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  }, [currentStepIndex]);

  return {
    currentStep: steps[currentStepIndex],
    currentStepIndex,
    steps,
    goToNextStep,
    goToPreviousStep,
  };
};
