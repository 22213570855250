import {components} from 'react-select';

export const Option = (props) => {
  const {innerProps, label} = props;

  return (
    <components.Option
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      innerProps={{...innerProps, 'data-qa-id': label}}
    />
  );
};
