import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {TimezoneSelect} from '~/SignUpSheets/components/TimezoneSelect/TimezoneSelect';
import {
  selectSheetTimezone,
  selectSignupOptionsAreGrouped,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {selectIsDateTimeBlueprint} from '~/SignUpSheets/reducers/blueprint/selectors';

export const DetailsTimezone = () => {
  const sheetTimezone = useSelector(selectSheetTimezone);
  const isDatetimeType = useSelector(selectIsDateTimeBlueprint);
  const isGrouped = useSelector(selectSignupOptionsAreGrouped);

  const {updateSheetDetails} = useUpdateSheet();

  const onTimezoneChange = useCallback(
    ({value, offset}) => {
      updateSheetDetails({payload: {timezone: {name: value, offset}}});
    },
    [updateSheetDetails]
  );

  if (!isDatetimeType && !isGrouped) {
    return null;
  }

  return (
    <TimezoneSelect
      timezone={sheetTimezone}
      onChange={onTimezoneChange}
      openMenuOnFocus
      inputClassName="details__font-default"
      live
    />
  );
};
