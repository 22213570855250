import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_EditDesignLine = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      {...props}
    >
      <path
        d="M12.3333 5V7.66667H5.66667V26.3333H24.3333V19.6667H27V27.6667C27 28.0203 26.8595 28.3594 26.6095 28.6095C26.3594 28.8595 26.0203 29 25.6667 29H4.33333C3.97971 29 3.64057 28.8595 3.39052 28.6095C3.14048 28.3594 3 28.0203 3 27.6667V6.33333C3 5.97971 3.14048 5.64057 3.39052 5.39052C3.64057 5.14048 3.97971 5 4.33333 5H12.3333Z"
        fill={color}
      />
      <path
        d="M14.552 19.6277L28.0747 6.10504L26.1893 4.2197L12.6667 17.7424V19.6277H14.552ZM15.6573 22.2944H9.99999V16.637L25.2467 1.39037C25.4967 1.14041 25.8358 0.999989 26.1893 0.999989C26.5429 0.999989 26.882 1.14041 27.132 1.39037L30.904 5.16237C31.1539 5.41241 31.2944 5.75148 31.2944 6.10504C31.2944 6.45859 31.1539 6.79767 30.904 7.0477L23.2807 14.671L15.6573 22.2944Z"
        fill={color}
      />
    </svg>
  );
};
