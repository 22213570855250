import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  selectEndDate,
  selectSheetTimezone,
  selectStartDate,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintType} from '~/SignUpSheets/reducers/blueprint/selectors';
import {BLUEPRINT_TYPE_ITEMS} from '~/SignUpSheets/reducers/blueprint/constants';
import {formatDate} from '~/SignUpSheets/components/Details/components/utils/utils';

export const useFormattedSheetDate = (includeTzInDate = false, forPrint = false) => {
  const start = useSelector(selectStartDate);
  const end = useSelector(selectEndDate);
  const timezone = useSelector(selectSheetTimezone);
  const blueprintType = useSelector(selectBlueprintType);
  const showTimes = blueprintType === BLUEPRINT_TYPE_ITEMS;

  return useMemo(() => {
    const {
      date = '',
      abbr,
      timeRange,
    } = formatDate(start, end, timezone?.name, showTimes, includeTzInDate, forPrint);
    return {date, abbr, timeRange};
  }, [start, end, timezone?.name, showTimes, includeTzInDate, forPrint]);
};
