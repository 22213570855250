import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {DetailsThemeChanger} from './components/DetailsThemeChanger/DetailsThemeChanger';
import {DetailsTitle} from './components/DetailsTitle/DetailsTitle';
import {DetailsDivider} from './components/DetailsDivider/DetailsDivider';
import {DetailsWhen} from './components/DetailsWhen/DetailsWhen';
import {DetailsWhere} from './components/DetailsWhere/DetailsWhere';
import {DetailsOrganizedBy} from './components/DetailsOrganizedBy/DetailsOrganizedBy';
import {DetailsOrganizerNote} from './components/DetailsOrganizerNote/DetailsOrganizerNote';
import {ViewOnlyModeProvider} from './ViewOnlyModeProvider';

import './Details.sass';

export const Details = ({viewOnlyMode = false}) => {
  const isDesktop = useIsLargishAndUp();

  return (
    <ViewOnlyModeProvider viewOnlyMode={viewOnlyMode}>
      <div className="signup-sheet__details" id="details">
        <DetailsThemeChanger />

        <div className="signup-sheet__details-content">
          <div className="signup-sheet__details-content__group--bigger-gap">
            <DetailsTitle />
            <DetailsWhen />
          </div>

          {(isDesktop || viewOnlyMode) && (
            <div className="signup-sheet__details__bottom">
              <DetailsOrganizerNote />
              <DetailsWhere />
              <DetailsDivider />
              <DetailsOrganizedBy />
            </div>
          )}

          {/* The order/placement of elements from above */}
          {!isDesktop && !viewOnlyMode && (
            <>
              <DetailsWhere />
              <DetailsOrganizerNote />
              <DetailsDivider />
              <DetailsOrganizedBy />
            </>
          )}
        </div>
      </div>
    </ViewOnlyModeProvider>
  );
};
