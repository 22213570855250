import moment from 'moment-timezone';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {selectStartDate, selectEndDate} from '~/SignUpSheets/reducers/sheet/selectors';
import {AddButtonWithTitle} from '~/SignUpSheets/components/AddButtonWithTitle/AddButtonWithTitle';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';

export const AddRemoveEndDate = ({onClick, startDate, automaticallySetEndDate = true}) => {
  const start = useSelector(selectStartDate);
  const end = useSelector(selectEndDate);
  const {updateSheetDetails} = useUpdateSheet();

  const onAddRemoveHandler = useCallback(() => {
    if (!automaticallySetEndDate) {
      return onClick();
    }

    const payload = {
      end: end
        ? null
        : moment(startDate || start) // default end is 3h ahead of start date
            .add(3, 'hours')
            .toISOString(),
    };

    updateSheetDetails({payload, errorsToClear: [['end']]});

    if (!end && onClick) {
      onClick();
    }
    return undefined;
  }, [startDate, start, end, automaticallySetEndDate, updateSheetDetails]);

  return (
    <AddButtonWithTitle
      label={`${end ? 'Remove' : 'Add'} End Date/Time`}
      showRemoveIcon={!!end}
      dataQaId={`${end ? 'remove' : 'add'}-eventenddate`}
      onClick={onAddRemoveHandler}
      disabled={!startDate}
    />
  );
};
