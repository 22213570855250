import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Select} from '~/common/_pb_components/atoms/Select';
import {
  selectMultipleDatesModalMonthlyScheduleSpecifierDay,
  selectMultipleDatesModalMonthlyScheduleSpecifierOrdinal,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER} from '~/SignUpSheets/reducers/sheet/constants';
import './MonthlyScheduleSpecifier.sass';

const ordinalOptions = [
  {value: '1st', label: '1st'},
  {value: '2nd', label: '2nd'},
  {value: '3rd', label: '3rd'},
  {value: '4th', label: '4th'},
  {value: 'last', label: 'last'},
  {value: 'first day of month', label: 'first day of month'},
  {value: 'last day of month', label: 'last day of month'},
];

const daysOptions = [
  {value: '0', label: 'Sunday'},
  {value: '1', label: 'Monday'},
  {value: '2', label: 'Tuesday'},
  {value: '3', label: 'Wednesday'},
  {value: '4', label: 'Thursday'},
  {value: '5', label: 'Friday'},
  {value: '6', label: 'Saturday'},
];

export const MonthlyScheduleSpecifier = () => {
  const dispatch = useDispatch();
  const ordinal = useSelector(selectMultipleDatesModalMonthlyScheduleSpecifierOrdinal);
  const day = useSelector(selectMultipleDatesModalMonthlyScheduleSpecifierDay);
  const hideDaySelector = ordinal === 'first day of month' || ordinal === 'last day of month';

  const onChangeOrdinalHandler = useCallback(
    (value) =>
      dispatch({
        type: UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER,
        payload: {ordinal: value},
      }),
    []
  );

  const onChangeDayHandler = useCallback(
    (value) =>
      dispatch({
        type: UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER,
        payload: {day: value},
      }),
    []
  );

  return (
    <>
      <div className="add-multiple-dates__monthly-schedule-specifier__form-group__selector">
        <Typography as="label" variant="label2">
          ON THE
        </Typography>
        <Select
          onChange={onChangeOrdinalHandler}
          data-qa-id="ordinal-select"
          options={ordinalOptions}
          defaultValue={ordinalOptions.find((option) => option.value === ordinal)}
          menuPosition="fixed"
        />
      </div>
      {!hideDaySelector && (
        <Select
          onChange={onChangeDayHandler}
          data-qa-id="day-select"
          options={daysOptions}
          defaultValue={daysOptions.find((option) => option.value === day)}
          className="add-multiple-dates__monthly-schedule-specifier__day-selector"
          menuPosition="fixed"
        />
      )}
    </>
  );
};
