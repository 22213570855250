import {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_CloseLine as CloseIcon} from '~/common/svg/PB_CloseLine';

export const BottomSheet = (props) => {
  const {onClose, 'data-qa-id': dataQaId = 'bottom-sheet'} = props;
  return (
    <BottomSheetPortal {...props}>
      <Fade>
        <>
          <Button
            variant="unstyled-button"
            className="bottom-sheet__overlay"
            onClick={onClose}
            aria-label="Close"
            data-qa-id={`${dataQaId}__overlay`}
          />
          <SlideUp>
            <Sheet {...props} />
          </SlideUp>
        </>
      </Fade>
    </BottomSheetPortal>
  );
};

BottomSheet.propTypes = {
  in: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.node,
  'data-qa-id': PropTypes.string,
};

const BottomSheetPortalContext = createContext(null);

const BottomSheetPortal = (props) =>
  createPortal(
    <BottomSheetPortalContext.Provider value={props}>
      <div className="bottom-sheet__portal">{props.children}</div>
    </BottomSheetPortalContext.Provider>,
    document.body
  );

const Fade = ({children}) => {
  const {in: IN} = useContext(BottomSheetPortalContext);

  return (
    <CSSTransition in={IN} timeout={300} classNames="fade" unmountOnExit>
      {children}
    </CSSTransition>
  );
};

const SlideUp = ({children}) => {
  const {in: IN} = useContext(BottomSheetPortalContext);

  return (
    <CSSTransition in={IN} timeout={300} classNames="slide-up" appear unmountOnExit>
      {children}
    </CSSTransition>
  );
};

const Sheet = ({content, title, onClose, className}) => (
  <div className={cx('bottom-sheet', className)}>
    {title && (
      <div className="bottom-sheet__header">
        <Typography variant="header5">{title}</Typography>
        <Button variant="unstyled-button" aria-label="Close" onClick={onClose}>
          <CloseIcon ratio={0.75} />
        </Button>
      </div>
    )}
    {content ?? null}
  </div>
);
