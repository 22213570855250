import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {BottomSheet} from '~/common/_pb_components/molecules/BottomSheet';
import {ResponsiveModal} from '~/common/_pb_components/organisms/ResponsiveModal';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {AdvancedOptionsOverlay} from '~/SignUpSheets/Create/routes/Customize/components/AdvancedOptions/modal/AdvancedOptionsOverlay';
import {useSteps} from '~/SignUpSheets/Create/routes/Customize/components/AdvancedOptions/modal/hooks/useSteps';
import {
  UPDATE_MULTIPLE_DATES_MODAL,
  UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM,
} from '~/SignUpSheets/reducers/sheet/constants';
import {initialMultipleDatesModalState} from '~/SignUpSheets/reducers/sheet/reducer';
import {mediumAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';
import './AdvancedOptionsModal.sass';

export const AdvancedOptionsModal = ({onClose, trackChangeUpstream}) => {
  const dispatch = useDispatch();
  const {matches: isDesktop} = useMatchQuery(`(${mediumAndUpQuery})`);
  const {goToNextStep, goToPreviousStep, currentStep, currentStepIndex} = useSteps();

  const handleOnClose = useCallback(() => {
    dispatch({
      type: UPDATE_MULTIPLE_DATES_MODAL,
      payload: {...initialMultipleDatesModalState, open: false},
    });
    dispatch({type: UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM});
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    document.body.classList.add('no-overflow');

    return () => {
      document.body.classList.remove('no-overflow');
    };
  }, []);

  return isDesktop ? (
    <ResponsiveModal
      title={currentStep.title}
      isOpen
      onClose={handleOnClose}
      closeOnClickOutside={false}
      modalId="advanced-options__modal"
      className="advanced-options__modal"
    >
      <AdvancedOptionsOverlay
        onClose={handleOnClose}
        currentStepIndex={currentStepIndex}
        currentStep={currentStep}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        trackChangeUpstream={trackChangeUpstream}
      />
    </ResponsiveModal>
  ) : (
    <>
      <div className="mobile-menu__overlay" />
      <BottomSheet
        in
        title={currentStep.title}
        content={
          <AdvancedOptionsOverlay
            asBottomSheet
            onClose={handleOnClose}
            currentStepIndex={currentStepIndex}
            currentStep={currentStep}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            trackChangeUpstream={trackChangeUpstream}
          />
        }
        onClose={handleOnClose}
      />
    </>
  );
};
