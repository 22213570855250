import React, {createContext, useMemo, useState} from 'react';

const defaultContextValue = {
  setCanvas: () => null,
  canvas: null,
  activeObjects: [],
};

export const FabricContext = createContext(defaultContextValue);

export const FabricContextParent = ({children}) => {
  const [canvas, setCanvas] = useState();
  const [activeObjects, setActiveObjects] = useState();

  const context = useMemo(
    () => ({
      canvas,
      setCanvas,
      activeObjects,
      setActiveObjects,
    }),
    [canvas, setCanvas, activeObjects, setActiveObjects]
  );

  return <FabricContext.Provider value={context}>{children}</FabricContext.Provider>;
};
