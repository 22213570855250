import React, {useCallback, useMemo} from 'react';
import {Select} from '~/common/_pb_components/atoms/Select';
import {Typography} from '~/common/_pb_components/atoms/Typography';

const MIN_OPTIONS = [
  {value: '15', label: '15'},
  {value: '20', label: '20'},
  {value: '30', label: '30'},
  {value: '45', label: '45'},
  {value: '60', label: '60'},
];

const HOUR_OPTIONS = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
  {value: '6', label: '6'},
];

const TIME_UNIT_OPTIONS = [
  {value: 'minutes', label: 'minutes'},
  {value: 'hours', label: 'hours'},
];

export const TimeIncrementSelector = ({
  timeIncrementValue,
  setTimeIncrementValue,
  intervalUnit,
  setIntervalUnit,
}) => {
  const onTimeUnitChangeHandler = useCallback(
    (value) => {
      if (value !== intervalUnit) {
        setTimeIncrementValue(value === 'minutes' ? MIN_OPTIONS[0].value : HOUR_OPTIONS[0].value);
      }
      setIntervalUnit(value);
    },
    [intervalUnit, setTimeIncrementValue, setIntervalUnit]
  );

  const onTimeIncrementChangeHandler = useCallback(
    (value) => {
      setTimeIncrementValue(+value);
    },
    [setTimeIncrementValue]
  );

  const timeOptions = useMemo(
    () => (intervalUnit === 'minutes' ? MIN_OPTIONS : HOUR_OPTIONS),
    [intervalUnit]
  );

  const timeValue = useMemo(
    () => timeOptions.find(({value}) => +value === timeIncrementValue) || timeOptions[0],
    [timeOptions, timeIncrementValue]
  );

  const intervalUnitValue = useMemo(
    () => TIME_UNIT_OPTIONS.find(({value}) => value === intervalUnit) || TIME_UNIT_OPTIONS[0],
    [intervalUnit]
  );

  return (
    <div className="add-multiple-slots__time-incrementals">
      <Typography variant="list2">Every</Typography>
      <Select
        value={timeValue}
        defaultValue={MIN_OPTIONS[0]}
        onChange={onTimeIncrementChangeHandler}
        data-qa-id="time-increment-select"
        options={timeOptions}
        menuPosition="fixed"
      />
      <Select
        value={intervalUnitValue}
        className="add-multiple-slots__time-incrementals__unit"
        defaultValue={TIME_UNIT_OPTIONS[0]}
        onChange={onTimeUnitChangeHandler}
        data-qa-id="interval-unit-select"
        options={TIME_UNIT_OPTIONS}
        menuPosition="fixed"
      />
    </div>
  );
};
