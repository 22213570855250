import moment from 'moment-timezone';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectBlueprintType} from '~/SignUpSheets/reducers/blueprint/selectors';
import {UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectMultipleSlotsModalEndTime,
  selectMultipleSlotsModalIntervalUnit,
  selectMultipleSlotsModalSignupEachSlot,
  selectMultipleSlotsModalStartTime,
  selectMultipleSlotsModalTimeIncrementValue,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {initializeSlot} from '~/SignUpSheets/utils/schema';

export const useMultipleSlots = () => {
  const dispatch = useDispatch();
  const blueprintType = useSelector(selectBlueprintType);
  const startTime = useSelector(selectMultipleSlotsModalStartTime);
  const endTime = useSelector(selectMultipleSlotsModalEndTime);
  const timeIncrementValue = useSelector(selectMultipleSlotsModalTimeIncrementValue);
  const intervalUnit = useSelector(selectMultipleSlotsModalIntervalUnit);
  const signupEachSlot = useSelector(selectMultipleSlotsModalSignupEachSlot);

  const intervalInMinutes = useCallback(
    () => (intervalUnit === 'hours' ? timeIncrementValue * 60 : timeIncrementValue),
    [intervalUnit, timeIncrementValue]
  );

  const calculateIntervalCount = useCallback(() => {
    const start = moment(startTime, 'hh:mm A');
    const end = moment(endTime, 'hh:mm A');
    const duration = moment.duration(end.diff(start));
    const minutes = duration.asMinutes();
    return Math.floor(minutes / intervalInMinutes());
  }, [startTime, endTime, intervalInMinutes]);

  const generateSlots = useCallback(() => {
    const interval = intervalInMinutes();
    const intervalCount = calculateIntervalCount();

    const buildNewSlot = (slot, idx) => ({
      ...slot,
      start_time: moment(startTime, 'hh:mm A')
        .add(interval * idx, 'minutes')
        .format('hh:mm A'),
      end_time: moment(startTime, 'hh:mm A')
        .add(interval * (idx + 1), 'minutes')
        .format('hh:mm A'),
      quantity: signupEachSlot,
    });

    return Array.from({length: intervalCount}, () => initializeSlot(blueprintType)).map(
      buildNewSlot
    );
  }, [calculateIntervalCount, intervalInMinutes, startTime, signupEachSlot, blueprintType]);

  useEffect(() => {
    if (startTime && endTime) {
      const slots = generateSlots();
      dispatch({
        type: UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT,
        payload: {intervalCount: slots?.length, slots},
      });
    }
  }, [startTime, endTime, generateSlots]);

  return {
    generateSlots,
    intervalInMinutes,
    calculateIntervalCount,
  };
};
