import React from 'react';
import {coin, charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const HorizontalDotsIcon = (props) => {
  const {
    activeColor = charcoal, // Array de cores, padrão é todos da mesma cor
    inactiveColor = coin,
    step = 0,
    className = '',
    ratio = 1,
  } = props;

  // default dimensions
  const width = 24;
  const height = 6;
  const style = {height: `${height}px`, width: `${ratio * width}px`};

  const colors = [inactiveColor, inactiveColor, inactiveColor];
  if (step >= 0 && step <= 2) {
    colors[step] = activeColor;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      style={style}
      className={className}
    >
      <g transform="translate(-903 -251)">
        <circle style={{fill: colors[0]}} cx="3" cy="3" r="3" transform="translate(903 251)" />
        <circle style={{fill: colors[1]}} cx="3" cy="3" r="3" transform="translate(912 251)" />
        <circle style={{fill: colors[2]}} cx="3" cy="3" r="3" transform="translate(921 251)" />
      </g>
    </svg>
  );
};
