import omitBy from 'lodash/omitBy';

export const selectThemes = ({themes}) => themes;

export const selectOmittedAssets = (theme, isDesktop) =>
  omitBy(theme, (_, key) => (isDesktop ? key.includes('mw') : key.includes('desktop')));

export const selectThemeById = ({themes}, {id, isDesktop}) => {
  const byThemeId = ({theme_id: tid}) => tid === id;
  const [theme] = themes.flatMap((el) => el.themes).filter(byThemeId);
  return selectOmittedAssets(theme, isDesktop);
};

export const selectPrintableHeaderImage = (s) => {
  const {theme_id: b} = s.blueprint;
  const {theme_id: a} = s.sheet;

  const {theme_tile_desktop_url: image} = selectThemeById(s, {id: a || b, isDesktop: true});
  return image;
};

export const selectDetailsHeader = (s, {isDesktop}) => {
  const {theme_id: b} = s.blueprint;
  const {theme_id: a} = s.sheet;

  const {theme_tile_desktop_url: dt, header_mw_url: mw} = selectThemeById(s, {
    id: a || b,
    isDesktop,
  });

  return isDesktop ? dt : mw;
};

export const selectBackground = ({blueprint, sheet, ...s}, {isDesktop}) => {
  const {theme_id: a} = blueprint;
  const {theme_id: b} = sheet;

  const {background_url: url = ''} = selectThemeById(s, {id: b || a, isDesktop});

  return /\.(mp4|webm)$/i.test(url) ? url : `url("https:${url}")`;
};

export const selectSlotColor = ({blueprint, sheet, ...s}) => {
  const {theme_id: a} = blueprint;
  const {theme_id: b} = sheet;

  const {slot_color: slotColor = ''} = selectThemeById(s, {id: b || a});

  return `#${slotColor}`;
};

export const selectSlotLine = ({blueprint, sheet, ...s}) => {
  const {theme_id: a} = blueprint;
  const {theme_id: b} = sheet;

  const {slot_line: slotLine = ''} = selectThemeById(s, {id: b || a});

  return `#${slotLine}`;
};
