import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Paint} from '~/common/svg/Paint';
import {PB_Chevron as PBChevron} from '~/common/svg/PB_Chevron';
import {Categories} from './Categories';
import {mediumAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';

export const OverlayContent = ({handleClose}) => {
  const {matches: isDesktop} = useMatchQuery(`(${mediumAndUpQuery})`);

  return (
    <>
      {!isDesktop && (
        <div className="themer-bottom-sheet__heading">
          <Paint />
          <Typography
            variant="paragraph2"
            semibold
            className="themer-botto-sheet__heading-copy"
            color="charcoal"
          >
            Change theme
          </Typography>
          <Button
            variant="transparent"
            color="pebble"
            onClick={handleClose}
            data-qa-id="theme-close-button"
            className="themer-bottom-sheet__heading__close"
          >
            <PBChevron direction="up" />
          </Button>
        </div>
      )}
      <div className="themer-overlay__content">
        <Categories />
      </div>
    </>
  );
};
