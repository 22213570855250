/* eslint-disable max-lines-per-function */
import moment from 'moment-timezone';
import {v4} from 'uuid';
import {USER_LOGGED_IN} from '~/common/components/LoginModal/reducer';
import {INIT_APP} from '~/common/fabric/constants';
import {
  SET_SHEET,
  TOGGLE_SHOW_VALIDATION_MODAL,
  TOGGLE_SHOW_NOTIFY_VOLUNTEERS_MODAL,
  UPDATE_ERRORS,
  UPDATE_SHEET_DETAILS,
  SET_SELECTED_SIGNUPS,
  UPDATE_SIGNUPS,
  SET_SIGNUPS,
  REMOVE_SIGNUP,
  UPDATE_MULTIPLE_DATES_MODAL,
  UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB,
  UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB,
  UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER,
  UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE,
  UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM,
  UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT,
  SET_SIGNUPS_PENDING_DELETION,
  APPLY_TEMPLATE,
  DELETE_SIGNUP_OPTION,
  MOVE_SIGNUP_OPTION_UP,
  MOVE_SIGNUP_OPTION_DOWN,
  ENABLE_GROUPING,
  DISABLE_GROUPING,
  UPDATE_GROUP,
  DUPLICATE_GROUP,
  DELETE_GROUP,
  UPDATE_OPEN_GROUPS,
} from './constants';
import {getUserTimezone} from '~/SignUpSheets/components/TimezoneSelect/TimezoneSelect';
import {getTime30MinsLater, sortOptionsByDate} from '~/SignUpSheets/utils/misc';
import {markdown2Html} from '~/SignUpSheets/utils/parser';
import {selectIsAutoSorting} from '~/SignUpSheets/reducers/blueprint/selectors';
import {frequencyHandler, selectedDayHandler} from '~/SignUpSheets/reducers/sheet/utils';

const userTimezone = getUserTimezone();

const todaysDate = moment();

export const initialMultipleDatesModalState = {
  tab: 'custom',
  open: false,
  dates: [],
  startDate: todaysDate.format('YYYY-MM-DDTHH:mm:ss'),
  endDate: null,
  frequency: 'weekly',
  days: [todaysDate.day().toString()],
  weeklyScheduleSpecifier: '1',
  monthlyScheduleSpecifier: {
    ordinal: '1st',
    day: todaysDate.day().toString(),
  },
};

const initialMultipleSlotsModalState = {
  startTime: '9:00 AM',
  endTime: '',
  timeIncrementValue: 15,
  intervalUnit: 'minutes',
  signupEachSlot: 2,
  intervalCount: 0,
  slots: [],
};

export const initialState = {
  notify_volunteers: null,
  show_notify_volunteers_modal: null,
  pristine: true,
  has_unsaved_wishlists: false,
  errors: [],
  show_validation_modal: false,
  ready: false,
  multipleDatesModal: initialMultipleDatesModalState,
  multipleSlotsModal: initialMultipleSlotsModalState,
  // The below should match the backend representation as closely as possible, preferably exactly
  id: null,
  blueprint_id: '',
  sheet_type: '',
  organizer_name: window.userName ?? '',
  organizer_id: window.user_id ?? '',
  title: '',
  start: null,
  end: null,
  preexisting_end: false,
  location: null,
  address: {city: '', state: '', street: '', zip: '', country: 'US'},
  message: '',
  organizer_email: null,
  organizer_phone: null,
  background_image: null,
  wishlist_option: 'evite-wishlist',
  wishlist_name: '',
  wishlists: [''],
  localWishlists: [''],
  previewMode: false,
  created_at: null,
  status: 'draft',
  groups: {},
  signup_options: [],
  signups: [],
  selected_signups: [],
  signups_pending_deletion: [],
  theme_id: '',
  timezone: {
    name: userTimezone.value,
    offset: userTimezone.offset,
  },
  calendar_links: {},
  short_link: null,
  shortlink_host: window.shortlink_host,
  open_groups: new Set(),
};

const sortSignupOptions = (signupOptions, sheetType) => {
  if (signupOptions?.length > 0 && selectIsAutoSorting({blueprint: {blueprint_type: sheetType}})) {
    return signupOptions.toSorted(sortOptionsByDate);
  }
  return signupOptions;
};

const setDaysAndDatesBasedOnSeries = (newState, prevState) => {
  const updatedState = {...newState};
  updatedState.multipleDatesModal.days = selectedDayHandler({sheet: prevState}, {sheet: newState});
  updatedState.multipleDatesModal.dates = frequencyHandler({sheet: newState});
  return updatedState;
};

export const reducer = (state = initialState, action = {}) => {
  let newState = {...state};

  switch (action.type) {
    case USER_LOGGED_IN:
      newState = {
        ...state,
        organizer_name: state.organizer_name || action.userName,
        organizer_id: state.organizer_id || action.userId,
      };
      break;
    case INIT_APP: {
      const {sheet = {}} = action.payload;
      newState = {
        ...state,
        ...sheet,
        ready: Object.keys(sheet) > 0,
        preexisting_end: !!sheet.end,
      };
      break;
    }

    case SET_SIGNUPS: {
      newState = {
        ...state,
        signups: action.payload ?? [],
      };
      break;
    }

    case UPDATE_SIGNUPS: {
      const lookup = action.payload.reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {});

      newState = {
        ...state,
        signups: state.signups.map((s) =>
          lookup[s.id]
            ? {
                ...s,
                id: lookup[s.id].id,
                user_id: lookup[s.id].user_id,
                comment: lookup[s.id].comment,
                email: lookup[s.id].email,
                name: lookup[s.id].name,
                slot_id: lookup[s.id].slot_id,
                purchased: lookup[s.id].purchased,
              }
            : s
        ),
      };
      break;
    }
    case REMOVE_SIGNUP: {
      const newId = Math.max(...state.signups.map((signup) => signup.id)) + 1;
      newState = {
        ...state,
        signups: state.signups.map((signup) =>
          signup.id === action.payload
            ? {
                ...signup,
                id: newId,
                user_id: '',
                name: '',
                email: '',
                comment: '',
              }
            : signup
        ),
      };
      break;
    }
    case SET_SELECTED_SIGNUPS: {
      newState = {
        ...state,
        selected_signups: action.payload,
      };
      break;
    }
    case SET_SIGNUPS_PENDING_DELETION: {
      newState = {
        ...state,
        signups_pending_deletion: action.payload,
      };
      break;
    }
    case SET_SHEET: {
      const {signups: payloadSignups = [], signup_options: payloadSignupOptions = []} =
        action.payload;
      const signups = [];
      for (const signup of payloadSignups) {
        if (signup.slot_id) {
          const signupOption = payloadSignupOptions.find(
            (so) => so.slots && so.slots.some((slot) => slot.slot_id === signup.slot_id)
          );
          signups.push({
            ...signup,
            signup_option: signupOption?.uuid,
          });
        } else {
          signups.push(signup);
        }
      }
      newState = {
        ...state,
        ...action.payload,
        signups,
        message: markdown2Html(action.payload.message ?? ''),
        ready: true,
        preexisting_end: !!action.payload.end,
      };
      break;
    }
    case UPDATE_SHEET_DETAILS: {
      // Check to see that has_unsaved_wishlists is being changed
      const {appendIfArray = false, ...payload} = action.payload;
      const {has_unsaved_wishlists: hasUnsavedWishlists} = payload;

      const updateStateValues = () => {
        Object.entries(payload).forEach(([key, val]) => {
          if (appendIfArray && Array.isArray(val) && Array.isArray(newState[key])) {
            newState[key] = [...newState[key], ...val];
          } else {
            newState[key] = val;
          }
        });
      };

      if (hasUnsavedWishlists === undefined) {
        // has_unsaved_wishlists is not being changed; proceed as normal
        updateStateValues();
        break;
      }

      // Update error object to account for has_unsaved_wishlists
      const errors = [...(hasUnsavedWishlists ? [] : state.errors)];
      const showValidationModal = hasUnsavedWishlists || state.show_validation_modal;
      if (hasUnsavedWishlists) {
        const idx = errors.find((e) => e.loc?.[0] === 'wishlists');
        if (idx >= 0) {
          errors[idx].msg = 'Please finish saving your wishlists';
        } else {
          errors.push({
            type: 'value_error',
            loc: ['wishlists'],
            msg: 'Please finish saving your wishlists',
          });
        }
      } else {
        const idx = errors.find((e) => e.loc?.[0] === 'wishlists');
        if (idx >= 0) errors.splice(idx, 1);
      }

      updateStateValues();
      newState.has_unsaved_wishlists = hasUnsavedWishlists;
      newState.show_validation_modal = showValidationModal;
      newState.errors = errors;
      break;
    }
    case UPDATE_OPEN_GROUPS: {
      newState.open_groups = new Set(action.payload);
      break;
    }
    case ENABLE_GROUPING: {
      const {type} = action.payload;
      newState.groups = {};
      const signupOptions = state.signup_options.map((so) => ({
        ...so,
        group: so.group ?? null,
      }));
      for (let i = 0; i < signupOptions.length; i++) {
        const groupId = v4();
        newState.groups[groupId] = {
          group_type: type,
          start_time: null,
          end_time: null,
        };
        signupOptions[i].group = groupId;
        if (i === 0) {
          const now = moment().set('milliseconds', 0);
          const onThe30 = now.minutes() % 30 === 0;
          if (type === 'time') {
            newState.groups[groupId].start_time = newState.start
              ? moment(newState.start).format('h:mm A')
              : now
                  .set('hours', now.hours() + 1)
                  .set(
                    'minutes',
                    onThe30
                      ? now.minutes() // Already on the 30; no rounding up
                      : now.minutes() + (30 - (now.minutes() % 30)) // Round up to nearest 30
                  )
                  .format('h:mm A');
            newState.groups[groupId].end_time = getTime30MinsLater(
              newState.groups[groupId].start_time
            );
          }
        }
      }
      newState.signup_options = signupOptions;
      break;
    }
    case DISABLE_GROUPING: {
      newState = {
        ...state,
        groups: {},
        signup_options: state.signup_options.map((so) => ({
          ...so,
          group: null,
        })),
      };
      break;
    }
    case DELETE_SIGNUP_OPTION: {
      const {index} = action.payload;
      if (index >= 0 && index < state.signup_options.length) {
        const newSignupOptionsList = [...state.signup_options];
        newSignupOptionsList.splice(index, 1);
        let newSignups;
        const deletedSignupSlots = state.signup_options[index].slots;
        if (deletedSignupSlots) {
          const deletedSignupSlotsIds = deletedSignupSlots.map((slot) => slot.slot_id);
          newSignups = state.signups.filter(
            (signup) => !deletedSignupSlotsIds.includes(signup.slot_id)
          );
        } else {
          const deletedSignupOptionUuid = state.signup_options[index]?.uuid;
          newSignups = state.signups.filter(
            (signup) => deletedSignupOptionUuid && signup.signup_option !== deletedSignupOptionUuid
          );
        }
        let groups = null;
        if (state.groups) {
          groups = {...state.groups};
          for (const groupId in groups) {
            if (!newSignupOptionsList.some((s) => s.group === groupId)) {
              delete groups[groupId];
            }
          }
        }
        newState = {
          ...state,
          signup_options: newSignupOptionsList,
          groups,
          signups: newSignups,
          pristine: false,
        };
      } else {
        evite.error('Invalid index for deletion');
      }
      break;
    }
    case MOVE_SIGNUP_OPTION_UP: {
      // TODO: Prune out "move up" functionality for 1437 (Drag N Drop)
      const {index, groupId = null} = action.payload;
      let srcIndex = index;
      if (srcIndex <= 0 || srcIndex >= state.signup_options.length) break; // Skip if index is out of bounds or already at the beginning of the list
      const newSignupOptionsList = [...state.signup_options];

      let destIndex = srcIndex - 1;

      if (groupId) {
        const optionsInGroup = newSignupOptionsList
          .map((o, i) => ({...o, index: i}))
          .filter((o) => o.group === groupId);
        if (optionsInGroup.length <= 1) break; // Only one option in this group; no movement possible

        destIndex = optionsInGroup[srcIndex - 1].index;
        srcIndex = optionsInGroup[srcIndex].index;
      }

      [newSignupOptionsList[destIndex], newSignupOptionsList[srcIndex]] = [
        newSignupOptionsList[srcIndex],
        newSignupOptionsList[destIndex],
      ];
      newState = {
        ...state,
        signup_options: newSignupOptionsList,
        pristine: false,
      };
      break;
    }
    case MOVE_SIGNUP_OPTION_DOWN: {
      // TODO: Prune out "move down" functionality for 1437 (Drag N Drop)
      const {index, groupId = null} = action.payload;
      let srcIndex = index;
      if (srcIndex < 0 || srcIndex >= state.signup_options.length - 1) break; // Skip if index is out of bounds or already at the end of the list
      const newSignupOptionsList = [...state.signup_options];

      let destIndex = srcIndex + 1;

      if (groupId) {
        const optionsInGroup = newSignupOptionsList
          .map((o, i) => ({...o, index: i}))
          .filter((o) => o.group === groupId);
        if (optionsInGroup.length <= 1) break; // Only one option in this group; no movement possible

        destIndex = optionsInGroup[srcIndex + 1].index;
        srcIndex = optionsInGroup[srcIndex].index;
      }
      [newSignupOptionsList[srcIndex], newSignupOptionsList[destIndex]] = [
        newSignupOptionsList[destIndex],
        newSignupOptionsList[srcIndex],
      ];
      newState = {
        ...state,
        signup_options: newSignupOptionsList,
        pristine: false,
      };
      break;
    }
    case UPDATE_GROUP: {
      if (!action.payload.groupId) break;
      const groups = {...state.groups} ?? {};
      const errors = [...state.errors];
      if (action.payload.groupId in groups) {
        // Existing group
        groups[action.payload.groupId] = {
          ...groups[action.payload.groupId],
          ...(action.payload.details ?? {}),
        };
        // Clear errors on changed group fields
        Object.keys(action.payload.details).forEach((k) => {
          const errorIdx = errors.findIndex(
            (e) =>
              e.loc?.[0] === 'groups' && e.loc?.[1] === action.payload.groupId && e.loc?.[2] === k
          );
          if (errorIdx < 0) return;
          errors.splice(errorIdx, 1);
        });
      } else {
        // New group
        switch (action.payload.details.group_type) {
          case 'time': {
            const existingEndTimes = Object.values(groups)
              .filter((g) => !!g.end_time)
              .map((g) => g.end_time)
              .sort((a, b) => moment(b, 'hh:mm A').valueOf() - moment(a, 'hh:mm A').valueOf());
            if (existingEndTimes.length === 0) {
              groups[action.payload.groupId] = {
                ...(groups[action.payload.groupId] ?? {}),
                ...(action.payload.details ?? {}),
              };
            } else {
              const [startTime] = existingEndTimes;
              const endTime = getTime30MinsLater(startTime);
              groups[action.payload.groupId] = {
                ...(groups[action.payload.groupId] ?? {}),
                ...(action.payload.details ?? {}),
                start_time: startTime,
                end_time: endTime,
              };
            }
            break;
          }
          default: {
            groups[action.payload.groupId] = {
              ...(groups[action.payload.groupId] ?? {}),
              ...(action.payload.details ?? {}),
            };
            break;
          }
        }
      }
      newState.groups = groups;
      newState.errors = errors;
      break;
    }
    case DUPLICATE_GROUP: {
      const {groupId, newGroupId = v4()} = action.payload;
      const group = newState.groups[groupId];
      if (!group) break;

      newState.groups = {
        ...state.groups,
        [newGroupId]: {...group},
      };

      const signupOptions = [...state.signup_options];
      const signupOptionsToCopy = signupOptions.filter((so) => so.group === groupId);
      signupOptions.push(
        ...signupOptionsToCopy.map((so) => ({...so, uuid: v4(), group: newGroupId}))
      );
      newState.signup_options = [...signupOptions];
      break;
    }
    case DELETE_GROUP: {
      const {groupId} = action.payload;
      newState.signup_options = state.signup_options.filter((so) => so.group !== groupId);
      const groups = {...state.groups};
      delete groups[groupId];
      newState.groups = groups;
      break;
    }
    case APPLY_TEMPLATE: {
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    }
    case UPDATE_ERRORS: {
      const {errors = [], resetOpenStates = false} = action.payload;
      newState.errors = errors;
      if (resetOpenStates) {
        // Reopen any groups that have errors in them
        const openGroups = new Set(state.open_groups);
        state.signup_options.forEach((so, i) => {
          if (errors.some((e) => e.loc?.[0] === 'signup_options' && e.loc?.[1] === i)) {
            openGroups.add(so.group ?? so.uuid);
          }
        });
        newState.open_groups = new Set(openGroups);
      }
      break;
    }
    case TOGGLE_SHOW_VALIDATION_MODAL:
      newState = {
        ...state,
        show_validation_modal: action.payload,
      };
      break;
    case TOGGLE_SHOW_NOTIFY_VOLUNTEERS_MODAL:
      newState = {
        ...state,
        show_notify_volunteers_modal: {...action.payload},
      };
      break;
    case UPDATE_MULTIPLE_DATES_MODAL:
      newState = {
        ...state,
        multipleDatesModal: {...state.multipleDatesModal, ...action.payload},
      };
      break;
    case UPDATE_MULTIPLE_DATES_MODAL_CUSTOM_TAB:
      newState = {
        ...state,
        multipleDatesModal: {
          ...state.multipleDatesModal,
          dates: [...action.payload],
        },
      };
      break;
    case UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB:
      newState = {
        ...state,
        multipleDatesModal: {
          ...state.multipleDatesModal,
          ...action.payload,
        },
      };
      newState = setDaysAndDatesBasedOnSeries(newState, state);
      break;
    case UPDATE_MULTIPLE_DATES_MODAL_REPEATING_TAB_MONTHLY_SPECIFIER:
      newState = {
        ...state,
        multipleDatesModal: {
          ...state.multipleDatesModal,
          monthlyScheduleSpecifier: {
            ...state.multipleDatesModal.monthlyScheduleSpecifier,
            ...action.payload,
          },
        },
      };
      newState = setDaysAndDatesBasedOnSeries(newState, state);
      break;
    case UPDATE_MULTIPLE_SLOTS_MODAL_FORM_CHANGE:
    case UPDATE_MULTIPLE_SLOTS_MODAL_CALCULATE_SLOT:
      newState = {
        ...state,
        multipleSlotsModal: {
          ...state.multipleSlotsModal,
          ...action.payload,
        },
      };
      break;
    case UPDATE_MULTIPLE_SLOTS_MODAL_CLEAR_FORM:
      newState = {...state, multipleSlotsModal: initialMultipleSlotsModalState};
      break;
    default:
      break;
  }
  newState.signup_options = sortSignupOptions(newState.signup_options, state.sheet_type);
  return newState;
};
